import { Button, CircularProgress, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import cn from "classnames";
import { ForwardedRef, forwardRef, ReactNode } from "react";
import { Link } from "react-router-dom";

interface CommonButtonProps {
  children: React.ReactNode;
  type?: "button" | "reset" | "submit";
  form?: string;
  variant?: "contained" | "outlined" | "text";
  size?: "medium" | "small";
  color?: "primary" | "secondary" | "error";
  shape?: "rounded" | "rect";
  startIcon?: ReactNode;
  isLoading?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
  className?: string;
}

interface BasicButtonProps {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  to?: never;
  replace?: never;
  autoFocus?: boolean;
}

interface LinkButtonProps {
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
  to: string;
  replace?: boolean;
  autoFocus?: never;
}

type ButtonSimpleProps = CommonButtonProps & (BasicButtonProps | LinkButtonProps);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",

      whiteSpace: "nowrap",
    },
    medium: {
      minWidth: 256,
      height: 42,

      fontSize: 16,
      letterSpacing: "0.46px",
    },
    small: {
      minWidth: "fit-content",
      height: 36,
      padding: "0 16px",

      fontSize: 14,
      lineHeight: "24px",
      letterSpacing: "0.4px",
    },
    "contained-primary": {
      "& .MuiCircularProgress-circle": { stroke: "#fff" },
    },
    "contained-secondary": {},
    "outlined-primary": {
      border: `1px solid ${theme.palette.primary.main}`,

      "&:hover": {
        border: `1px solid ${theme.palette.primary.dark}`,
      },
    },
    "outlined-secondary": {
      border: `1px solid ${theme.palette.text.primary}`,
      color: theme.palette.text.primary,

      "&:hover": {
        border: `1px solid ${theme.palette.primary.dark}`,
        backgroundColor: "rgba(0, 126, 150, 0.04)",
      },
    },
    "text-primary": {},
    "text-secondary": {
      color: "rgba(36, 53, 68, 0.68)",

      "&:hover": {
        backgroundColor: "rgba(0, 126, 150, 0.04)",
      },
    },
    rounded: {
      borderRadius: "22px",
    },
    rect: {
      borderRadius: "4px",
    },
    spinner: {
      width: 22,
    },
  })
);

const ButtonSimple = forwardRef<HTMLButtonElement | HTMLAnchorElement, ButtonSimpleProps>(
  (
    {
      onClick,
      to,
      replace,
      children,
      type = "button",
      form,
      variant = "contained",
      size = "medium",
      color = "primary",
      shape = "rounded",
      startIcon,
      isLoading,
      disabled,
      autoFocus,
      fullWidth,
      className,
    }: ButtonSimpleProps,
    ref
  ) => {
    const classes = useStyles();

    let resultJsx: JSX.Element;

    if (to === undefined) {
      resultJsx = (
        <Button
          ref={ref as ForwardedRef<HTMLButtonElement>}
          onClick={onClick as React.MouseEventHandler<HTMLButtonElement>}
          type={type}
          form={form}
          variant={variant}
          size={size}
          color={color === "error" ? color : undefined}
          startIcon={startIcon}
          disabled={disabled}
          autoFocus={autoFocus}
          fullWidth={fullWidth}
          className={cn(classes.button, classes[size], classes[`${variant}-${color}`], classes[shape], className)}
          disableElevation
        >
          {!isLoading ? children : <CircularProgress size={22} className={classes.spinner} />}
        </Button>
      );
    } else {
      resultJsx = (
        <Button
          ref={ref as ForwardedRef<HTMLAnchorElement>}
          component={Link}
          to={to}
          replace={replace}
          onClick={onClick as React.MouseEventHandler<HTMLAnchorElement>}
          type={type}
          variant={variant}
          size={size}
          color={color === "error" ? color : undefined}
          startIcon={startIcon}
          disabled={disabled}
          fullWidth={fullWidth}
          className={cn(classes.button, classes[size], classes[`${variant}-${color}`], classes[shape], className)}
          disableElevation
        >
          {!isLoading ? children : <CircularProgress size={22} className={classes.spinner} />}
        </Button>
      );
    }

    return resultJsx;
  }
);

export default ButtonSimple;

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import DocsIcon from "@mui/icons-material/Description";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      "@media(max-width: 700px)": {
        minWidth: 40,
        padding: 0,
      },
    },
    icon: {
      [theme.breakpoints.up("xs")]: {
        marginRight: 5,
      },
    },
    text: {
      fontWeight: 500,
      letterSpacing: "0.02857em",

      "@media(max-width: 700px)": {
        display: "none",
      },
    },
  })
);

const Docs = () => {
  const classes = useStyles();

  return (
    <Button
      href="https://testrtc.com/docs/"
      target="_blank"
      color="primary"
      data-cy="logout-btn"
      className={classes.root}
    >
      <DocsIcon className={classes.icon} />
      <Typography variant="body2" color="primary" className={classes.text}>
        {"Docs"}
      </Typography>
    </Button>
  );
};

export default Docs;

import * as React from "react";
import { Redirect, Route, RouteComponentProps, Switch, withRouter } from "react-router";
import NavBar from "./components/NavBar";
import WorkingSection from "./components/WorkingSection";
import SignInPage from "../SignInPage";
import SignUpPage from "../SignUpPage";
import SignUpConfirmPage from "../SignUpConfirmPage";
import ForgotPasswordPage from "../ForgotPasswordPage/index";
import ResetPassword from "../ResetPassword";
import SubscribePage from "../SubscribePage/index";
import AnalyzeDumpPage from "../AnalyzeDumpPage/index";
import AuthService from "../../../services/AuthService";
import AuthProvider from "../AuthProvider";
import ConfirmAccount from "../ConfirmAccount";
import Report from "../Report";
import { AuthRoutes } from "../../../constants/routes/auth-routes";
import { AppRoutes } from "../../../constants/routes/app-routes";
import AnalyzeRTC from "src/containers/LogsContainer/WebRTCInternals/AnalyzeRTC";
import loaderIcon from "src/assets/images/loading-progress.svg";

class LoginLayout extends React.Component<RouteComponentProps<{}>> {
  componentDidMount() {
    if (AuthService.loggedIn()) {
      this.props.history.push(AppRoutes.Main);
    } else {
      AuthService.logout("testrtc");
    }
  }

  render() {
    return (
      <AuthProvider loaderIcon={loaderIcon}>
        <NavBar />
        <WorkingSection>
          <Switch>
            <Route exact={true} path={AuthRoutes.SignIn} component={SignInPage} />
            <Route exact={true} path={AuthRoutes.SignUp} component={SignUpPage} />
            <Route exact={true} path={AuthRoutes.ForgotPassword} component={ForgotPasswordPage} />
            <Route exact={true} path={AuthRoutes.ResetPasswordId} component={ResetPassword} />
            <Route exact={true} path={AuthRoutes.ConfirmAccountId} component={ConfirmAccount} />
            <Route exact={true} path={AuthRoutes.Subscribe} component={SubscribePage} />
            <Route exact={true} path={AuthRoutes.Analyze} component={AnalyzeDumpPage} />
            <Route exact={true} path={AuthRoutes.ReportFreeId} component={Report} />
            <Route exact={true} path={AppRoutes.WebrtcInternalsFreeAnalyzeRtcId} component={AnalyzeRTC} />
            <Route exact={true} path={`${AuthRoutes.SignUpConfirm}/:email`} component={SignUpConfirmPage} />
            <Redirect exact={true} from="/" to={AuthRoutes.SignIn} />
          </Switch>
        </WorkingSection>
      </AuthProvider>
    );
  }
}

export default withRouter(LoginLayout);

export const AppRoutes = {
  root: "/app",

  Forbidden: "/app/403",
  NotFound: "/app/404",
  WrongLink: "/app/400",
  ServerError: "/app/500",

  Main: "/app/main",

  History: "/app/history",
  Invites: "/app/invites",

  Support: "/app/support",

  AccountManagement: "/app/accounts",
  UserSettings: "/app/user-settings",
  Settings: "/app/settings",
} as const;

export const ExternalLinks = {
  Documentation: "https://www.spearline.com/article-categories/agent-assure/",
} as const;

import * as React from "react";
import { connect } from "react-redux";
import { createTheme, ThemeProvider, StyledEngineProvider, alpha } from "@mui/material/styles";
import { green } from "@mui/material/colors";
import CssBaseline from "@mui/material/CssBaseline";
import { LicenseManager } from "ag-grid-enterprise";

import { APP_VERSION } from "./constants";
import Router from "./Router";
import SnackbarNotifier from "./containers/SnackbarNotifier";
import { SetNotification } from "./actions/notificationAction";

import "@mui/material/styles";
import "@mui/material/Button";

declare module "@mui/material/styles" {
  interface Palette {
    textSecondary: Palette["primary"];
    data1: Palette["primary"];
    data4: Palette["primary"];
    data5: Palette["primary"];
  }

  interface PaletteOptions {
    textSecondary?: PaletteOptions["primary"];
    data1?: PaletteOptions["primary"];
    data4?: PaletteOptions["primary"];
    data5?: PaletteOptions["primary"];
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    textSecondary: true;
  }
}

LicenseManager.setLicenseKey(
  "CompanyName=testRTC Ltd,LicensedApplication=testRTC,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=1,AssetReference=AG-023260,ExpiryDate=10_December_2022_[v2]_MTY3MDYzMDQwMDAwMA==4a6261f0be8e2c2a4715fa12be21a119"
);

type AppDispatch = {
  setNotification(message: string, type?: string): void;
};

export type AppState = {
  error: Error | null;
};

export const AppContext = React.createContext({
  error: null,
  watchrtcNotifications: 0,
} as AppState);

const primaryColor = {
  light: "#58A4E5",
  main: "#3577C1",
  dark: "#23498F",
  contrastText: "#FFFFFF",
};

const textColor = "#243544";
const textColorPct = {
  68: alpha(textColor, 0.68),
  54: alpha(textColor, 0.54),
  26: alpha(textColor, 0.26),
  23: alpha(textColor, 0.23),
  12: alpha(textColor, 0.12),
  8: alpha(textColor, 0.08),
  4: alpha(textColor, 0.04),
};

const theme = createTheme({
  palette: {
    primary: {
      ...primaryColor,
    },
    secondary: {
      light: green[500],
      main: "#559542",
      dark: green[800],
      contrastText: "#FFFFFF",
    },
    text: {
      primary: textColor,
      secondary: textColorPct[68],
    },
    // custom color for MUI Button
    textSecondary: { main: textColorPct[68] },
    info: {
      ...primaryColor,
    },
    success: {
      light: "#0CB068",
      main: "#007E46",
      dark: "#005E31",
    },
    warning: {
      light: "#FFB33F",
      main: "#FC9A25",
      dark: "#E76316",
    },
    error: {
      light: "#EF5350",
      main: "#D32F2F",
      dark: "#C62828",
    },
    data1: {
      ...primaryColor,
    },
    data4: {
      light: "#FFD38B",
      main: "#FFB33F",
      dark: "#F68B21",
    },
    data5: {
      light: "#EE7B82",
      main: "#FF494B",
      dark: "#C52630",
    },
    action: {
      active: textColorPct[54],
      hover: textColorPct[4],
      selected: textColorPct[8],
      disabled: textColorPct[26],
      disabledBackground: textColorPct[12],
      focus: textColorPct[12],
    },
    divider: textColorPct[23],
  },

  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: ".8125rem",
        },
      },
    },
    MuiTableSortLabel: {
      styleOverrides: {
        icon: {
          height: 15,
          width: 15,
          marginLeft: 2,
          marginTop: 1,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: ({ theme }) => ({
          background: theme.palette.text.primary,
          fontSize: 10,
          lineHeight: "14px",
          fontWeight: 500,
          color: "#fff",
        }),
        arrow: ({ theme }) => ({ color: theme.palette.text.primary }),
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontWeight: 700,
          lineHeight: 1.25,
          letterSpacing: 0,
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: { padding: "8px 24px", ".MuiDialogTitle-root + &": { paddingTop: 8 } },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: { padding: "16px 24px" },
      },
    },
  },
});

export class App extends React.Component<AppDispatch, AppState> {
  constructor(props: AppDispatch) {
    super(props);

    this.state = {
      error: null,
    };
  }

  componentDidMount() {
    console.info(APP_VERSION);
  }

  componentDidCatch(error: Error, _info: React.ErrorInfo) {
    this.setState({
      error: error,
    });
    this.props.setNotification("Unexpected error occurred. Please, contact support.");
  }

  public render() {
    return (
      <AppContext.Provider value={this.state}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Router />
            <SnackbarNotifier />
          </ThemeProvider>
        </StyledEngineProvider>
      </AppContext.Provider>
    );
  }
}

const mapDispatchToProps = (dispatch: any): AppDispatch => ({
  setNotification: (message: string) => dispatch(SetNotification(message)),
});

export default connect<any, AppDispatch, {}>(null, mapDispatchToProps)(App);

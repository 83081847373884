// tslint:disable: member-ordering
import * as React from "react";
import * as _ from "lodash";
import * as moment from "moment";

import Typography from "@mui/material/Typography";
import TitleLinkIcon from "@mui/icons-material/HelpOutline";

import HighchartsReact from "highcharts-react-official";
import * as Highcharts from "highcharts";

import highchartsMore from "highcharts/highcharts-more";
import NoDataToDisplay from "highcharts/modules/no-data-to-display";
import boost from "highcharts/modules/boost";

import { reportError } from "src/services/ErrorService";
import ChartHelper from "../../helpers/chartHelper";
import { HighchartsChartRef } from "./types";
import { defaultChartColors, hexToRgbaColorMap } from "./constants";

NoDataToDisplay(Highcharts);
highchartsMore(Highcharts);
boost(Highcharts);

(function (H) {
  H.wrap(H.Series.prototype, "getValidPoints", function (this: unknown, proceed, ...args) {
    args[1] = false; //insideOnly
    return proceed.apply(this, args);
  });
})(Highcharts);
(window as any).Highcharts = Highcharts;

export interface ChartState {
  chartOptions: Highcharts.Options;
  isChartSetup: boolean;
  hideChart: boolean;
}

export interface ChartProps {
  id: string;
  title?: string;
  titleLink?: string;
  dataset: Array<any>;
  options: any;
  width?: string;
  height?: number | string;
  style?: React.CSSProperties;
  yAxisLabel?: string;
  xAxisLabel?: string;
  iteration?: string | number;
  byTimeActivated?: boolean;
  byProbeActivated?: boolean;
  hoverable?: boolean;
  filterOption?: string;
  syncLegends?: boolean;
  /**
   * @deprecated Seconds are redrawing automatically. Can remove
   */
  xAxisRedrawSeconds?: boolean;
  unsetMinY?: boolean;
  onHideChart?: (flag: boolean) => void;
  showNoDataMessage?: boolean;
  allowDatasetToHaveNoData?: boolean;
  allowDatasetToBeEmpty?: boolean;
  shouldHideSeriesIfHasNoData?: boolean;
  supressBoostMode?: boolean;
  hasNoEvents?: boolean;
}

interface ChartPropsInternal extends ChartProps {
  chartRef: React.RefObject<HighchartsChartRef>;
}

const headStyles: React.CSSProperties = {
  textTransform: "capitalize",
  fontSize: 15,
};

const titleContainerStyle: React.CSSProperties = {
  display: "flex",
  alignItems: "end",
  justifyContent: "center",
};

const titleIconStyle: React.CSSProperties = {
  color: "grey",
  marginLeft: 5,
};

class Chart extends React.Component<ChartPropsInternal & HighchartsReact.Props, ChartState> {
  defaultChartOptions: Highcharts.Options = {};
  static x: any;
  static y: any;
  static series: any;
  static points: Array<any>;

  static customTooltips: Array<any> = [];

  static formatTooltip(_tooltip: any, x = this.x, y = this.y, series = this.series) {
    return `<strong>${x}</strong><br/>${series.name} — <strong>${y.toFixed(0)}</strong>`;
  }

  static formatTooltipProbe(_tooltip: any, points = this.points) {
    return points.reduce((s: any, point: any) => {
      return s + "<br/>" + point.series.name + ": " + point.y;
      // eslint-disable-next-line
    }, "<b>" + "Probe #" + this.x + "</b>");
  }

  static formatUsageGraphTooltip(_tooltip: any, points = this.points) {
    return points.reduce((s: any, point: any) => {
      const suffix = point.series.tooltipOptions?.valueSuffix || "";
      const toFixed = point.series.tooltipOptions?.toFixed || 0;
      return s + "<br/>" + point.series.name + ": " + `<strong>${point.y.toFixed(toFixed)} ${suffix}</strong>`;
      // eslint-disable-next-line
    }, `<strong>${moment(this.x).format("MMM DD HH:mm")}</strong>`);
  }

  static formatDateTooltip(_tooltip: any, x = this.x, y = this.y, series = this.series) {
    const suffix = series.tooltipOptions?.valueSuffix || "";
    const toFixed = series.tooltipOptions?.toFixed || 0;
    return `<strong>${moment(x).format("MMM DD HH:mm")}</strong><br/>${series.name} — <strong>${y.toFixed(
      toFixed
    )} ${suffix}</strong>`;
  }

  // find out only this approach to map old custom tooltips in new chart lib
  static formatTooltipByProbeActived(_tooltip: any, points = this.points) {
    let showTooltip = true;
    let tooltip = "<span>";
    points.forEach((point, _index: number) => {
      const tooltips = Chart.customTooltips[point.series.name];
      if (!tooltips) {
        tooltip += `<br/>${point.series.name} (Probe #${point.x} - ${point.y.toFixed(3)})`;
      } else {
        const correspondingTooltip = tooltips.filter((t: any) => {
          if (!t.value) {
            return false;
          }
          if (_.isNumber(t.value)) {
            return t.value.toFixed(3) === point.y.toFixed(3);
          }
          if (_.isObject(t.value) && Object.keys(t.value).length === 0) {
            return false;
          }
          return t.value.average.toFixed(3) === point.y.toFixed(3);
        })[0];
        if (!correspondingTooltip || !correspondingTooltip.probeId) {
          showTooltip = false;
          return;
        }
        if (correspondingTooltip && correspondingTooltip.value) {
          if (Object.keys(correspondingTooltip.value).every((t) => correspondingTooltip.value[t] === 0)) {
            showTooltip = false;
            return;
          }
        }
        tooltip += `<br/>${point.series.name} (Probe #${correspondingTooltip.probeId} - ${point.y.toFixed(3)})`;
      }
    });
    tooltip += "</span>";
    if (!showTooltip) {
      return false;
    }
    return tooltip;
  }

  constructor(props: ChartPropsInternal & HighchartsReact.Props) {
    super(props);

    this.defaultChartOptions = {
      chart: {
        // height: 200,
        borderWidth: 0,
        borderColor: "#000",
      },
      lang: {
        noData: "No data to display",
      },
      noData: {
        style: {
          fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
          fontSize: "15px",
          fontWeight: "normal",
        },
      },
      title: { text: undefined },
      colors: defaultChartColors,
      legend: {
        enabled: false,
      },
      yAxis: {
        labels: {
          formatter: this.yAxisFormatter,
        },
        maxPadding: 0,
        min: 0,
        // tickInterval: 1,
        // tickDecimals: 2,
      },
      xAxis: {
        labels: {},
        // tickInterval: 1,
        // tickDecimals: 2,
      },
      series: [],
      plotOptions: {
        line: {
          lineWidth: 5,
        },
        series: {
          // enableMouseTracking: true,
          opacity: 1,
          states: {
            hover: {
              enabled: false,
            },
            inactive: {
              opacity: 1,
            },
          },
          lineWidth: 5,
        },
      },
      tooltip: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 500,
            },
          },
        ],
      },
      exporting: {
        enabled: false,
      },
    };
    this.state = {
      // To avoid unnecessary update keep all options in the state.
      chartOptions: _.cloneDeep(this.defaultChartOptions),
      hideChart: false,
      isChartSetup: false,
    };
  }

  componentDidMount() {
    this.setUpChart(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps: ChartPropsInternal & HighchartsReact.Props) {
    if (!_.isEqual(this.props.options, nextProps.options) || !_.isEqual(this.props.dataset, nextProps.dataset)) {
      this.setUpChart(nextProps);
    }
  }

  componentDidUpdate(prevProps: ChartPropsInternal & HighchartsReact.Props) {
    if (this.props.supressBoostMode && !prevProps.supressBoostMode) {
      this.props.chartRef.current?.chart?.redraw();
    }
  }

  setUpChart(props: ChartPropsInternal & HighchartsReact.Props) {
    const {
      options,
      height,
      hoverable,
      xAxisLabel,
      yAxisLabel,
      unsetMinY,
      allowDatasetToHaveNoData = false,
      allowDatasetToBeEmpty = false,
      shouldHideSeriesIfHasNoData = false,
      title,
      syncLegends,
      supressBoostMode,
      hasNoEvents = false,
    } = props;
    const series: Array<any> = [];
    let dataset: Array<any> = [];

    // Dirty hack for now
    const isProbeRTCChart = options.tooltip?.usageGraph;
    const enableBoost = supressBoostMode ? false : props.dataset?.length > 10;

    dataset = props.dataset;
    if (
      dataset.every(
        (ds) => !ds.data || ds.data.length === 0 || ds.data.every((d: any) => d[1] === 0 || d[1] === null)
      ) &&
      (!allowDatasetToHaveNoData || (allowDatasetToHaveNoData && dataset.length === 0)) &&
      !allowDatasetToBeEmpty
    ) {
      console.log(`yura: Chart/index.tsx\n${title} data is empty.`, dataset);
      this.hideChart(true);
      return;
    } else if (this.state.hideChart) {
      this.setState({
        ...this.state,
        hideChart: false,
      });
      this.hideChart(false);
    }

    const maxY = this.getMaxY(dataset);

    if (!isProbeRTCChart) {
      dataset = this.prepareData(dataset, maxY);
    }

    for (let dsIndex = 0; dsIndex < dataset.length; dsIndex += 1) {
      const ds = dataset[dsIndex];

      const isEventSeries =
        ds.label?.indexOf("Global ") !== -1 ||
        ds.label?.indexOf("Local ") !== -1 ||
        ds.label?.indexOf("Call end") !== -1 ||
        ds.label?.indexOf("Join ") !== -1 ||
        ds.label?.indexOf("Leave ") !== -1;

      if (!ds.data || ds.data.length === 0) {
        continue;
      }
      if (shouldHideSeriesIfHasNoData) {
        if (ds.data.every((d: any) => d[1] === 0 || d[1] === null)) {
          continue;
        }
      }
      if (!hasNoEvents && isEventSeries) {
        // https://www.highcharts.com/forum/viewtopic.php?t=34522#p121130
        // http://jsfiddle.net/ghuzdjhb/
        if (_.isArray(options.yAxis)) {
          ds.yAxis = 1;
        }
        try {
          // distinct arrays of array(points)
          const distincted: Array<any> = ds.data
            // get X values
            .map((d: any) => d[0])
            // distinct array of X values
            .filter((value: any, index: any, self: any) => {
              return self.indexOf(value) === index;
            })
            // map to new array(point)
            // 2nd value(Y value) is not matter, but must exists
            // it sets in setMaxPointForVerticalLines function
            .map((d: any) => [d, _.isArray(options.yAxis) ? 2 : 0]);

          ds.data = distincted;
          if (!this.props.byProbeActivated) {
            ds.data = ds.data.sort((a: Array<any>, b: Array<any>) => Number(a[0]) - Number(b[0]));
          }
        } catch (err) {
          reportError(`Chart series: ${ds.label} has incorrect data`, err);
          continue;
        }
      }

      const type = ChartHelper.getSeriesType(ds);
      let serie;

      if (enableBoost) {
        serie = {
          boostThreshold: 100,
          data: ds.data,
          name: ds.label,
          lineWidth: ds.lineWidth !== undefined ? ds.lineWidth : type === "arearange" ? 0 : 2,
          type,
          pointPlacement: ds.pointPlacement,
          clip: true,
        } as any;
      } else {
        serie = {
          name: ds.label,
          boostThreshold: ds.boostThreshold,
          data: ds.data,
          type,
          zIndex: type === "arearange" ? 0 : dsIndex + 1,
          fillOpacity:
            ds.fillOpacity !== undefined ? ds.fillOpacity : type === "arearange" || type === "column" ? 0.5 : 1,
          lineWidth: ds.lineWidth !== undefined ? ds.lineWidth : type === "arearange" ? 0 : 2,
          tooltip: ds.tooltip,
          pointPlacement: ds.pointPlacement,
          clip: true,
        } as any;

        if (type === "area") {
          serie.boostThreshold = 2000;

          if (hexToRgbaColorMap[ds.color]) {
            serie.fillColor = `${hexToRgbaColorMap[ds.color].slice(0, -2)}0.35)`;
            serie.fillOpacity = 0.99;
          } else {
            serie.fillOpacity = 0.35;
          }

          serie.data = serie.data.map((point: unknown) => {
            if (Array.isArray(point)) {
              return [point[0], point[1] ?? 0];
            } else {
              return point;
            }
          });
        }
      }

      if (type === "column" && isEventSeries) {
        serie.clip = false;
      }

      // do not set visible as undefined
      // as it resets toggled legend item on switch between sources
      if (ds.visible !== undefined) {
        serie.visible = ds.visible;
      }

      if (ds.color) {
        serie.color = ds.color;
      }

      if (ds.yAxis || ds.yAxis === 0) {
        serie.yAxis = ds.yAxis;
      }

      if (ds.yAxis === undefined) {
        serie.yAxis = 0;
      }

      if (["Avg", "Min", "Max"].includes(ds.label)) {
        serie.yAxis = 0;
      }

      series.push(serie);

      if (ds.helpers && ds.helpers.tooltip) {
        Chart.customTooltips[ds.label] = ds.helpers.tooltip;
      }
    }

    const categories = this.props.byProbeActivated
      ? dataset[0]?.helpers?.tooltip
          .map((t: any, idx: number) => t.probeId || (idx + 1).toString())
          .sort((a: any, b: any) => {
            return a.localeCompare(b, undefined, { numeric: true, sensitivity: "base" });
          })
      : false;

    // if series with columns has more than 5 bars in there then
    // and it is not mobile
    // allow chart adjust bar with automatically
    const barWidth =
      window.innerWidth > 600 &&
      this.props.byProbeActivated &&
      series?.filter((x) => x.type === "column").every((x) => x.data?.length < 5)
        ? 100
        : undefined;

    let yAxisMin;

    if (Number.isFinite(options.yAxis?.min)) {
      yAxisMin = options.yAxis.min;
    } else if (unsetMinY) {
      yAxisMin = null;
    } else {
      yAxisMin = 0;
    }

    const newChartOptions: Highcharts.Options = {
      boost: enableBoost
        ? {
            // Chart-level boost when there are more than 15 series in the chart
            seriesThreshold: 15,
            ...options.boost,
          }
        : {
            seriesThreshold: 999999,
          },
      time: {
        ...options.time,
      },
      chart: {
        ...options.chart,
        height: height || null,
      },
      navigation: {
        buttonOptions: {
          enabled: false,
        },
      },
      title: options?.title,
      xAxis: {
        ...options.xAxis,
        title: {
          ...options.xAxis?.title,
          text: xAxisLabel || options.xAxis?.axisLabel || "",
        },
        labels: {
          ...options.xAxis?.labels,
          formatter: options.xAxis?.labels?.formatter || options.xAxis?.formatter,
        },
        crosshair: !!(options.xAxis?.crosshair || this.props.byProbeActivated || isProbeRTCChart),
        categories: options.xAxis?.categories || categories,
      },
      lang: {
        ...options.lang,
      },
      yAxis: _.isArray(options.yAxis)
        ? options.yAxis
        : {
            ...options.yAxis,
            title: {
              ...options.yAxis?.title,
              text: yAxisLabel || options.yAxis?.axisLabel || "",
            },
            labels: {
              ...options.yAxis?.labels,
              formatter: options.yAxis?.labels?.format
                ? undefined
                : (context: any) => {
                    return options.yAxis?.formatter
                      ? options.yAxis.formatter(context.value, context)
                      : this.yAxisFormatter(context);
                  },
            },
            min: yAxisMin,
          },
      series,
      plotOptions: {
        ...options.plotOptions,
        series: {
          events: {
            ...options.plotOptions?.series?.events,
            legendItemClick: options?.plotOptions?.series?.events?.legendItemClick
              ? options?.plotOptions?.series?.events?.legendItemClick
              : syncLegends
              ? function (this: any, _event: any) {
                  const _this = this as any;
                  const charts = [...Highcharts?.charts];
                  if (charts?.length) {
                    charts.pop();
                    charts.forEach((c) => {
                      if (c?.series[_this.index]) {
                        if (_this.visible) {
                          c?.series[_this.index].hide();
                        } else {
                          c?.series[_this.index].show();
                        }
                      }
                    });
                  }
                }
              : undefined,
          },
          line: {
            states: {
              hover: {
                enabled: options.grid?.hoverable || hoverable,
              },
            },
          },
          states: {
            hover: {
              enabled: options.grid?.hoverable || hoverable,
            },
          },
          marker: options.plotOptions?.marker || {
            enabled: false,
            symbol: null,
          },
          pointWidth: options.plotOptions?.series?.pointWidth || barWidth,
          opacity: options.plotOptions?.series?.opacity,
          cursor: options.plotOptions?.series?.cursor,
        },
        column: this.props.byProbeActivated
          ? {
              grouping: false,
              shadow: false,
              opacity: 0.7,
              pointPadding: 0,
              groupPadding: 0,
              borderWidth: 1,
            }
          : options.plotOptions?.column
          ? options.plotOptions?.column
          : {},
      },
      tooltip: {
        enabled: options.tooltip?.enabled || options.tooltip?.show || this.props.byProbeActivated,
        formatter: options.tooltip?.formatter
          ? options.tooltip.formatter
          : this.props.byProbeActivated
          ? Chart.formatTooltipProbe
          : isProbeRTCChart
          ? Chart.formatUsageGraphTooltip
          : Chart.formatTooltip,
        headerFormat: "",
        shared: this.props.byProbeActivated || isProbeRTCChart ? true : options.tooltip?.shared ? true : false,
        split: options.tooltip?.split,
        outside: !!options.tooltip?.outside,
        useHTML: options.tooltip?.useHTML,
        hideDelay: options.tooltip?.hideDelay,
        stickOnContact: options.tooltip?.stickOnContact,
        distance: options.tooltip?.distance,
      },
    };

    if (newChartOptions.tooltip) {
      if (options.tooltip?.padding !== undefined) {
        newChartOptions.tooltip.padding = options.tooltip.padding;
      }

      if (options.tooltip?.borderWidth !== undefined) {
        newChartOptions.tooltip.borderWidth = options.tooltip.borderWidth;
      }

      if (options.tooltip?.backgroundColor !== undefined) {
        newChartOptions.tooltip.backgroundColor = options.tooltip.backgroundColor;
      }

      if (options.tooltip?.shadow !== undefined) {
        newChartOptions.tooltip.shadow = options.tooltip.shadow;
      }

      if (options.tooltip?.style !== undefined) {
        newChartOptions.tooltip.style = options.tooltip.style;
      }
    }

    const mergedChartOptions = _.merge({}, this.defaultChartOptions, newChartOptions);

    this.setState({
      chartOptions: mergedChartOptions,
      isChartSetup: true,
    });
  }

  hideChart = (flag: boolean) => {
    const { onHideChart } = this.props;
    this.setState({
      ...this.state,
      hideChart: flag,
    });
    if (onHideChart) {
      onHideChart(flag);
    }
  };

  // set global/local/call end events as full chart height
  setMaxPointForVerticalLines(chart: any) {
    if (!chart?.yAxis) {
      return;
    }
    const extremes = chart.yAxis[0].getExtremes();
    if (!extremes.max) {
      return;
    }
    let changed = false;
    const options = this.state.chartOptions;

    if (options.series) {
      options.series.forEach((s: any) => {
        if (
          s.name &&
          (s.name.indexOf("Global") !== -1 ||
            s.name.indexOf("Local") !== -1 ||
            s.name.indexOf("Call end") !== -1 ||
            s.name.indexOf("Session") !== -1 ||
            s.name.indexOf("Join") !== -1 ||
            s.name.indexOf("Leave") !== -1)
        ) {
          s.data.forEach((d: Array<any>) => {
            if (_.isArray(d)) {
              if (d[1] !== extremes.max) {
                changed = true;
              }
              d[1] = extremes.max;
            }
          });
        }
      });
    }

    if (changed) {
      this.setState({ chartOptions: { ...options } });
    }
  }

  yAxisFormatter = (context: any) => {
    const splittedVal = context.value.toString().split(".");
    if (!splittedVal[1]) {
      return splittedVal[0];
    } else if (splittedVal[1].length > 2 || Number(context.value) < 1) {
      return context.value.toFixed(2);
    } else {
      return context.value;
    }
  };

  getMaxY(dataset: Array<any>) {
    let max = 0;
    dataset.forEach((ds) => {
      if (!ds.data) {
        return;
      }
      ds.data.forEach((point: Array<any>) => {
        // second value in point is Y
        // check if Y exist
        if (!point[1] || !_.isNumber(point[1]) || !isFinite(point[1])) {
          return;
        }
        if (point[1] > max) {
          max = point[1];
        }
      });
    });

    return max;
  }

  // replace Y axis Infinity/NaN values
  prepareData(dataset: Array<any>, maxY: number) {
    dataset.forEach((ds) => {
      if (!ds.data) {
        return;
      }
      ds.data.forEach((point: Array<any>) => {
        if (!_.isArray(point)) {
          point = [0, 0];
        }
        if (isNaN(point[1])) {
          point[1] = 0;
        }
        if (point[1] && !isFinite(point[1])) {
          if (ds.label.indexOf("band") !== -1) {
            point[1] = maxY;
          }
        }
      });
    });
    return dataset;
  }

  render() {
    const { chartOptions, hideChart, isChartSetup } = this.state;
    const { title, titleLink, showNoDataMessage } = this.props;

    if (hideChart) {
      if (showNoDataMessage) {
        return (
          <Typography style={headStyles} variant="subtitle1" gutterBottom={true} align="center">
            {"No data"}
          </Typography>
        );
      }
      return null;
    }

    return (
      <div style={{ width: "100%" }} className="avoid">
        {title && (
          <div style={titleContainerStyle}>
            <Typography style={headStyles} variant="subtitle1" align="center">
              {title}
            </Typography>
            {titleLink && (
              <a href={titleLink} target="_blank" rel="noopener noreferrer" style={titleIconStyle}>
                <TitleLinkIcon />
              </a>
            )}
          </div>
        )}
        {isChartSetup && <HighchartsReact highcharts={Highcharts} options={chartOptions} ref={this.props.chartRef} />}
        {/* <Treshold chartRef={this.props.chartRef} treshold={treshold} /> */}
      </div>
    );
  }
}

// don't use callback refs, only created by useRef() or createRef()
export default React.forwardRef<HighchartsChartRef, ChartPropsInternal & HighchartsReact.Props>((props, ref) => (
  <Chart {...props} chartRef={ref as React.RefObject<HighchartsChartRef>} />
));

import * as React from "react";
import { Field, Form } from "react-final-form";
import TextFieldControl from "../../../components/form-elements/TextFieldControl";
import FormValidators from "../../../helpers/form-validators";
import { RouteComponentProps, withRouter } from "react-router";
import { axiosInstance } from "../../../services/AxiosService";
import ApiPath from "../../../constants/ApiPath";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { AuthRoutes } from "../../../constants/routes/auth-routes";
import { createStyles, withStyles, WithStyles } from "@mui/styles";

interface IForgotForm {
  email: string;
}

type StyledComponent = WithStyles<"root">;

class ForgotPasswordForm extends React.Component<RouteComponentProps<{}> & StyledComponent> {
  constructor(props: RouteComponentProps<{}> & StyledComponent) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);
  }

  public render(): JSX.Element {
    const { classes } = this.props;
    return (
      <Form
        onSubmit={this.onSubmit}
        render={({ handleSubmit, invalid }) => (
          <form onSubmit={handleSubmit}>
            <Grid container={true} spacing={2}>
              <Grid item={true} xs={12} className={classes.root}>
                <Field
                  component={TextFieldControl}
                  type="text"
                  name="email"
                  label="Email"
                  validate={FormValidators.composeValidators(FormValidators.required(), FormValidators.isEmail)}
                />
              </Grid>
              <Grid item={true} xs={12}>
                <Button type="submit" variant="contained" color="secondary" disabled={invalid}>
                  Notify Me
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      />
    );
  }

  private async onSubmit(values: IForgotForm): Promise<void | { [x: string]: string }> {
    try {
      await axiosInstance.get(`${ApiPath.api.newsletterSubscribe}/${values.email}`);
      this.props.history.push(
        {
          pathname: AuthRoutes.SignIn,
        },
        { isSubscribeSent: true, emailRemind: values.email }
      );
    } catch (_err) {
      return { email: "Sorry, could not subscribe you for updates. Please try later." };
    }
  }
}

const decorate = withStyles(() => {
  return createStyles({
    root: {
      "& .MuiInputBase-input": {
        paddingTop: "24px",
      },
    },
  });
});

export default withRouter(decorate(ForgotPasswordForm));

import * as React from "react";
import { RouteComponentProps } from "react-router";
import SubscribePageForm from "./SubscribePageForm";
import { Slide, Typography, Grid } from "@mui/material";

class ForgotPasswordPage extends React.Component<RouteComponentProps<{}>> {
  public render(): JSX.Element {
    return (
      <Grid container={true} direction="row" justifyContent="center">
        <Slide direction="right" in={true} mountOnEnter={true} unmountOnExit={true}>
          <Grid item={true} xs={4}>
            <Typography variant="h1" style={{ fontSize: 30 }}>
              Subscribe to testRTC Updates
            </Typography>
            <br />
            <SubscribePageForm />
          </Grid>
        </Slide>
      </Grid>
    );
  }
}

export default ForgotPasswordPage;

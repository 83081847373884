import Star from "@mui/icons-material/Star";
import StarBorder from "@mui/icons-material/StarBorder";

type Props = {
  rating: number;
  setRating: (rating: number) => void;
  disabled: boolean;
};

const stars = [1, 2, 3, 4, 5];

const containerStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const disabledStyles = {
  opacity: "0.7",
  pointerEvents: "none",
};

const starColor = {
  color: "#f1cd2b",
  cursor: "pointer",
};

const starBColor = {
  color: "#3577c1",
  cursor: "pointer",
};

export default (props: Props) => {
  const disabled = props.disabled ? disabledStyles : {};

  return (
    <div
      style={{
        ...containerStyles,
        ...disabled,
      }}
    >
      {stars.map((s, i: number) =>
        i + 1 <= props.rating ? (
          <Star key={s} style={starColor} onClick={() => props.setRating(i + 1)} />
        ) : (
          <StarBorder key={s} style={starBColor} onClick={() => props.setRating(i + 1)} />
        )
      )}
    </div>
  );
};

import { useContext } from "react";
import cn from "classnames";
import { Alert, Button, IconButton, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { CloseOutlined as CloseOutlinedIcon } from "@mui/icons-material";

import { ProjectsContext } from "@/containers/AppController";

export type TimeUnit = "day" | "hour" | "minute" | "second";

interface AlertToolbarProps {
  isEvaluation: boolean;
  isExpired: boolean;
  isSuspended: boolean;
  isTrialAlertOpen: boolean;
  timeLeft: number;
  timeLeftUnit: TimeUnit;
  isAlertHideable: boolean;
  closeTrialAlert: () => void;
}

export const timeUnits: TimeUnit[] = ["day", "hour", "minute", "second"];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    alert: {
      position: "sticky",
      top: 0,
      zIndex: 10,
    },
    action: { marginRight: 0 },
    closeButton: {
      margin: "0 2px 0 14px",
      position: "relative",
      top: -1,
    },
    closeIcon: {
      width: 20,
      height: 20,
    },
    alertInfo: {
      backgroundColor: "#E7F6FD",
      color: "#1A6287",
    },
    infoIcon: {
      "&&&": {
        color: theme.palette.primary.main,
      },
    },
    infoButton: {
      color: "#1A6287",
      letterSpacing: "0.46px",
    },
    closeInfoIcon: {
      color: "#1A6287",
    },
    alertError: {
      backgroundColor: "#FBEAEA",
      color: "#6A333C",
    },
    errorIcon: {
      "&&&": {
        color: theme.palette.error.main,
      },
    },
    errorButton: {
      color: "#6A333C",
      letterSpacing: "0.46px",
    },
  })
);

const AlertToolbar = ({
  isEvaluation,
  isExpired,
  isSuspended,
  isTrialAlertOpen,
  timeLeft,
  timeLeftUnit,
  isAlertHideable,
  closeTrialAlert,
}: AlertToolbarProps): JSX.Element | null => {
  const classes = useStyles();

  const { selectedProject } = useContext(ProjectsContext);

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const handleBillingClick = () => {};

  let resultJsx: JSX.Element | null = null;

  if (selectedProject) {
    if (isEvaluation && !isExpired) {
      if (!isAlertHideable || isTrialAlertOpen) {
        resultJsx = (
          <Alert
            severity="info"
            action={
              <>
                <Button onClick={handleBillingClick} size="small" className={classes.infoButton}>
                  Upgrade plan
                </Button>
                {isAlertHideable ? (
                  <IconButton onClick={closeTrialAlert} size="small" aria-label="close" className={classes.closeButton}>
                    <CloseOutlinedIcon className={cn(classes.closeIcon, classes.closeInfoIcon)} />
                  </IconButton>
                ) : null}
              </>
            }
            classes={{ icon: classes.infoIcon, action: classes.action }}
            className={cn(classes.alert, classes.alertInfo)}
          >
            Free trial expires in {timeLeft} {Math.abs(timeLeft) === 1 ? timeLeftUnit : `${timeLeftUnit}s`}
          </Alert>
        );
      }
    } else if (isEvaluation && isExpired) {
      resultJsx = (
        <Alert
          severity="error"
          action={
            <Button onClick={handleBillingClick} size="small" color="error" className={classes.errorButton}>
              Upgrade plan
            </Button>
          }
          classes={{ icon: classes.errorIcon, action: classes.action }}
          className={cn(classes.alert, classes.alertError)}
        >
          Your free trial has expired, please contact us to upgrade your plan
        </Alert>
      );
    } else if (isSuspended) {
      resultJsx = (
        <Alert
          severity="error"
          action={
            <Button onClick={handleBillingClick} size="small" color="error" className={classes.errorButton}>
              Check billing
            </Button>
          }
          classes={{ icon: classes.errorIcon, action: classes.action }}
          className={cn(classes.alert, classes.alertError)}
        >
          Your account expired, please contact us if you wish to renew it
        </Alert>
      );
    }
  }

  return resultJsx;
};

export default AlertToolbar;

// tslint:disable:no-any

import * as React from "react";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import Slide from "@mui/material/Slide";
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import createStyles from '@mui/styles/createStyles';
import AnalyzeDumpForm from "./AnalyzeDumpForm";
import { Link } from "react-router-dom";
import ANALYZE_ICON from "../../../assets/images/analyze-icon.png";
import { axiosInstance, CancelSignal } from "../../../services/AxiosService";
import ApiPath from "../../../constants/ApiPath";
import { AuthRoutes } from "../../../constants/routes/auth-routes";
import { AxiosInstance, CancelTokenSource } from "axios";

type StyledComponent = WithStyles<"analyzeImage" | "link" | "listItem" | "analyzeContainer" | "logoContainer">;

type AnalyzeDumpState = {
  isLoading: boolean;
  showConfirmationDialog: boolean;
  email: string;
  acceptedFiles: Array<any>;
  rejectedFiles: Array<any>;
  recaptchaKey: string;
};

function Transition(props: any) {
  return <Slide direction="up" {...props} />;
}

class AnalyzeDumpPage extends React.Component<StyledComponent, AnalyzeDumpState> {
  formComponent = null;
  axios: AxiosInstance;
  signal: CancelTokenSource;

  initialState = {
    isLoading: false,
    showConfirmationDialog: false,
    email: "",
    acceptedFiles: [],
    rejectedFiles: [],
    recaptchaKey: "",
  };

  constructor(props: StyledComponent) {
    super(props);

    this.state = {
      ...this.initialState,
    };

    this.signal = CancelSignal();

    this.onSubmit = this.onSubmit.bind(this);
    this.onModalClose = this.onModalClose.bind(this);
    this.handleDrop = this.handleDrop.bind(this);
    this.onRecaptchaChange = this.onRecaptchaChange.bind(this);
  }

  componentWillUnmount() {
    this.signal.cancel("Unmounted");
  }

  public render(): JSX.Element {
    const { classes } = this.props;
    const { showConfirmationDialog, email } = this.state;

    return (
      <>
        <Grid container={true} direction="row" justifyContent="center">
          <Grid item={true} lg={4} md={6} sm={8} xs={12} className={classes.analyzeContainer}>
            <div className={classes.logoContainer}>
              <img className={classes.analyzeImage} src={ANALYZE_ICON} alt="analyse-icon" />
              <Typography variant="h4" align="center">
                Analyze a log file
              </Typography>
            </div>
            <br />
            <AnalyzeDumpForm
              onSubmit={this.onSubmit}
              handleDrop={this.handleDrop}
              onRecaptchaChange={this.onRecaptchaChange}
              {...this.state}
            />
          </Grid>
        </Grid>
        <Dialog
          open={showConfirmationDialog}
          TransitionComponent={Transition}
          keepMounted={true}
          onClose={this.onModalClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description-1"
        >
          <DialogTitle id="alert-dialog-slide-title">Upload Completed!</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description-1">
              Thanks for uploading the log file.
              <br />
              We are currently preparing the report for you.
              <br />
              An email will be sent to {email} the moment it is ready.
            </DialogContentText>
            <DialogContentText id="alert-dialog-slide-description-2">
              In the meantime, you can:
              <br />
              <span className={classes.listItem} style={{ marginTop: 10 }}>
                Analyze another log file
              </span>
              <span className={classes.listItem}>
                <span>Visit testRTC web </span>
                <a target="_blank" className={classes.link} href="https://testrtc.com/" rel="noopener noreferrer">
                  page
                </a>
              </span>
              <span className={classes.listItem}>
                <Link className={classes.link} to={AuthRoutes.Subscribe}>
                  Subscribe for updates
                </Link>
              </span>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </>
    );
  }

  private handleDrop(acceptedFiles: any, _rejectedFiles: any) {
    this.setState({
      acceptedFiles: [...acceptedFiles],
    });
  }

  private onModalClose() {
    this.setState({
      showConfirmationDialog: false,
    });
  }

  private async onSubmit(values: any): Promise<any> {
    this.setState({ isLoading: true });
    const { acceptedFiles, recaptchaKey } = this.state;
    const errors = [];
    if (!acceptedFiles.length) {
      errors.push({
        file: "File required",
      });
    }
    if (acceptedFiles[0] && acceptedFiles[0].size / 1024 / 1024 > 5) {
      errors.push({
        fileSize: "File size should not exceed 5MB",
      });
    }
    if (!recaptchaKey) {
      errors.push({
        reCaptchaResponse: "reCaptcha Required",
      });
    }

    if (errors.length) {
      this.setState({ isLoading: false });
      return errors.reduce((obj, e) => ({ ...obj, ...e }), {});
    }

    const formData = new FormData();

    const data = {
      ...values,
      reCaptchaResponse: recaptchaKey,
    };

    Object.keys(data).forEach((key: string) => formData.append(key, data[key]));
    formData.append("file", acceptedFiles[0]);

    return await axiosInstance
      .post(`${ApiPath.api.analyzeDatasFree}`, formData, {
        headers: { "content-type": "multipart/form-data" },
        cancelToken: this.signal.token,
      })
      .then(() => {
        this.setState({ ...this.initialState, showConfirmationDialog: true, email: values.email, isLoading: false });

        return null;
      })
      .catch((err) => {
        this.setState({ isLoading: false });

        return {
          incorectFile: err.response?.data?.msg,
        };
      });
  }

  private onRecaptchaChange(key: string) {
    this.setState({
      recaptchaKey: key,
    });
  }
}

const decorate = withStyles((theme) =>
  createStyles({
    analyzeImage: {
      marginRight: 10,
      height: 50
    },
    link: {
      color: "#599647",
      textDecoration: "none",
      "&:hover": {
        textDecoration: "underline",
      },
      "&:active": {
        textDecoration: "none",
      },
    },
    listItem: {
      display: "list-item",
      marginLeft: 30,
    },
    analyzeContainer: {
      padding: theme.spacing(2),
    },
    logoContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    }
  })
);

export default decorate(AnalyzeDumpPage);

import { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router";
import { Button, Grid, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { AxiosResponse } from "axios";
import { useDispatch } from "react-redux";

import { axiosInstance } from "src/services/AxiosService";
import ApiPath from "src/constants/ApiPath";
import { SetNotification } from "src/actions/notificationAction";
import AuthService from "../../../services/AuthService";
import { FetchUser } from "../../../actions/authAction";
import { AuthRoutes } from "../../../constants/routes/auth-routes";
import { AppRoutes } from "../../../constants/routes/app-routes";

type RouteParams = {
  objectId: string;
};

const useStyles = makeStyles(
  createStyles({
    root: {
      height: "inherit",
      paddingBottom: 20,
    },
    logoContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginLeft: "10%",
    },
    logoImage: {
      height: "auto",
      maxHeight: 50,
    },
  })
);

const ConfirmAccount = (props: RouteComponentProps<RouteParams>) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const fetchUser = () => dispatch(FetchUser());
  const setNotification = (message: string) => dispatch(SetNotification(message));

  const [confirming, setConfirming] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        await axiosInstance
          .get(`${ApiPath.api.user.confirmAccount}/${props.match.params.objectId}`)
          .then((response: AxiosResponse<LoginResponse>) => {
            if (response.status === 200 && response?.data?.token && response?.data?.expires) {
              AuthService.setToken(response.data.token);
              AuthService.setExpires(response.data.expires);
            }
          });
      } catch (err) {
        props.history.push(AuthRoutes.SignIn);
        setNotification(err?.response?.data?.message || "Failed to confirm account. Token is invalid");
      } finally {
        setConfirming(false);
      }
    })();
  }, []);

  const handleContinue = async () => {
    const user = await fetchUser();

    if (user as any) {
      props.history.push(AppRoutes.Main);
    } else {
      props.history.push(AuthRoutes.SignIn);
    }
  };

  let resultJsx: JSX.Element;

  if (confirming) {
    resultJsx = (
      <Grid container={true} justifyContent="center" alignItems="center" direction="column" className={classes.root}>
        <Grid item={true}>
          <Typography variant="h3">Waiting for confirmation</Typography>
        </Grid>
        <Grid item={true} style={{ marginTop: 30 }}>
          <Typography variant="h6">Please wait...</Typography>
        </Grid>
      </Grid>
    );
  } else {
    resultJsx = (
      <Grid container={true} justifyContent="center" alignItems="center" direction="column" className={classes.root}>
        <Grid item={true}>
          <Typography variant="h3">Thanks for signing up</Typography>
        </Grid>
        <Grid item={true} style={{ marginTop: 30 }}>
          <Typography variant="h6">Your account is ready.</Typography>
        </Grid>
        <Grid item={true} style={{ marginTop: 30 }}>
          <Button color="secondary" variant="contained" onClick={handleContinue}>
            Continue
          </Button>
        </Grid>
      </Grid>
    );
  }

  return resultJsx;
};

export default ConfirmAccount;

import { Grid, Tooltip, Typography } from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";

const LabelCell = ({ label, tooltip }: { label: string; tooltip?: string }) => {
  return (
    <Grid alignItems="center" container={true} direction="row">
      <p style={{ flex: 1 }}>{label}</p>
      {!!tooltip && (
        <Tooltip
          style={{ marginLeft: 5 }}
          placement={"right-start"}
          title={<Typography variant={"body1"}>{tooltip}</Typography>}
        >
          <InfoOutlined />
        </Tooltip>
      )}
    </Grid>
  );
};

export default LabelCell;

import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { RouteComponentProps } from "react-router";
import { Grid, Typography, Card, Button } from "@mui/material";
import { Form, Field } from "react-final-form";
import FormValidators from "src/helpers/form-validators";
import TextFieldControl from "src/components/form-elements/TextFieldControl";
import { axiosInstance } from "src/services/AxiosService";
import ApiPath from "src/constants/ApiPath";
import { SetNotification } from "../../../actions/notificationAction";
import { AuthRoutes } from "../../../constants/routes/auth-routes";

interface ResetPasswordRouteParams {
  token: string;
}

const ResetPassword = (props: RouteComponentProps<ResetPasswordRouteParams>) => {
  const dispatch = useDispatch();

  const setNotification = (message: string) => dispatch(SetNotification(message));

  const [validating, setValidating] = useState(true);

  const notification = (message: string) => {
    if (setNotification) {
      setNotification(message);
    } else {
      alert(message);
    }
  };

  const validate = async () => {
    try {
      if (!props.match.params.token) {
        props.history.push(AuthRoutes.SignIn);
        return;
      }
      setValidating(true);

      await axiosInstance.get(`${ApiPath.api.user.resetPassword}/${props.match.params.token}`);
    } catch (err) {
      notification(err.response.data.message || "The request is not valid.");
      props.history.push(AuthRoutes.SignIn);
    } finally {
      setValidating(false);
    }
  };

  useEffect(() => {
    validate();
  }, []);

  const onSubmit = async (values: any) => {
    if (values.password !== values.repeat_password) {
      notification("Password does not match");
      return;
    }
    try {
      await axiosInstance.post(`${ApiPath.api.user.resetPassword}/${props.match.params.token}`, {
        password: values.password,
      });
      notification("Password has been changed successfully, now you can log in.");
      props.history.push(AuthRoutes.SignIn);
    } catch (err) {
      notification(err.response.data.message || "Unexpected error. Please contact support");
    }
  };

  if (validating) {
    return null;
  }

  return (
    <Form
      // tslint:disable-next-line: no-any
      onSubmit={onSubmit}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Card
            style={{
              padding: 30,
              width: "100%",
              maxWidth: 400,
              margin: "0 auto",
              top: "20px",
            }}
          >
            <Typography variant="h6" gutterBottom={true}>
              {"Please enter new password for your account."}
            </Typography>
            <Grid container={true} spacing={2}>
              <Grid item={true} xs={12}>
                <Field
                  component={TextFieldControl}
                  type="password"
                  name="password"
                  label="Password"
                  validate={FormValidators.composeValidators(FormValidators.required(), FormValidators.minValue(6))}
                  variant="outlined"
                />
              </Grid>
              <Grid item={true} xs={12}>
                <Field
                  component={TextFieldControl}
                  type="password"
                  name="repeat_password"
                  label="Repeat Password"
                  validate={FormValidators.composeValidators(FormValidators.required(), FormValidators.minValue(6))}
                  variant="outlined"
                />
              </Grid>
              <Grid item={true} container={true} xs={12} alignContent={"flex-end"} justifyContent={"flex-end"}>
                <Button type="submit" variant="contained" color="secondary">
                  {"Submit"}
                </Button>
              </Grid>
            </Grid>
          </Card>
        </form>
      )}
    />
  );
};

export default ResetPassword;

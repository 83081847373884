import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, RouteComponentProps } from "react-router-dom";
import { Field, Form } from "react-final-form";
import { Box, IconButton, Theme, Typography } from "@mui/material";
import { Visibility as VisibilityIcon, VisibilityOff as VisibilityOffIcon } from "@mui/icons-material";
import { createStyles, makeStyles } from "@mui/styles";
import cn from "classnames";

import AuthService from "@monortc/front/services/AuthService";
import { FetchUser } from "@monortc/front/actions/authAction";
import { SetNotification } from "@monortc/front/actions/notificationAction";
import TextFieldControlSimple from "@monortc/front/components/form-elements/TextFieldControlSimple";
import FormValidators from "@monortc/front/helpers/form-validators";
import ButtonSimple from "@monortc/front/components/ButtonSimple";
import LoginSocialButtons from "@/components/Auth/LoginSocialButtons";
import { AuthRoutes } from "@/constants/routes/auth-routes";
import { AppRoutes } from "@/constants/routes/app-routes";

export interface ILoginForm {
  email: string;
  password: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      maxWidth: 368,
      width: "100%",
    },
    title: {
      margin: 0,

      fontSize: 24,
      fontWeight: 700,
      lineHeight: 1.25,
      letterSpacing: "0.25px",
    },
    formMessage: {
      margin: "8px auto 0",

      lineHeight: 1.75,
      letterSpacing: "0.15px",
    },
    formMessageError: {
      color: theme.palette.error.main,
    },
    formContainer: {
      marginTop: 24,
    },
    nthTextField: {
      marginTop: 16,
    },
    forgotPasswordLink: {
      display: "block",
      marginTop: 16,

      textAlign: "right",
      color: theme.palette.primary.main,
      fontSize: 14,
      textDecoration: "none",
      letterSpacing: "0.15px",

      transition: "0.2s color ease",
      "&:hover": {
        color: theme.palette.primary.dark,
      },
    },
    loginButton: { margin: "32px auto 0" },
    socialLoginButtonContainer: {
      marginTop: 24,
      display: "flex",
      justifyContent: "center",
    },
    singleSignOnButton: {
      marginTop: 24,
    },
    signUpText: {
      margin: "24px 0 0",

      fontSize: 14,
      letterSpacing: "0.15px",
    },
    signUpLink: {
      display: "inline-block",
      marginLeft: "4px",

      color: theme.palette.primary.main,
      textDecoration: "none",

      transition: "0.2s color ease",
      "&:hover": {
        color: theme.palette.primary.dark,
      },
    },
  })
);

export const SignInPage = (props: RouteComponentProps<{}>) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const fetchUser = () => dispatch(FetchUser({ assureAuth: true }));
  const setNotification = (message: string) => dispatch(SetNotification(message));

  const [isLoading, setIsLoading] = useState(false);
  const [submitError, setSubmitError] = useState("");

  const [isRemindOpened, setIsRemindOpened] = useState(false);
  useEffect(() => {
    if (props.location?.state) {
      const state: any = props.location.state;

      if (state.isPasswordRemind) {
        setIsRemindOpened(true);
      }

      props.history.replace({ pathname: AuthRoutes.SignIn }, {});
    }
  }, []);

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const toggleIsPasswordVisible = () => setIsPasswordVisible((currentState) => !currentState);

  const onSubmit = async (values: ILoginForm): Promise<void | { [x: string]: string }> => {
    try {
      setIsLoading(true);

      await AuthService.login(values.email, values.password, { assureAuth: true });

      const result: any = await fetchUser();
      const user: User = result.data;

      setIsLoading(false);

      if (user && user.role === "pending") {
        setNotification("The user is not confirmed. Please confirm the user.");
        await AuthService.logout("agent-assure");
        return;
      }

      const redirectUrl = AuthService.getRedirectUrl();
      AuthService.setRedirectUrl("");
      if (redirectUrl) {
        window.location.href = redirectUrl;
        return;
      } else {
        props.history.push(AppRoutes.Main);
        return;
      }
    } catch (err) {
      setIsLoading(false);
      setSubmitError(err.message);
    }
  };

  let formMessageJsx: JSX.Element | null = null;

  if (submitError) {
    formMessageJsx = <p className={cn(classes.formMessage, classes.formMessageError)}>{submitError}</p>;
  } else if (isRemindOpened) {
    formMessageJsx = <p className={classes.formMessage}>A password reset email has been sent to your email address.</p>;
  }

  return (
    <Box className={classes.container}>
      <Typography variant="h1" className={classes.title}>
        Login
      </Typography>

      {formMessageJsx}

      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit }) => (
          <form className={classes.formContainer} onSubmit={handleSubmit}>
            <Field
              component={TextFieldControlSimple}
              type="text"
              name="email"
              label="Email"
              autoComplete="email"
              validate={FormValidators.composeValidators(FormValidators.required(), FormValidators.isEmail)}
            />
            <Field
              component={TextFieldControlSimple}
              type={!isPasswordVisible ? "password" : "text"}
              name="password"
              label="Password"
              autoComplete="current-password"
              validate={FormValidators.required()}
              endAdornment={
                <IconButton
                  onClick={toggleIsPasswordVisible}
                  edge="end"
                  size="small"
                  aria-label="toggle password visibility"
                >
                  {!isPasswordVisible ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
              }
              className={classes.nthTextField}
            />

            <Box>
              <Link className={classes.forgotPasswordLink} to={AuthRoutes.ForgotPassword}>
                Forgot password?
              </Link>
            </Box>

            <ButtonSimple type="submit" isLoading={isLoading} fullWidth className={classes.loginButton}>
              Login
            </ButtonSimple>
          </form>
        )}
      />

      <Box className={classes.socialLoginButtonContainer}>
        <LoginSocialButtons />
      </Box>
      <ButtonSimple
        to={AuthRoutes.SingleSignOn}
        variant="outlined"
        color="primary"
        fullWidth
        className={classes.singleSignOnButton}
      >
        Single Sign On
      </ButtonSimple>

      <p className={classes.signUpText}>
        Don't have an account?{" "}
        <Link className={classes.signUpLink} to={AuthRoutes.SignUp}>
          Sign up here
        </Link>
      </p>
    </Box>
  );
};

export default SignInPage;

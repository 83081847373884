import { Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";

import ButtonSimple from "@monortc/front/components/ButtonSimple";
import alienEmojiIcon from "@/assets/images/emoji-alien.png";
import { AppRoutes } from "@/constants/routes/app-routes";

const useStyles = makeStyles(
  createStyles({
    container: {
      height: "100%",
      padding: 24,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
    },
    icon: {},
    title: {
      margin: "18px auto 0",

      textAlign: "center",
      fontSize: 38,
      fontWeight: 700,
      lineHeight: 1.5,
      letterSpacing: "-0.5px",
    },
    subtitle: {
      margin: "16px auto 0",

      textAlign: "center",
      lineHeight: 1.75,
    },
    actionButton: { margin: "32px auto 0" },
  })
);

const AppError = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <img src={alienEmojiIcon} className={classes.icon} />

      <Typography variant="h1" className={classes.title}>
        Oops! Scripting Error...
      </Typography>
      <p className={classes.subtitle}>Try to refresh the page, if that still doesn't work please contact support.</p>

      <ButtonSimple to={AppRoutes.Support} className={classes.actionButton}>
        Contact support
      </ButtonSimple>
    </div>
  );
};

export default AppError;

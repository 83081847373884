import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Field, Form } from "react-final-form";
import { Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";

import FormValidators from "@monortc/front/helpers/form-validators";
import { axiosInstance } from "@monortc/front/services/AxiosService";
import TextFieldControlSimple from "@monortc/front/components/form-elements/TextFieldControlSimple";
import ButtonSimple from "@monortc/front/components/ButtonSimple";
import ApiPath from "@/constants/ApiPath";
import { AuthRoutes } from "@/constants/routes/auth-routes";

const useStyles = makeStyles(
  createStyles({
    container: {
      maxWidth: 368,
      width: "100%",
    },
    title: {
      margin: 0,

      fontSize: 24,
      fontWeight: 700,
      lineHeight: 1.25,
      letterSpacing: "0.25px",
    },
    subtitle: {
      margin: "8px 0 0",

      lineHeight: 1.75,
      letterSpacing: "0.15px",
    },
    form: {
      marginTop: 24,
    },
    resetButton: {
      marginTop: 32,
    },
    goBackButton: {
      marginTop: 24,
    },
  })
);

const ForgotPassword = (props: RouteComponentProps<{}>) => {
  const classes = useStyles();

  const [isLoading, setIsLoading] = React.useState(false);

  const onSubmit = async (values: any): Promise<any> => {
    setIsLoading(true);

    try {
      await axiosInstance.get(`${ApiPath.api.requestPassword}/${values.email}?assureAuth=true`);
      props.history.push(
        {
          pathname: AuthRoutes.SignIn,
        },
        { isPasswordRemind: true, emailRemind: values.email }
      );
    } catch (err) {
      return {
        email:
          err.response?.status === 404
            ? "User with this email not found"
            : "Could not reset password. Please contact support",
      };
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={classes.container}>
      <Typography variant="h1" className={classes.title}>
        Forgot password
      </Typography>
      <p className={classes.subtitle}>No problem, we'll send you a link to reset it.</p>

      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} className={classes.form}>
            <Field
              component={TextFieldControlSimple}
              type="text"
              name="email"
              label="Email"
              validate={FormValidators.composeValidators(FormValidators.required(), FormValidators.isEmail)}
            />

            <ButtonSimple type="submit" isLoading={isLoading} fullWidth className={classes.resetButton}>
              Submit
            </ButtonSimple>
          </form>
        )}
      />

      <ButtonSimple to={AuthRoutes.SignIn} variant="outlined" fullWidth className={classes.goBackButton}>
        Back to login
      </ButtonSimple>
    </div>
  );
};

export default withRouter(ForgotPassword);

import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { Theme, Typography } from "@mui/material";
import { WithStyles } from "@mui/styles";
import withStyles from "@mui/styles/withStyles";
import createStyles from "@mui/styles/createStyles";
import Button from "@mui/material/Button";
import ExitToApp from "@mui/icons-material/ExitToApp";
import AuthService from "../../../services/AuthService";
import { AuthRoutes } from "../../../constants/routes/auth-routes";

type StyledComponent = WithStyles<"root" | "logOutIcon" | "text">;

class Logout extends React.Component<RouteComponentProps<{}> & StyledComponent> {
  public render(): JSX.Element {
    return (
      <Button onClick={() => this.logOut()} color="primary" data-cy="logout-btn" className={this.props.classes.root}>
        <ExitToApp className={this.props.classes.logOutIcon} />
        <Typography variant="body2" color="primary" className={this.props.classes.text}>
          Logout
        </Typography>
      </Button>
    );
  }

  private logOut(): void {
    AuthService.logout("testrtc");
    this.props.history.push(AuthRoutes.SignIn);
  }
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      "@media(max-width: 700px)": {
        minWidth: 40,
        padding: 0,
      },
    },
    logOutIcon: {
      [theme.breakpoints.up("xs")]: {
        marginRight: 5,
      },
    },
    text: {
      fontWeight: 500,
      letterSpacing: "0.02857em",

      "@media(max-width: 700px)": {
        display: "none",
      },
    },
  });

const decorate = withStyles(styles);

export default withRouter(decorate(Logout));

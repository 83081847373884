// tslint:disable:no-any
import * as React from "react";
import { Button, Grid, IconButton, Menu, MenuItem, Theme, Toolbar, Tooltip, Typography } from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import { Table, TableBody, TableCell, TablePagination, TableRow, Hidden } from "@mui/material";
import { ReactSVG } from "react-svg";
import ExportIcon from "@mui/icons-material/GetAppOutlined";

import EnhancedTableHead, { ColumnSchemaHideDown } from "./EnhancedTableHead";
import { GridProps, GridState, GridHandlers } from "./index";
import SearchToolbar from "./SearchToolbar";
import GridPaginationActions from "./GridPaginationActions";
import LoaderIcon from "../../assets/images/loading-progress.svg";
import useExportConfig from "src/hooks/useExportConfig";
import ExportDialog from "../AgGrid/Presets/ExportDialog";
import { ExportFormat } from "../AgGrid/types";
import {
  PROJECT_EXPORT_RETENTION_PERIOD_KEYS,
  SYSTEM_EXPORT_RETENTION_PERIOD_DAYS,
  defaultExportRetentionPeriodDays,
  defaultExportRowLimit,
} from "../AgGrid/Presets";
import { ProjectsContext } from "src/containers/AppController";

const PaginationRootComponent = (props: any) => <div {...props} />;

type GridViewProps<T extends GridModel> = GridProps<T> & GridState<T> & GridHandlers;

interface GridModelWithName extends GridModel {
  inRoomIdx?: number;
  inSessionIdx?: number;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolbar: {
      padding: "16px 24px",
      borderBottom: `1px solid ${theme.palette.divider}`,
      alignItems: "center",
      justifyContent: "space-between",
    },
    exportActionsContainer: {
      marginLeft: "auto",
    },
    exportButton: {
      border: `1px solid ${theme.palette.action.active}`,
      borderRadius: "4px",

      "& .MuiTouchRipple-root .MuiTouchRipple-child": {
        borderRadius: "4px",
      },
    },
    exportIcon: { color: "#547189" },
    table: {
      "& thead": {
        "& th": { color: "rgba(0, 0, 0, 0.54)", fontSize: theme.typography.pxToRem(12) },
      },
    },
    tableRowItem: {
      "& td": {
        fontSize: theme.typography.pxToRem(13),
        wordBreak: "break-all",
      },
      "&:hover": {
        cursor: "pointer",
        backgroundColor: "#f7f7f7",
      },
    },
    searchField: {
      width: 245,
      marginRight: theme.spacing(1),
    },
    noRowsCell: {
      padding: theme.spacing(3),
      color: "rgba(0, 0, 0, 0.54)",
    },
    searchCell: {
      paddingRight: 0,
      minWidth: 373,
    },
    searchBtn: {
      width: 30,
      height: 30,
    },
    paginationRoot: {
      display: "flex",
      justifyContent: "flex-end",
      color: "rgba(0, 0, 0, 0.54)",
    },
    paginationSelectRoot: {
      marginRight: 8,
      paddingTop: 4,
    },
    paginationSelect: {
      paddingRight: 24,
      fontSize: theme.typography.pxToRem(12),
      minHeight: "1.5em",
    },
    searchBar: {
      paddingTop: 8,

      [theme.breakpoints.up("lg")]: {
        maxWidth: "60% !important",
      },
    },
    paginationSelectIcon: {
      top: 4,
    },
    paginationSpacer: {
      flex: "unset",
    },
    paginationToolbar: {
      padding: 0,
      [theme.breakpoints.down("sm")]: {
        paddingLeft: 16,
        paddingRight: 16,
      },
    },
    toolbarCaption: {
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
      letterSpacing: "0.01em",
      fontSize: theme.typography.pxToRem(12),
      lineHeight: 1.66,
    },
    progress: {
      display: "flex",
      justifyContent: "center",
      margin: `${theme.spacing(1)} auto`,
      "& svg": {
        height: 60,
      },
    },
    exportBtn: {
      padding: "6px 15px",
      margin: 5,
    },
  })
);

const GridView = <T extends GridModelWithName>({
  columnSchema,
  data,
  sort,
  search,
  pagination,
  title,
  onTitleCtrlClick,
  isFramed,
  exportUrl,
  searchByLabel,
  searchStyle,
  filters,
  filter,
  dataPending,
  isExporting,
  rowProps,
  hidePaginationWhenDataLessThan,
  hideHeader,
  rowsPerPageOptions,
  searchValue,
  bottomNavigation,
  agGridExportUrl,
  exportProduct,
  customExportRowLimit,
  customExportColumnNames,
  isJsonExportAvailable,
  onRequestSort,
  onChangePage,
  onChangeRowsPerPage,
  onSubmitFilterSearch,
  onRowClick,
  rowColorFunc,
  onExportClick,
  onAgGridExport,
}: GridViewProps<T>) => {
  const classes = useStyles();

  const { exportConfig } = useExportConfig(!agGridExportUrl || isFramed);

  const { selectedProject } = React.useContext(ProjectsContext);

  const [moreDialogAnchorEl, setMoreDialogAnchorEl] = React.useState<null | HTMLElement>(null);
  const isMoreDialogOpen = Boolean(moreDialogAnchorEl);
  const openMoreDialog = (event: React.MouseEvent<HTMLElement>) => {
    setMoreDialogAnchorEl(event.currentTarget);
  };
  const closeMoreDialog = () => {
    setMoreDialogAnchorEl(null);
  };

  const [exportFormat, setExportFormat] = React.useState<ExportFormat | undefined>();
  const openExportDialog = (newExportFormat: ExportFormat) => setExportFormat(newExportFormat);
  const closeExportDialog = () => setExportFormat(undefined);

  const exportRetentionPeriodDays = exportProduct
    ? Math.min(
        selectedProject?.[PROJECT_EXPORT_RETENTION_PERIOD_KEYS[exportProduct]],
        SYSTEM_EXPORT_RETENTION_PERIOD_DAYS[exportProduct]
      )
    : defaultExportRetentionPeriodDays;
  const exportRowLimit = customExportRowLimit ?? exportConfig?.maxObjectsToExport ?? defaultExportRowLimit;
  const isExportEnabled = agGridExportUrl && exportRowLimit;

  let columnNames: string[];
  if (!customExportColumnNames) {
    columnNames = columnSchema
      ?.map((colDef) => colDef.label)
      .filter((columnName): columnName is string => Boolean(columnName));
  } else if (typeof customExportColumnNames === "string") {
    columnNames = customExportColumnNames.split(",");
  } else {
    columnNames = customExportColumnNames;
  }

  let conditionalHidePagination = false;
  if (hidePaginationWhenDataLessThan) {
    conditionalHidePagination = hidePaginationWhenDataLessThan > data.docs.length && data.page === 1;
  }
  const hideTableHead = hideHeader as boolean;

  const _rowsPerPageOptions = rowsPerPageOptions || [25, 50, 100];

  const cellRender = (model: T, column: ColumnSchema, rowIndex: number) => {
    if (!column.isObject) {
      return column.render ? column.render(model, rowIndex) : model[column.id];
    } else {
      const value = column.id.split(".").reduce((a, b) => (a ? a[b] : null), model);
      return column.render ? column.render(value, rowIndex) : value;
    }
  };

  return (
    <React.Fragment>
      {(search || pagination) && (
        <Grid
          container={true}
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          {title || isExportEnabled ? (
            <Toolbar className={classes.toolbar}>
              {title ? (
                <Typography variant="subtitle1" onClick={onTitleCtrlClick}>
                  {title}
                </Typography>
              ) : null}

              {isExportEnabled && (
                <div className={classes.exportActionsContainer}>
                  <Tooltip title="Download">
                    <IconButton
                      onClick={(event) => (isJsonExportAvailable ? openMoreDialog(event) : openExportDialog("csv"))}
                      className={classes.exportButton}
                    >
                      <ExportIcon className={classes.exportIcon} />
                    </IconButton>
                  </Tooltip>
                  <Menu
                    anchorEl={moreDialogAnchorEl}
                    id="account-menu"
                    open={isMoreDialogOpen}
                    onClose={closeMoreDialog}
                    onClick={closeMoreDialog}
                    transformOrigin={{ horizontal: "right", vertical: "top" }}
                    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                  >
                    {[
                      <MenuItem onClick={() => openExportDialog("csv")} key="downloadCsv">
                        <ExportIcon fontSize="small" htmlColor="#c5c5c5" />
                        <Typography fontSize={14} marginLeft={1}>
                          Download CSV
                        </Typography>
                      </MenuItem>,
                      isJsonExportAvailable ? (
                        <MenuItem onClick={() => openExportDialog("json")} key="downloadJson">
                          <ExportIcon fontSize="small" htmlColor="#c5c5c5" />
                          <Typography fontSize={14} marginLeft={1}>
                            Download JSON
                          </Typography>
                        </MenuItem>
                      ) : null,
                    ]}
                  </Menu>
                </div>
              )}
            </Toolbar>
          ) : null}

          {search && (
            <Grid item={true} xs={12} lg={7} className={classes.searchBar}>
              <SearchToolbar
                onSubmit={onSubmitFilterSearch}
                placeholder={`Search by ${searchByLabel}`}
                colSpan={columnSchema.length / 2 + 1}
                filters={filters}
                filter={filter}
                search={search}
                searchStyle={searchStyle}
                searchValue={searchValue}
              />
            </Grid>
          )}

          {pagination && !conditionalHidePagination && (
            <Grid
              item={true}
              xs={12}
              lg={search ? 5 : 12}
              sx={{
                maxWidth: "100% !important",
              }}
            >
              <TablePagination
                classes={{
                  root: classes.paginationRoot,
                  selectRoot: classes.paginationSelectRoot,
                  select: classes.paginationSelect,
                  selectIcon: classes.paginationSelectIcon,
                  spacer: classes.paginationSpacer,
                  toolbar: classes.paginationToolbar,
                  selectLabel: classes.toolbarCaption,
                  displayedRows: classes.toolbarCaption,
                }}
                component={PaginationRootComponent}
                colSpan={columnSchema.length / 2 - 1}
                count={data.totalDocs}
                rowsPerPage={data.limit}
                rowsPerPageOptions={_rowsPerPageOptions}
                page={data.page - 1}
                labelRowsPerPage={<span>Items</span>}
                onPageChange={onChangePage as any}
                onRowsPerPageChange={onChangeRowsPerPage}
                ActionsComponent={GridPaginationActions}
              />
            </Grid>
          )}
        </Grid>
      )}
      <Table className={classes.table}>
        {!hideTableHead && (
          <EnhancedTableHead
            columnSchema={columnSchema}
            order={sort.order}
            orderBy={sort.orderBy}
            rowCount={data.totalDocs}
            onRequestSort={onRequestSort}
          />
        )}
        <TableBody>
          {Array.isArray(data.docs) && data.docs.length > 0 ? (
            data.docs.map((model: T, i: number) => (
              <TableRow
                onClick={(e: any) => (onRowClick ? onRowClick(e, model) : undefined)}
                className={[classes.tableRowItem, "avoid"].join(" ")}
                key={i}
                id={model.inRoomIdx ? `watchrtc-peer-${model.inRoomIdx}` : `testrtc-peer-${i + 1}`}
                style={rowColorFunc !== undefined ? { background: rowColorFunc(model) } : {}}
                {...rowProps}
              >
                {columnSchema.map((column: ColumnSchema, index: number) => (
                  <Hidden
                    key={index}
                    xlDown={column.hideDown === ColumnSchemaHideDown.lg}
                    lgDown={column.hideDown === ColumnSchemaHideDown.md}
                    mdDown={column.hideDown === ColumnSchemaHideDown.sm}
                    smDown={column.hideDown === ColumnSchemaHideDown.xs}
                  >
                    <TableCell
                      size="medium"
                      padding={column.disablePadding ? "none" : undefined}
                      align={column.numeric ? "right" : "left"}
                      style={column.style}
                    >
                      {cellRender(model, column, i)}
                    </TableCell>
                  </Hidden>
                ))}
              </TableRow>
            ))
          ) : (
            <tr>
              <td className={classes.noRowsCell} colSpan={columnSchema.length}>
                {!dataPending ? (
                  <Typography variant="body2" align="center">
                    No rows to show
                  </Typography>
                ) : (
                  <ReactSVG src={LoaderIcon} className={classes.progress} />
                )}
              </td>
            </tr>
          )}
        </TableBody>
      </Table>
      {data.docs.length !== 0 && (
        <Grid
          container={true}
          spacing={0}
          justifyContent={!exportUrl && bottomNavigation ? "flex-end" : "space-between"}
        >
          {exportUrl && (
            <Button
              variant="outlined"
              color="primary"
              className={classes.exportBtn}
              onClick={onExportClick}
              disabled={isExporting || dataPending}
            >
              {isExporting ? "Exporting" : "Export"}
            </Button>
          )}
          {bottomNavigation && !conditionalHidePagination && (
            <TablePagination
              classes={{
                root: classes.paginationRoot,
                selectRoot: classes.paginationSelectRoot,
                select: classes.paginationSelect,
                selectIcon: classes.paginationSelectIcon,
                spacer: classes.paginationSpacer,
                toolbar: classes.paginationToolbar,
                selectLabel: classes.toolbarCaption,
                displayedRows: classes.toolbarCaption,
              }}
              component={PaginationRootComponent}
              colSpan={columnSchema.length / 2 - 1}
              count={data.totalDocs}
              rowsPerPage={data.limit}
              rowsPerPageOptions={_rowsPerPageOptions}
              page={data.page - 1}
              labelRowsPerPage={<span>Items</span>}
              onPageChange={onChangePage as any}
              onRowsPerPageChange={onChangeRowsPerPage}
              ActionsComponent={GridPaginationActions}
            />
          )}
        </Grid>
      )}

      {isExportEnabled ? (
        <ExportDialog
          format={exportFormat}
          retentionPeriodDays={exportRetentionPeriodDays}
          rowLimit={exportRowLimit}
          columnNames={columnNames}
          doExport={onAgGridExport}
          closeDialog={closeExportDialog}
        />
      ) : null}
    </React.Fragment>
  );
};

export default GridView;

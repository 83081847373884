import { Theme, alpha } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import cn from "classnames";

import Map, { MapProps } from "src/components/Map";
import SectionDataPoint, { SectionDataPointConfig } from "../SectionDataPoint";

type KeyValueData = (SectionDataPointConfig | undefined)[];

interface KeyValueSection {
  variant: "keyValue";

  title: string;
  data: KeyValueData;
}

interface KeyValueColumnsSection {
  variant: "keyValueColumns";

  title: string;
  data: [KeyValueData, KeyValueData];
}

interface KeyValueColumnsWithTitlesSection {
  variant: "keyValueColumnsWithTitles";

  title?: never;
  data: [{ title: string; data: KeyValueData }, { title: string; data: KeyValueData }];
}

interface MapSection {
  variant: "map";

  title: string;
  data: MapProps;
}

export type SectionConfig = KeyValueSection | KeyValueColumnsSection | KeyValueColumnsWithTitlesSection | MapSection;

type SectionProps = SectionConfig;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      minWidth: 0,
      flex: "1 0 240px",
      position: "relative",
      margin: "16px 0 0",
      padding: "0 16px 16px",
      border: `1px solid ${alpha(theme.palette.text.primary, 0.12)}`,
      borderRadius: 4,
    },
    containerMap: {
      padding: 0,
    },
    title: {
      margin: "16px 0 8px",

      fontSize: 14,
      lineHeight: "22px",
      fontWeight: 500,
      letterSpacing: "0.1px",
    },
    innerTitle: {
      margin: "16px 0",
    },
    titleMap: {
      margin: "16px 16px 16px",
    },
    mapTitle: {
      position: "absolute",
      top: 0,
      left: "16px",
      zIndex: 100,
      fontSize: 14,
      lineHeight: "22px",
      fontWeight: 500,
      letterSpacing: "0.1px",
    },

    columnsContainer: { display: "flex", columnGap: "16px", flexWrap: "wrap" },
    column: { minWidth: 0, flex: "1 0 200px" },
  })
);

const Section = ({ title, variant, data }: SectionProps) => {
  const classes = useStyles();
  let contentJsx: JSX.Element | JSX.Element[] | null = null;

  if (variant === "keyValue") {
    const dataJsx = data
      .filter((dataPoint): dataPoint is SectionDataPointConfig => Boolean(dataPoint))
      .map(function getDataPointJsx(dataPoint) {
        return <SectionDataPoint {...dataPoint} key={dataPoint.label} />;
      });

    if (dataJsx.length > 2) {
      const dataJsxMiddleElementIndex = Math.ceil(dataJsx.length / 2);
      const firstColumnJsx = <div className={classes.column}>{dataJsx.slice(0, dataJsxMiddleElementIndex)}</div>;
      const secondColumnJsx = <div className={classes.column}>{dataJsx.slice(dataJsxMiddleElementIndex)}</div>;

      contentJsx = (
        <>
          <h3 className={classes.title}>{title}</h3>
          <div className={classes.columnsContainer}>
            {firstColumnJsx}
            {secondColumnJsx}
          </div>
        </>
      );
    } else {
      contentJsx = (
        <>
          <h3 className={classes.title}>{title}</h3>
          <div className={classes.columnsContainer}>
            <div className={classes.column}>{dataJsx}</div>
          </div>
        </>
      );
    }
  } else if (variant === "keyValueColumns") {
    const columnsJsx = data.map((column, index) => (
      <>
        <div className={classes.column} key={index}>
          {column
            .filter((dataPoint): dataPoint is SectionDataPointConfig => Boolean(dataPoint))
            .map(function getDataPointJsx(dataPoint) {
              return <SectionDataPoint {...dataPoint} key={dataPoint.label} />;
            })}
        </div>
      </>
    ));

    contentJsx = (
      <>
        <h3 className={classes.title}>{title}</h3>
        <div className={classes.columnsContainer}>{columnsJsx}</div>
      </>
    );
  } else if (variant === "keyValueColumnsWithTitles") {
    const columnsJsx = data.map((column, index) => (
      <>
        <div className={classes.column} key={index}>
          <h3 className={cn(classes.title, classes.innerTitle)}>{column.title ?? ""}</h3>
          {column.data
            .filter((dataPoint): dataPoint is SectionDataPointConfig => Boolean(dataPoint))
            .map(function getDataPointJsx(dataPoint) {
              return <SectionDataPoint {...dataPoint} key={dataPoint.label} />;
            })}
        </div>
      </>
    ));

    contentJsx = <div className={classes.columnsContainer}>{columnsJsx}</div>;
  } else {
    contentJsx = (
      <>
        <h3 className={classes.mapTitle}>{title}</h3>
        <Map locations={data.locations} remoteLocations={data.remoteLocations} />
      </>
    );
  }

  return <div className={cn(classes.container, { [classes.containerMap]: variant === "map" })}>{contentJsx}</div>;
};

export default Section;

import * as React from "react";
import { useState } from "react";
import {
  Card,
  Divider,
  FormLabel,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { ProjectPropertiesState } from "../";
import { ProjectPropertiesViewProps } from "../View";
import { Field } from "react-final-form";
import TextFieldControl from "src/components/form-elements/TextFieldControl";
import FormValidators from "src/helpers/form-validators";
import UsersAutocomplete from "./UsersAutocomplete";
import { Cancel } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import { AppRoutes } from "../../../../constants/routes/app-routes";
import CheckboxControl from "src/components/form-elements/CheckboxControl";

export interface ProjectUser {
  _id: string;
  role: string;
  company: string;
  email: string;
  roleId?: string; // role id in project if !admin
}

const TableRowStyles = {
  height: "33px",
} as React.CSSProperties;

const TableCellStyles = {
  height: "33px",
  paddingTop: "1px",
  paddingBottom: "1px",
} as React.CSSProperties;

const General = ({
  classes,
  addUser,
  autocompleteUsers,
  selectedUsers,
  removeUser,
  searchUsers,
  project,
}: ProjectPropertiesViewProps & ProjectPropertiesState) => {
  const [editingUser, setEditingUser] = useState("");
  const getRoles = (user: any) => {
    return project.rolePool.map((role: Role) => {
      return (
        <div
          className={`${classes.roleItem} ${user.roleId === role._id && classes.assignedRole}`}
          key={role._id}
          onClick={() => {
            setEditingUser("");
            user.roleId = role._id;
          }}
        >
          {role.name}
        </div>
      );
    });
  };
  const getUserRole = (user: any) => {
    const editing = editingUser === user._id;
    if (user.role === "admin") {
      return <span>Super Admin</span>;
    }
    let role = project.rolePool.find((role: Role) => role._id.toString() === user.roleId);
    let err = false;
    if (!role) {
      err = true;
      role = project.rolePool[1] || project.rolePool[0];
      user.hasNoRole = true;
      user.roleId = role._id;
    }
    return (
      <Grid container={true} justifyContent="space-between" alignItems="center">
        <div className={classes.userRole}>
          {editing ? (
            <>
              <span>{role.name}</span>
              <Card className={classes.userRoles}>
                <span>Select new role :</span>
                {getRoles(user)}
              </Card>
            </>
          ) : (
            <span style={{ color: err || user.hasNoRole ? "red" : "inherit" }}>{role.name}</span>
          )}
        </div>
        <IconButton
          style={{ padding: 8 }}
          onClick={() => {
            if (editing) {
              setEditingUser("");
            } else {
              setEditingUser(user._id);
            }
          }}
          size="large"
        >
          {editing ? <Cancel fontSize="small" /> : <EditIcon fontSize="small" />}
        </IconButton>
      </Grid>
    );
  };

  return (
    <Card className={classes.card} style={{ padding: 0, overflow: "visible", margin: 0 }}>
      <Toolbar>
        <Typography variant="subtitle1">General</Typography>
      </Toolbar>
      <Divider />
      <Grid container={true} spacing={2} style={{ padding: 24 }}>
        <Grid item={true} xs={12} container={true}>
          <Grid item={true}>
            <FormLabel className={classes.fieldLabel}>Project Name</FormLabel>
          </Grid>
          <Grid item={true} className={classes.formFieldGridItem}>
            <Field component={TextFieldControl} type="text" name="name" validate={FormValidators.required()} />
          </Grid>
        </Grid>

        <Grid item={true} xs={12} container={true}>
          <Grid item={true}>
            <FormLabel className={classes.fieldLabel}>Is SSO allowed?</FormLabel>
          </Grid>
          <Grid item={true} className={classes.formFieldGridItem}>
            <Field component={CheckboxControl} name="isSSOAllowed" />
          </Grid>
        </Grid>
        <Grid item={true} xs={12} container={true}>
          <Grid item={true}>
            <FormLabel className={classes.fieldLabel}>Domain</FormLabel>
          </Grid>
          <Grid item={true} className={classes.formFieldGridItem}>
            <Field component={TextFieldControl} type="text" name="domain" />
          </Grid>
        </Grid>
        <Grid item={true} xs={12} container={true}>
          <Grid item={true}>
            <FormLabel className={classes.fieldLabel}>Max Users</FormLabel>
          </Grid>
          <Grid item={true} className={classes.formFieldGridItem}>
            <Field component={TextFieldControl} type="number" name="maxUsers" />
          </Grid>
        </Grid>
        <Grid item={true} xs={12} container={true}>
          <Grid item={true}>
            <FormLabel className={classes.fieldLabel}>Add User</FormLabel>
          </Grid>
          <Grid item={true} className={classes.formFieldGridItem}>
            <UsersAutocomplete suggestions={autocompleteUsers} onChange={addUser} onInputChange={searchUsers} />
          </Grid>
        </Grid>
        {selectedUsers.length !== 0 && (
          <Grid item={true} xs={12} container={true}>
            <Grid item={true}>
              <FormLabel className={classes.fieldLabel}>User List</FormLabel>
            </Grid>
            <Grid item={true} className={classes.formFieldGridItem}>
              <Table>
                <TableHead>
                  <TableRow style={TableRowStyles}>
                    <TableCell style={TableCellStyles}>Email</TableCell>
                    <TableCell style={TableCellStyles}>Company</TableCell>
                    <TableCell style={{ ...TableCellStyles, width: 240 }}>Role</TableCell>
                    <TableCell style={TableCellStyles} />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selectedUsers.map((user) => {
                    return (
                      <TableRow key={user._id} style={TableRowStyles}>
                        <TableCell style={TableCellStyles}>
                          <a
                            href={`${AppRoutes.UserDetails}/${user._id}`}
                            target="_blank"
                            className={classes.userLink}
                            rel="noopener noreferrer"
                          >
                            {user.email}
                          </a>
                        </TableCell>
                        <TableCell style={TableCellStyles}>{user.company}</TableCell>
                        <TableCell style={TableCellStyles}>{getUserRole(user)}</TableCell>
                        <TableCell style={TableCellStyles}>
                          {" "}
                          <IconButton
                            style={{ padding: "8px" }}
                            aria-label="delete"
                            onClick={() => removeUser(user._id)}
                            size="large"
                          >
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Grid>
          </Grid>
        )}
        <Grid item={true} xs={12} container={true}>
          <Grid item={true}>
            <FormLabel className={classes.fieldLabel}>Notes</FormLabel>
          </Grid>
          <Grid item={true} className={classes.formFieldGridItem}>
            <Field component={TextFieldControl} type="text" name="notes" multiline={true} rows="5" resizeY={true} />
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};

export default General;

import SDPUtils from "../../../../helpers/sdpHelper";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useState } from "react";
import CopyIcon from "@mui/icons-material/FileCopy";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { connect } from "react-redux";
import { SetNotification } from "../../../../actions/notificationAction";
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import IconButton from "@mui/material/IconButton";

type StyledComponent = WithStyles<"copyIcon" | "sectionWrap" | "sectionHeader" | "sectionHeaderText">;

type DisplaySDPProps = {
  event: ProcessEvent;
};

type SectionProps = {
  section: string;
  expandAll: boolean;
};

type DispatchProps = {
  setNotification?: any;
};

const Section = (props: SectionProps & StyledComponent) => {
  const { section, expandAll, classes } = props;
  const [expanded, setExpanded] = useState(false);

  if (!section) {
    return null;
  }
  const lines = SDPUtils.splitLines(section);

  const isExpanded = expandAll || expanded;

  return (
    <div
      className={classes.sectionWrap}
      onClick={(e: any) => {
        e.stopPropagation();
        e.preventDefault();
        setExpanded(!expanded);
      }}
    >
      <div className={classes.sectionHeader}>
        {!expandAll && (
          <>
            <ExpandMoreIcon style={{ transform: `${expanded ? "rotate(180deg)" : ""}` }} />
            <div className={classes.sectionHeaderText}>{lines[0] + " (" + (lines.length - 1) + " more lines)"}</div>
          </>
        )}
      </div>
      {isExpanded && (
        <div style={{ paddingLeft: 24 }}>
          {lines.map((line: any, index: number) => {
            return <div key={`line-${index}`}>{line}</div>;
          })}
        </div>
      )}
    </div>
  );
};

const DisplaySDP = (props: DisplaySDPProps & DispatchProps & StyledComponent) => {
  const [expandAll, setExpandAll] = useState(false);
  const { event, setNotification, classes } = props;
  if (!event) {
    return null;
  }

  const [type, sdp] = event.value.substr(6).split(`, sdp: `);
  let parsedSDP: any;
  let parsedType: any;
  if (!sdp) {
    try {
      const _t = event.value.replace(/\\"|{|}|"/gi, ``).split("sdp:");
      parsedType = '"' + _t[0].replace(/type:|,/gi, "") + '"';
      parsedSDP = _t[1];
    } catch (e) {
      console.log("SDP parse error", e.message);
    }
  } else {
    parsedType = type;
    parsedSDP = sdp;
  }
  if (!parsedSDP) {
    return <span style={{ marginLeft: 24 }}>failed to parse SDP messages</span>;
  }
  const sections = SDPUtils.splitSections(parsedSDP);

  return (
    <div>
      <div>
        type: {parsedType}{" "}
        <IconButton
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setExpandAll(!expandAll);
          }}
          color={"primary"}
          children={expandAll ? "-" : "+"}
          size="large"
        />
      </div>
      {sections.map((section: any, index: number) => {
        return <Section classes={classes} expandAll={expandAll} key={`section-${index}`} section={section} />;
      })}
      <CopyToClipboard text={JSON.stringify(event.value)}>
        <CopyIcon
          className={classes.copyIcon}
          color="primary"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setNotification && setNotification("SDP Message copied to clipboard");
          }}
        />
      </CopyToClipboard>
    </div>
  );
};

const decorate = withStyles({
  copyIcon: {
    cursor: "pointer",
    "&:hover": {
      color: "#007dc6",
    },
  },
  sectionWrap: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  sectionHeader: {
    display: "flex",
    alignItems: "center",
  },
  sectionHeaderText: {
    fontWeight: "bold",
    marginTop: 5,
  },
});

const mapDispatchToProps = (dispatch: any) => ({
  setNotification: (message: string) => dispatch(SetNotification(message)),
});

export default connect(null, mapDispatchToProps)(decorate(DisplaySDP));

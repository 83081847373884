import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { Form, Field } from "react-final-form";
import { IconButton, Typography } from "@mui/material";
import { Visibility as VisibilityIcon, VisibilityOff as VisibilityOffIcon } from "@mui/icons-material";
import { createStyles, makeStyles } from "@mui/styles";

import FormValidators from "@monortc/front/helpers/form-validators";
import { axiosInstance } from "@monortc/front/services/AxiosService";
import { SetNotification } from "@monortc/front/actions/notificationAction";
import TextFieldControlSimple from "@monortc/front/components/form-elements/TextFieldControlSimple";
import ButtonSimple from "@monortc/front/components/ButtonSimple";
import ApiPath from "@/constants/ApiPath";
import { AuthRoutes } from "@/constants/routes/auth-routes";

const FORM_FIELDS = {
  password: "password",
  repeatPassword: "repeatPassword",
} as const;

interface FormData {
  [FORM_FIELDS.password]: string;
  [FORM_FIELDS.repeatPassword]: string;
}

interface ResetPasswordRouteParams {
  token: string;
}

const useStyles = makeStyles(
  createStyles({
    container: {
      maxWidth: 368,
      width: "100%",
    },
    title: {
      margin: 0,

      fontSize: 24,
      fontWeight: 700,
      lineHeight: 1.25,
      letterSpacing: "0.25px",
    },
    subtitle: {
      margin: "8px 0 0",

      lineHeight: 1.75,
      letterSpacing: "0.15px",
    },
    form: {
      marginTop: 24,
    },
    nthTextField: {
      marginTop: 16,
    },
    submitButton: {
      margin: "32px auto 0",
    },
  })
);

const ResetPassword = (props: RouteComponentProps<ResetPasswordRouteParams>) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const [validating, setValidating] = useState(true);

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const toggleIsPasswordVisible = () => setIsPasswordVisible((currentState) => !currentState);

  const setNotification = (message: string) => dispatch(SetNotification(message));

  const validate = async () => {
    try {
      if (!props.match.params.token) {
        props.history.push(AuthRoutes.SignIn);
        return;
      }
      setValidating(true);

      await axiosInstance.get(`${ApiPath.api.user.resetPassword}/${props.match.params.token}`);
    } catch (err) {
      setNotification(err.response.data.message || "The request is not valid.");
      props.history.push(AuthRoutes.SignIn);
    } finally {
      setValidating(false);
    }
  };

  useEffect(() => {
    validate();
  }, []);

  const onSubmit = async (values: FormData) => {
    const errors: Partial<FormData> = {};

    if (values.password !== values.repeatPassword) {
      errors.repeatPassword = "Passwords do not match";
    } else {
      try {
        await axiosInstance.post(`${ApiPath.api.user.resetPassword}/${props.match.params.token}`, {
          password: values.password,
        });

        setNotification("Password has been changed successfully, now you can log in.");
        props.history.push(AuthRoutes.SignIn);
      } catch (err) {
        setNotification(err.response.data.message || "Unexpected error. Please contact support");
      }
    }

    return errors;
  };

  if (validating) {
    return null;
  }

  return (
    <div className={classes.container}>
      <Typography variant="h1" className={classes.title}>
        Reset Password
      </Typography>
      <p className={classes.subtitle}>Please enter a new password.</p>

      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} className={classes.form}>
            <Field
              component={TextFieldControlSimple}
              type={!isPasswordVisible ? "password" : "text"}
              name={FORM_FIELDS.password}
              label="Password"
              helperText="Minimum 8 characters"
              endAdornment={
                <IconButton
                  onClick={toggleIsPasswordVisible}
                  edge="end"
                  size="small"
                  aria-label="toggle password visibility"
                >
                  {!isPasswordVisible ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
              }
              validate={FormValidators.composeValidators(FormValidators.required(), FormValidators.minValue(8))}
            />
            <Field
              component={TextFieldControlSimple}
              type={!isPasswordVisible ? "password" : "text"}
              name={FORM_FIELDS.repeatPassword}
              label="Confirm Password"
              validate={FormValidators.composeValidators(FormValidators.required(), FormValidators.minValue(8))}
              endAdornment={
                <IconButton
                  onClick={toggleIsPasswordVisible}
                  edge="end"
                  size="small"
                  aria-label="toggle password visibility"
                >
                  {!isPasswordVisible ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
              }
              className={classes.nthTextField}
            />

            <ButtonSimple type="submit" fullWidth className={classes.submitButton}>
              Submit
            </ButtonSimple>
          </form>
        )}
      />
    </div>
  );
};

export default ResetPassword;

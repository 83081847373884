import { Check, Close } from "@mui/icons-material";
import { Box, Button, Dialog, DialogContent, Link, Popover, Stack, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import * as React from "react";
import { useSelector } from "react-redux";
import { ReactSVG } from "react-svg";
// import spearlineLogo from "./assets/spearline-testrtc-logo.svg";
import otherProductsLogo from "./assets/other-products.svg";
import TestRTCLogo from "./assets/testrtc-logo.svg";
import VoiceAssureLogo from "./assets/voiceassure-logo.svg";
// import AgentAssureLogo from "./assets/agentassure-logo.svg";
import CyaraLogo from "./assets/cyara-logo.svg";
import CyaraLogoW from "./assets/cyara-logo-white.svg";
import Loader from "../Loader/Loader";
import axios from "axios";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    productItem: {
      display: "flex",
      justifyContent: "space-between",
      cursor: "pointer",
      padding: "8px 16px",
      "&:hover": {
        background: "#0091FF0A",
      },
    },
    otherProducts: {
      fontSize: "12px",
      color: "#6B7178",
      padding: "14px 24px",
      textTransform: "none",
      width: "100%",
      justifyContent: "flex-start",
      borderTop: "1px solid #FFFFFF1F",
      borderRadius: 0,
      display: "flex",
      alignItems: "center",
    },
    asd: {
      backgroundColor: theme.palette.divider,
    },
    poweredByIcon: {
      marginLeft: "8px",
    },
    poweredBy: {
      padding: "24px 17px",
      fontSize: "14px",
      color: "#ADB0B7",
      width: "100%",
      justifyContent: "center",
      textTransform: "none",
      borderTop: "1px solid #FFFFFF1F",
      borderRadius: 0,
      display: "flex",
      alignItems: "center",
    },
    otherProductsIcon: {
      marginRight: "12px",
      width: "18px",
      height: "18px",
    },
    oneUITexts: {
      fontSize: "13px",
      lineHeight: "15px",
      fontWeight: 400,
    },
    myProducts: {
      lineHeight: "20px",
      padding: "16px",
    },
    exploreProducts: {
      padding: "16px",
    },
    productButton: {
      width: "100%",
      display: "flex",
      alignItems: "start",
      justifyContent: "start",
      padding: 0,
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
  })
);

type PopupProps = {
  agentAssure: boolean;
};

const OneUI_API = process.env.REACT_APP_ONE_UI_BASE_API;

const Popup = ({ agentAssure }: PopupProps) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [openModal, setOpenModal] = React.useState(false);
  const [iframeLink, setIframeLink] = React.useState("");
  const [loading, setLoading] = React.useState(true);
  const [token, setToken] = React.useState("");
  const { user } = useSelector((state: IStore) => state.userAuth);
  const [oneUI, setOneUI] = React.useState(user.oneUI);
  const iframeLoad = () => {
    setLoading(false);
  };

  const allProducts = [
    {
      name: "testRTC",
      logo: TestRTCLogo,
      iframeLink: "https://www.spearline.com/what-we-do/products/testrtc-oneui/",
      myProductLink: "https://app.testrtc.com/app/main",
    },
    {
      name: "voiceAssure",
      logo: VoiceAssureLogo,
      iframeLink: "https://www.spearline.com/what-we-do/voice-assure-oneui/",
      myProductLink: "https://platform.spearline.com/",
    },
    // {
    //   name: "agentAssure",
    //   logo: AgentAssureLogo,
    //   link: "https://www.spearline.com/what-we-do/products/agent-assure-oneui/",
    // },
    {
      name: "cyara",
      logo: CyaraLogo,
      iframeLink: "https://cyara.com/lp/cyara-automated-cx-assurance-platform-embed/",
      myProductLink: "/",
    },
  ];

  const myProducts = allProducts.filter((product) => oneUI[product.name]);
  const exploreProducts = allProducts.filter((product) => !oneUI[product.name]);

  const getToken = async () => {
    try {
      const response = await axios.post(
        `${OneUI_API}/admin/api/user/token`,
        {
          email: process.env.REACT_APP_ONE_UI_EMAIL,
          password: process.env.REACT_APP_ONE_UI_PASSWORD,
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      setToken(response.data.data.token);
    } catch (error) {
      console.error("Error getting token:", error);
    }
  };

  const getUser = async () => {
    try {
      const response = await axios.post(
        `${OneUI_API}/admin/api/user/is-exist`,
        {
          email: user.email,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      const voiceAssureResponse = {
        voiceAssure: response.data.data.isExist,
      };

      setOneUI((prevObject) => ({ ...prevObject, ...voiceAssureResponse }));
    } catch (error) {
      console.error("Error in getting user:", error);
    }
  };

  React.useEffect(() => {
    getToken();
  }, []);

  React.useEffect(() => {
    if (token) {
      getUser();
    }
  }, [token]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleModalOpen = (productName: string) => {
    const product = allProducts.find((product) => product.name === productName);
    if (product) {
      if (myProducts.some((myProduct) => myProduct.name === productName) && product.myProductLink) {
        window.open(product.myProductLink, "_blank");
        return;
      } else {
        setIframeLink(product.iframeLink);
        setOpenModal(true);
        setLoading(true);
      }
    }
  };

  const handleModalClose = () => {
    setOpenModal(false);
    setLoading(false);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const classes = useStyles();

  return (
    <>
      <Stack mt={agentAssure ? 0 : "48px"}>
        <Button aria-describedby={id} onClick={handleClick} className={classes.otherProducts}>
          <ReactSVG
            src={otherProductsLogo}
            className={classes.otherProductsIcon}
            style={{
              marginLeft: agentAssure ? "12px" : 0,
            }}
          />
          <span
            className={classes.oneUITexts}
            style={{
              color: agentAssure ? "#243544" : "#ADB0B7",
              marginLeft: agentAssure ? "6px" : 0,
              fontSize: agentAssure ? "16px" : "",
            }}
          >
            Other Products
          </span>
        </Button>
        {!agentAssure && (
          <Link
            href="https://www.spearline.com/"
            target="_blank"
            sx={{
              textDecoration: "none",
            }}
          >
            <Button className={classes.poweredBy}>
              <span className={classes.oneUITexts}>Powered by</span>
              <ReactSVG src={agentAssure ? CyaraLogo : CyaraLogoW} className={classes.poweredByIcon} />
            </Button>
          </Link>
        )}
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorReference="anchorEl"
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "center",
            horizontal: "left",
          }}
        >
          <Stack width={"260px"} minHeight={"250px"}>
            {myProducts.length > 0 && (
              <Box
                paddingBottom={exploreProducts.length > 0 || myProducts.length > 2 ? 2 : 0}
                sx={{ borderBottom: exploreProducts.length > 0 ? "1px solid #2435441F" : "" }}
              >
                <Box className={classes.myProducts}>
                  <Typography fontWeight={700}>My Products</Typography>
                </Box>
                {myProducts.map((product) => {
                  const currentProduct =
                    (agentAssure && product.name === "agentAssure") || (!agentAssure && product.name === "testRTC");
                  return (
                    <Box
                      key={product.name}
                      className={classes.productItem}
                      style={{
                        pointerEvents: currentProduct ? "none" : "auto",
                      }}
                    >
                      <Button
                        disableRipple
                        className={classes.productButton}
                        onClick={() => handleModalOpen(product.name)}
                      >
                        <ReactSVG
                          src={product.logo}
                          style={{
                            maxHeight: "20px",
                          }}
                        />
                      </Button>
                      {currentProduct && <Check sx={{ color: "#0091FF" }} />}
                    </Box>
                  );
                })}
              </Box>
            )}
            {exploreProducts.length > 0 && (
              <Box pb={2}>
                <Box className={classes.exploreProducts}>
                  <Typography fontSize={"14px"} fontWeight={500}>
                    Explore more products
                  </Typography>
                </Box>
                {exploreProducts.map((product) => (
                  <Box key={product.name} className={classes.productItem}>
                    <Button
                      disableRipple
                      className={classes.productButton}
                      onClick={() => handleModalOpen(product.name)}
                    >
                      <ReactSVG
                        src={product.logo}
                        style={{
                          maxHeight: "20px",
                        }}
                      />
                    </Button>
                  </Box>
                ))}
              </Box>
            )}
          </Stack>
        </Popover>
      </Stack>
      <Dialog
        open={openModal}
        onClose={handleModalClose}
        fullScreen
        sx={{
          padding: 4,
        }}
        PaperProps={{
          style: { borderRadius: 4 },
        }}
      >
        <DialogContent
          sx={{
            padding: 0,
            height: "100%",
          }}
        >
          <Stack
            sx={{
              height: "100%",
            }}
          >
            <Stack
              pt={"38px"}
              pb={"32px"}
              px={"36px"}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "end",
              }}
            >
              <Button
                disableRipple
                sx={{
                  "&:hover": {
                    backgroundColor: "transparent",
                  },
                }}
                onClick={handleModalClose}
              >
                <Close
                  sx={{
                    color: "#000000",
                    opacity: 0.54,
                  }}
                />
              </Button>
            </Stack>
            <Box
              sx={{
                height: "100%",
              }}
            >
              {loading ? <Loader /> : null}
              <iframe src={iframeLink} width={"100%"} height={"100%"} title="Product" onLoad={iframeLoad}></iframe>
            </Box>
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Popup;

import * as React from "react";
import { Link } from "react-router-dom";
import { Field, Form } from "react-final-form";
import { Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";

import FormValidators from "@monortc/front/helpers/form-validators";
import { axiosInstance } from "@monortc/front/services/AxiosService";
import TextFieldControlSimple from "@monortc/front/components/form-elements/TextFieldControlSimple";
import ButtonSimple from "@monortc/front/components/ButtonSimple";
import ApiPath from "@/constants/ApiPath";
import { AuthRoutes } from "@/constants/routes/auth-routes";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      maxWidth: 368,
      width: "100%",
    },
    title: {
      margin: 0,

      fontSize: 24,
      fontWeight: 700,
      lineHeight: 1.25,
      letterSpacing: "0.15px",
    },
    subtitle: {
      margin: "8px 0 0",

      lineHeight: 1.75,
      letterSpacing: "0.15px",
    },
    form: {
      marginTop: 24,
    },
    loginButton: {
      marginTop: 32,
    },
    loginWithEmailButton: {
      marginTop: 24,
    },
    signUpText: {
      margin: "24px 0 0",

      fontSize: 14,
      letterSpacing: "0.15px",
    },
    signUpLink: {
      display: "inline-block",
      marginLeft: "4px",

      color: theme.palette.primary.main,
      textDecoration: "none",

      transition: "0.2s color ease",
      "&:hover": {
        color: theme.palette.primary.dark,
      },
    },
  })
);

const ForgotPassword = () => {
  const classes = useStyles();

  const [isLoading, setIsLoading] = React.useState(false);

  const onSubmit = async (values: any): Promise<any> => {
    setIsLoading(true);

    try {
      const domain = values.email.split("@")[1]?.split(".")[0];
      const result = await axiosInstance.get(ApiPath.auth.loginSso + domain);

      location.assign(process.env.REACT_APP_API_PATH + result.data.url);
    } catch (err) {
      return {
        email: err.response?.status === 404 ? "Domain not found" : "SSO not configured",
      };
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={classes.container}>
      <Typography variant="h1" className={classes.title}>
        SSO Login
      </Typography>

      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} className={classes.form}>
            <Field
              component={TextFieldControlSimple}
              type="text"
              name="email"
              label="Email"
              validate={FormValidators.composeValidators(FormValidators.required(), FormValidators.isEmail)}
            />

            <ButtonSimple type="submit" isLoading={isLoading} fullWidth className={classes.loginButton}>
              Login
            </ButtonSimple>
          </form>
        )}
      />

      <ButtonSimple to={AuthRoutes.SignIn} variant="outlined" fullWidth className={classes.loginWithEmailButton}>
        Login with email
      </ButtonSimple>

      <p className={classes.signUpText}>
        Don't have an account?{" "}
        <Link className={classes.signUpLink} to={AuthRoutes.SignUp}>
          Sign up here
        </Link>
      </p>
    </div>
  );
};

export default ForgotPassword;

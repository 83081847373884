export default {
  TestingRTCProbe: "TestingRTCProbe",
  QualityRTC: "QualityRTC",
  ProbeRTC: "ProbeRTC",
  WatchRTC: "WatchRTC",
  WatchRTCLive: "WatchRTCLive",
  WatchRTCExpectation: "WatchRTCExpectation",
  BillingData: "BillingData",
  AnalyticsEvents: "AnalyticsEvents",
  Probes: "Probes",
  QualityRTCInvite: "QualityRTCInvite",
  NetworkTestBenchmark: "NetworkTestBenchmark",
};

import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Form, Field } from "react-final-form";
import { Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";

import FormValidators from "@monortc/front/helpers/form-validators";
import { axiosInstance } from "@monortc/front/services/AxiosService";
import { SetNotification } from "@monortc/front/actions/notificationAction";
import TextFieldControlSimple from "@monortc/front/components/form-elements/TextFieldControlSimple";
import ButtonSimple from "@monortc/front/components/ButtonSimple";
import ApiPath from "@/constants/ApiPath";
import { AppRoutes } from "@/constants/routes/app-routes";

const FORM_FIELDS = {
  password: "password",
  repeatPassword: "repeatPassword",
} as const;

interface FormData {
  [FORM_FIELDS.password]: string;
  [FORM_FIELDS.repeatPassword]: string;
}

const useStyles = makeStyles(
  createStyles({
    container: {
      maxWidth: 368,
      width: "100%",
    },
    title: {
      margin: 0,

      fontSize: 24,
      fontWeight: 700,
      lineHeight: 1.25,
      letterSpacing: "0.25px",
    },
    subtitle: {
      margin: "8px 0 0",

      lineHeight: 1.75,
      letterSpacing: "0.15px",
    },
    form: {
      marginTop: 24,
    },
    nthTextField: {
      marginTop: 16,
    },
    submitButton: {
      margin: "32px auto 0",
    },
  })
);

const CreatePassword = () => {
  const classes = useStyles();

  const history = useHistory();

  const dispatch = useDispatch();
  const setNotification = (message: string) => dispatch(SetNotification(message));

  const user = useSelector((state: IStore<"assureUser">) => state.userAuth.user);

  useEffect(
    function ensurePasswordAbsence() {
      if (user) {
        const localUser = user as AssureUser & { hashedPassword?: string };

        if (localUser.hashedPassword) {
          history.push(AppRoutes.Main);
        }
      }
    },
    [user]
  );

  const onSubmit = async (values: FormData) => {
    const errors: Partial<FormData> = {};

    if (values.password !== values.repeatPassword) {
      errors.repeatPassword = "Passwords do not match";
    } else {
      try {
        const response = await axiosInstance.post(ApiPath.api.user.setPassword, {
          password: values.password,
        });

        if (response.data) {
          setNotification("Password has been set successfully, now you can log in.");

          window.location.replace(AppRoutes.Main);
        }
      } catch (err) {
        setNotification(err.response.data.message || "Unexpected error. Please contact support");
      }
    }

    return errors;
  };

  return (
    <div className={classes.container}>
      <Typography variant="h1" className={classes.title}>
        Create a new password
      </Typography>
      <p className={classes.subtitle}>Please enter a new password for your account.</p>

      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} className={classes.form}>
            <Field
              component={TextFieldControlSimple}
              type="password"
              name={FORM_FIELDS.password}
              label="Password"
              helperText="Minimum 8 characters"
              validate={FormValidators.composeValidators(FormValidators.required(), FormValidators.minValue(8))}
            />
            <Field
              component={TextFieldControlSimple}
              type="password"
              name={FORM_FIELDS.repeatPassword}
              label="Confirm Password"
              validate={FormValidators.composeValidators(FormValidators.required(), FormValidators.minValue(8))}
              className={classes.nthTextField}
            />

            <ButtonSimple type="submit" fullWidth className={classes.submitButton}>
              Submit
            </ButtonSimple>
          </form>
        )}
      />
    </div>
  );
};

export default CreatePassword;

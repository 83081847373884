export const AuthRoutes = {
  Pricing: "/pricing",
  SignIn: "/signin",
  SingleSignOn: "/sso",
  SignUp: "/signup",
  SignUpConfirm: "/signup-confirm",
  ConfirmAccount: "/confirm-account",
  ConfirmAccountId: "/confirm-account/:objectId",
  ForgotPassword: "/forgot-password",
  CreatePassword: "/create-password",
  ResetPassword: "/reset",
  ResetPasswordId: "/reset/:token",
} as const;

import * as ReactDOM from "react-dom";
import { Provider } from "react-redux";
import "typeface-roboto";

import store from "@monortc/front/store";
import SocketService from "@monortc/front/services/SocketService";
import App from "./App";

import "@monortc/front/styles/app.css";
import "@monortc/front/styles/Badge.css";

const _socketService = new SocketService();
_socketService.connect();

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root") as HTMLElement
);

import { useBoolean } from "usehooks-ts";
import cn from "classnames";
import { Divider, IconButton, Theme, Tooltip, alpha } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { ArrowBackIos as ArrowBackIosIcon } from "@mui/icons-material";

import CustomKey, { CustomKeyConfig } from "../CustomKey";

interface ProjectCustomKey {
  name: string;
  label?: string;
}

export interface CustomKeysProps {
  customKeysTitle?: string;
  customKeysTitleTooltip?: string;
  customKeys?: CustomKeyConfig[];
  tablePageLink?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      margin: "16px auto 0",
      padding: 16,
      border: `1px solid ${alpha(theme.palette.text.primary, 0.12)}`,
      borderRadius: 4,
    },
    header: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      flexWrap: "wrap",
    },
    title: {
      margin: "0 32px 0 0",

      fontSize: 14,
      lineHeight: "22px",
      fontWeight: 500,
      letterSpacing: "0.1px",
    },
    collapseButton: { margin: "-4px", transform: "rotate(90deg)", transition: "0.2s transform ease" },
    collapseButtonCollapsed: { transform: "rotate(-90deg)" },
    collapseIcon: { width: 14, height: "auto", transform: "translateX(20%)" },

    contentContainer: {
      marginRight: -33,
      display: "flex",
      flexWrap: "wrap",
      position: "relative",

      "&::after": {
        content: '""',
        display: "block",
        width: 1,
        height: "100%",
        position: "absolute",
        top: 4,
        bottom: 4,
        right: 16,
        backgroundColor: "#E4E6E8",
      },
    },
    customKeyWrapper: {
      minWidth: 0,
      flex: "1 0 233px",
      margin: "16px 0 0",
      display: "flex",
    },
    divider: { margin: "0 16px" },
  })
);

export const getCustomKeyConfigForTestingRtc = (customMetrics: Partial<Metrics> | undefined) => {
  const result: CustomKeyConfig[] = [];

  if (customMetrics) {
    const customMetricEntries = Object.entries(customMetrics);

    if (customMetricEntries.length) {
      for (const [key, customMetric] of customMetricEntries) {
        const value = customMetric?.varValue;
        const aggregationType = customMetric?.aggType;

        result.push({
          label: key,
          labelTooltip: `Aggregation type - ${aggregationType}`,
          values: [
            value && value !== 0 && isFinite(value)
              ? value.toLocaleString("en", {
                  maximumFractionDigits: 2,
                })
              : "None",
          ],
          keyName: key,
        });
      }
    }
  }

  return result;
};

export const getCustomKeyConfigForWatchRtc = (
  customKeys: { [key: string]: string | string[] | { id: string; label: string; endpoint: string }[] },
  projectCustomKeys: ProjectCustomKey[]
) => {
  const result: CustomKeyConfig[] = [];

  const customKeyEntries = Object.entries(customKeys);

  if (customKeyEntries.length && projectCustomKeys.length) {
    for (const [key, value] of customKeyEntries) {
      const projectCustomKey = projectCustomKeys.find((projectKey) => projectKey.name === key);

      if (projectCustomKey && value) {
        const values = typeof value === "string" ? value.split(",") : value;

        if (values.length) {
          result.push({ label: projectCustomKey.label ?? projectCustomKey.name, values, keyName: key });
        }
      }
    }
  }

  return result;
};

const CustomKeys = ({
  customKeysTitle = "Custom keys",
  customKeysTitleTooltip,
  customKeys,
  tablePageLink,
}: CustomKeysProps) => {
  const classes = useStyles();

  const { value: isCollapsed, toggle: toggleIsCollapsed } = useBoolean();

  let resultJsx: JSX.Element | null = null;

  if (customKeys && customKeys.length) {
    let contentJsx: JSX.Element[] | null = null;

    contentJsx = customKeys
      .filter((customKey) => customKey.values.length)
      .map(function getCustomKeyJsx(customKey) {
        return (
          <div className={classes.customKeyWrapper} key={customKey.keyName}>
            <CustomKey {...customKey} tablePageLink={tablePageLink} />
            <Divider orientation="vertical" flexItem className={classes.divider} />
          </div>
        );
      });

    resultJsx = (
      <div className={classes.container}>
        <header className={classes.header}>
          <Tooltip title={customKeysTitleTooltip ?? ""} arrow>
            <h3 className={classes.title}>{customKeysTitle}</h3>
          </Tooltip>

          <IconButton
            onClick={toggleIsCollapsed}
            className={cn(classes.collapseButton, { [classes.collapseButtonCollapsed]: isCollapsed })}
          >
            <ArrowBackIosIcon className={classes.collapseIcon} />
          </IconButton>
        </header>

        {!isCollapsed ? <div className={classes.contentContainer}>{contentJsx}</div> : null}
      </div>
    );
  }

  return resultJsx;
};

export default CustomKeys;

export const AppRoutes = {
  root: "/app",

  Forbidden: "/app/403",
  NotFound: "/app/404",
  WrongLink: "/app/400",
  ServerError: "/app/500",

  AssignToTest: "/app/test-run/assign", // not used

  Main: "/app/main",

  // TODO testingRTC
  Assets: "/app/testing/assets",
  NewAssetProperty: "/app/testing/assets/new",
  AssetPropertyId: "/app/testing/assets/:objectId",

  Testing: "/app/testing",

  TestingScripts: "/app/testing/scripts",
  TestProperty: "/app/testing/scripts",
  NewTestProperty: "/app/testing/scripts/new",
  TestPropertyId: "/app/testing/scripts/:objectId",

  TestRun: "/app/testing/history",
  TestRunDetails: "/app/testing/history/tests",
  TestRunDetailsId: "/app/testing/history/tests/:objectId",

  TestIteration: "/app/testing/history/agents",
  TestIterationId: "/app/testing/history/agents/:objectId",
  TestIterationTrace: "/app/testing/history/agents/:objectId/trace",

  TestLogs: "/app/testLogs",
  TestLogsId: "/app/testLogs/:objectId/:machine?/:manual?",

  DrillDownToTest: "/app/drilldowntotest",
  DrillDownToTestId: "/app/drilldowntotest/:testId/:testType",

  TestRunGroup: "/app/testing/history/group",
  TestRunGroupId: "/app/testing/history/group/:objectId/:group/:groupValue",

  PerformanceDashboard: "/app/testing/performance",

  // TODO upRTC
  Monitoring: "/app/monitoring",

  MonitorRun: "/app/monitoring/history",
  MonitorRunDetails: "/app/monitoring/history/tests",
  MonitorRunDetailsId: "/app/monitoring/history/tests/:objectId",

  MonitorAgentDetails: "/app/monitoring/history/agents",
  MonitorAgentDetailsId: "/app/monitoring/history/agents/:objectId",
  MonitorAgentDetailsTrace: "/app/monitoring/history/agents/:objectId/trace",

  MonitorList: "/app/monitoring/monitors",
  MonitorProperty: "/app/monitoring/monitors",
  NewMonitorProperty: "/app/monitoring/monitors/new",
  MonitorPropertyId: "/app/monitoring/monitors/:objectId",

  // TODO qualityRTC
  QualityRTCRoot: "/app/qualityrtc",
  QualityRTC: "/app/qualityrtc/history",
  QualityRTCInvites: "/app/qualityrtc/invites",
  QualityRTCInviteNew: "/app/qualityrtc/invites/new",
  QualityRTCInviteId: "/app/qualityrtc/invites/:objectId",

  NetworkTestCharts: "/app/network-test-charts",

  // TODO probeRTC
  OnPremiseProbe: "/app/probertc",
  OnPremiseProbeHistory: "/app/probertc/history",
  OnPremiseProbeHistoryId: "/app/probertc/history/:probeId",

  NewOnPremiseProbeProperty: "/app/probertc/new",

  OnPremiseProbeProperty: "/app/probertc/edit",
  OnPremiseProbePropertyId: "/app/probertc/edit/:objectId",

  OnPremiseProbesCompare: "/app/probertc/compare",
  OnPremiseProbesCompareId: "/app/probertc/compare/:firstId/:secondId",

  OnPremiseProbeCharts: "/app/probertc/view",
  OnPremiseProbeChartsId: "/app/probertc/view/:objectId",
  OnPremiseProbeResults: "/app/probertc/view/results",
  OnPremiseProbeResultsId: "/app/probertc/view/results/:probeId/:objectId",

  // TODO watchRTC
  WatchRTC: "/app/watchrtc",

  WatchRTCHistory: "/app/watchrtc/history",

  WatchRTCRoom: "/app/watchrtc/history/room",
  WatchRTCRoomId: "/app/watchrtc/history/room/:objectId",

  WatchRTCPeer: "/app/watchrtc/history/peer",
  WatchRTCPeerId: "/app/watchrtc/history/peer/:objectId/:isLive?",
  WatchRTCPeerTrace: "/app/watchrtc/history/peer/:objectId/trace",

  WatchRTCLive: "/app/watchrtc/live",
  WatchRTCLiveId: "/app/watchrtc/live/:objectId",
  WatchRTCLivePeerTrace: "/app/watchrtc/live/:objectId/trace",

  WatchRTCHighlights: "/app/watchrtc/highlights",
  WatchRTCTrends: "/app/watchrtc/trends",

  WatchRTCNotifications: "/app/watchrtc/notifications",

  AnalyzeDump: "/app/analyze",
  AnalyzeDumpResult: "/app/analyze/result",
  AnalyzeDumpResultId: "/app/analyze/result/:objectId",

  // TODO Logs
  Logs: "/app/logs",

  WebrtcInternals: "/app/logs/webrtc-internals",
  WebrtcInternalsId: "/app/logs/webrtc-internals/:testIterationId/:fileName/:testVariant?",

  WebrtcInternalsAnalyzeRtc: "/app/logs/webrtc-internals-analyze-rtc",
  WebrtcInternalsAnalyzeRtcId: "/app/logs/webrtc-internals-analyze-rtc/:testIterationId/:fileName",

  WebrtcInternalsFreeAnalyzeRtc: "/report/free/webrtc-internals-analyze-rtc",
  WebrtcInternalsFreeAnalyzeRtcId: "/report/free/webrtc-internals-analyze-rtc/:testIterationId/:fileName",

  WebrtcInternalsWatchRtc: "/app/logs/webrtc-internals-watch-rtc",
  WebrtcInternalsWatchRtcId: "/app/logs/webrtc-internals-watch-rtc/:testIterationId/:fileName/:dumpUrl",

  WebrtcInternalsWatchRtcLive: "/app/logs/webrtc-internals-live-watch-rtc",
  WebrtcInternalsWatchRtcLiveId: "/app/logs/webrtc-internals-live-watch-rtc/:testIterationId",

  NightWatch: "/app/logs/nightwatch",
  NightWatchId: "/app/logs/nightwatch/:testRunId/:testIterationId/:iterationMachine/:runIndex",

  TestScript: "/app/logs/test-script",
  TestScriptId: "/app/logs/test-script/:testRunId/:testIterationId/:iterationMachine/:runIndex",

  Browser: "/app/logs/browser",
  BrowserId: "/app/logs/browser/:testRunId/:testIterationId/:iterationMachine/:inSessionIdx",

  Performance: "/app/logs/performance",
  PerformanceId: "/app/logs/performance/:testRunId?/:testIterationId?/:iterationMachine?/:runIndex?",

  // TODO Account settings
  Settings: "/app/settings",
  MyProfile: "/app/profile", // not available
  AccountBilling: "/app/billing", // not available

  Support: "/app/support-ticket",

  // TODO Admin routes
  UserAccounts: "/app/admin/users",

  UserDetails: "/app/admin/users",
  UserDetailsId: "/app/admin/users/:objectId",

  Projects: "/app/admin/projects",
  ProjectProperties: "/app/admin/projects",
  ProjectPropertiesId: "/app/admin/projects/:objectId",

  Probes: "/app/admin/agents",
  UsageGraphs: "/app/admin/usage-graph",

  NetworkTestBenchmark: "/app/admin/network-test-benchmark",
  NetworkTestBenchmarkId: "/app/admin/network-test-benchmark/:objectId",

  AdminUtils: "/app/admin/admin-utils",

  UserSettings: "/app/user-settings",

  BillingData: "/app/admin/billing-data",
  BillingDataId: "/app/admin/billing-data/:objectId",

  AnalyticsEvents: "/app/admin/analytics-events",
};

export const ExternalLinks = {
  RenewalRequest: "https://testrtc.com/lp-renewal-request-form/",
} as const;

import * as React from "react";
import { Theme } from "@mui/material/styles";
import withStyles from "@mui/styles/withStyles";
import createStyles from "@mui/styles/createStyles";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Videocam from "@mui/icons-material/Videocam";
import Headset from "@mui/icons-material/Headset";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ArrowBack from "@mui/icons-material/ArrowBack";
import Typography from "@mui/material/Typography";
import ArrowForward from "@mui/icons-material/ArrowForward";
import * as moment from "moment";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import IconButton from "@mui/material/IconButton";
import StatusIcon from "../../../../components/Test/StatusIcon";
import { checkNan, formatDuration } from "../../../../helpers/testDetails";
import { InfoRow } from "./InfoRow";

interface ExpandChannelDetailsProps {
  // tslint:disable-next-line:no-any
  classes: any;
  // tslint:disable-next-line:no-any
  channel: any;
  advancedAnalyticsLink?: any;
  id?: number;
  channelId?: number;
  testRtc: boolean;
  isFramed?: boolean;
}

interface ExpandDetailsState {
  expanded: boolean;
}

type Props = ExpandChannelDetailsProps;

class ExpandChannelDetails extends React.Component<Props, ExpandDetailsState> {
  constructor(props: Props) {
    super(props);

    this.state = {
      expanded: false,
    };

    this.onExpand = this.onExpand.bind(this);
  }

  shouldComponentUpdate(_nextProps: Props, nextState: ExpandDetailsState) {
    return nextState.expanded !== this.state.expanded;
  }

  onExpand() {
    this.setState({
      expanded: !this.state.expanded,
    });
  }

  render() {
    const { channel, classes, advancedAnalyticsLink, id, channelId, testRtc } = this.props;
    const isVideo = channel.channelType === "video";
    const isAudio = channel.channelType === "audio";
    const videoResolution = !!channel.data.videoResolution;
    const channelRef = channel.data.channelDisplayName.split("-");

    const hasVideoData =
      !!channel.data.videoFramerate ||
      (!!channel.data.fps && channel.data.fps.hasOwnProperty("average")) ||
      videoResolution;
    const roundTrip = !!channel.data.rtt && !!channel.data.rtt.count;

    const mosScore = channel?.data?.mos;

    const getStartTime = (startTime: any) => {
      return startTime ? moment(startTime).format("HH:mm:ss") : "";
    };

    const getResolutionValue = (resolution: any, mostFrequent: boolean): number => {
      // keep compatibility with old results that use a string representation (eg: frameWidth=640, frameHeight=800)
      if (typeof resolution === "number") {
        return resolution;
      }

      return mostFrequent ? resolution.mostFrequent : resolution.max;
    };

    const hasDetailedResolutionValues = (resolution: any): boolean => {
      return typeof resolution !== "number";
    };

    const getDuration = (samples: number, dataFrequency: number, duration?: number) => {
      if (duration) {
        return formatDuration(duration * 1000, "DHMS");
      }
      return samples ? formatDuration(samples * 1000 * (dataFrequency || 1), "DHMS") : "0";
    };

    const channelName = testRtc ? "testrtc" : "watchrtc";
    const channelDirection = channel.isOutgoing ? "out" : "in";

    const channelID = (name: string) => {
      const output = `${channel.channelType}-${channelDirection}-channel-${channelId}-${name}`;
      return output;
    };

    return (
      <Accordion
        style={{
          borderLeft: `7px solid ${channel.color}`,
        }}
        className={classes.expansionPanel}
        defaultExpanded={false}
        expanded={this.state.expanded}
        onChange={this.onExpand}
        id={`${channelName}-channel-${id}`}
        // CollapseProps={{
        //   classes: {
        //     entered: classes.expandPanelDetails,
        //   },
        // }}
      >
        <AccordionSummary className={classes.expandHead} expandIcon={<ExpandMoreIcon />}>
          <Grid container={true} spacing={2}>
            <Grid item={true} xs={12} md={3} className={classes.expandHeadFist}>
              {isAudio && <Headset className={classes.headsetIcon} />}
              {isVideo && <Videocam className={classes.cameraIcon} />}
              {channel.isOutgoing ? (
                <ArrowForward className={classes.arrowForwardIcon} />
              ) : (
                <ArrowBack className={classes.arrowBackIcon} />
              )}
              <Typography variant="body2">{channel.data.audioCoddec}</Typography>
            </Grid>
            <Grid item={true} md={2} xs={6} className={classes.expandHeadSecond}>
              <Typography variant="body2">
                {checkNan(
                  channel.data.bytes.average?.toLocaleString("en", {
                    maximumFractionDigits: 0,
                  })
                )}
              </Typography>
            </Grid>
            <Grid item={true} md={1} xs={6} className={classes.expandHeadFist}>
              <Typography variant="body2">Kbps</Typography>
            </Grid>
            <Grid item={true} xs={12} md={6} className={classes.expandHeadSecond}>
              <div className={classes.peerLink}>
                {channel.data.channelDisplayName && (
                  <IconButton
                    target={!this.props.isFramed ? "_blank" : undefined}
                    className={classes.openInNew}
                    href={`${advancedAnalyticsLink}#${channelRef[0]}-${channelRef[2]}-${channelRef[3]}`}
                    size="small"
                  >
                    <OpenInNewIcon fontSize={"small"} />
                  </IconButton>
                )}
                <Tooltip title={channel.data.mappedStreamName ? channel.data.channelDisplayName : ""} placement="top">
                  <Typography variant="body2" id={channelID("channelName")}>
                    {channel.data.channelDisplayName}{" "}
                    {channel.data.mappedStreamName ? `[${channel.data.mappedStreamName}]` : ""}
                  </Typography>
                </Tooltip>
                {channel.error && (
                  <Typography
                    style={{
                      marginLeft: 5,
                    }}
                  >
                    {`${channel.error}`}
                  </Typography>
                )}
                <StatusIcon
                  status={channel.status}
                  style={{
                    marginRight: 8,
                    marginLeft: 8,
                  }}
                />
              </div>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container={true} spacing={2}>
            <Grid item={true} container={true} direction="row">
              <Grid item={true} container={true} direction="column" style={{ width: "auto", marginRight: 90 }}>
                <InfoRow
                  classes={classes}
                  items={["Start time", getStartTime(channel.data.startTime)]}
                  id={["", channelID("startTime")]}
                />
                <InfoRow
                  classes={classes}
                  items={["Duration", getDuration(channel.data.samples, channel.dataFrequency, channel.data.duration)]}
                  id={["", channelID("duration")]}
                />
                {"mos" in channel.data && channel.data.mos !== null && (
                  // <InfoRow classes={classes} items={["MOS score", channel.data.mos?.average?.toFixed(2)]} />
                  <InfoRow
                    classes={classes}
                    items={[
                      "MOS score",
                      typeof mosScore === "number" ? mosScore?.toFixed(2) : mosScore?.average?.toFixed(2),
                    ]}
                    id={["", channelID("mos")]}
                  />
                )}
                {"score" in channel.data && (
                  <InfoRow
                    classes={classes}
                    items={["Score", channel.data.score?.toFixed(2)]}
                    id={["", channelID("score")]}
                  />
                )}
                {/*<InfoRow classes={classes} items={["Implementation", "libvpx"]} /> // TODO add for #5132*/}
              </Grid>
              {!!channel.data.connection && (
                <Grid container={true} item={true} direction="column" style={{ width: "auto" }}>
                  <InfoRow classes={classes} items={["Connection", channel.data.connection.transport]} />
                  <InfoRow classes={classes} items={["Local IP", channel.data.connection.localAddress]} />
                  <InfoRow classes={classes} items={["Remote IP", channel.data.connection.remoteAddress]} />
                </Grid>
              )}
            </Grid>
            <Grid item={true}>
              <InfoRow classes={classes} isHeader items={["", "Total", "Average", "Variance"]} />
              <InfoRow
                classes={classes}
                id={["", channelID("totalData"), channelID("avgData"), channelID("varianceData")]}
                items={[
                  "Data (Kbits)",
                  ((channel.data.totalBytes * 8) / 1000)?.toLocaleString("en", {
                    maximumFractionDigits: 0,
                  }),
                  channel.data.bytes.average?.toLocaleString("en", {
                    maximumFractionDigits: 0,
                  }),
                  channel.data.bytes.variance?.toLocaleString("en", {
                    maximumFractionDigits: 3,
                  }),
                ]}
              />
              <InfoRow
                classes={classes}
                id={["", channelID("totalPackets"), channelID("avgPackets"), channelID("variancePackets")]}
                items={[
                  "Packets",
                  channel.data.totalPackets &&
                    channel.data.totalPackets?.toLocaleString("en", {
                      maximumFractionDigits: 0,
                    }),
                  channel.data.packets.average?.toLocaleString("en", {
                    maximumFractionDigits: 0,
                  }),
                  channel.data.packets.variance?.toLocaleString("en", {
                    maximumFractionDigits: 3,
                  }),
                ]}
              />
              {!!channel.data.avgPacketSize && channel.data.avgPacketSize.count > 0 && (
                <InfoRow
                  classes={classes}
                  items={[
                    "Average packet size (bytes)",
                    channel.data.avgPacketSize?.toLocaleString("en", {
                      maximumFractionDigits: 0,
                    }),
                  ]}
                />
              )}
              <InfoRow
                classes={classes}
                id={["", "", channelID("jitter"), channelID("varianceJitter")]}
                items={[
                  "Jitter (msec)",
                  "",
                  channel.data.jitter.average?.toLocaleString("en", {
                    maximumFractionDigits: 0,
                  }),
                  channel.data.jitter.variance?.toLocaleString("en", {
                    maximumFractionDigits: 3,
                  }),
                ]}
              />
              {roundTrip && (
                <InfoRow
                  classes={classes}
                  id={["", "", channelID("rtt"), channelID("varianceRTT")]}
                  items={[
                    "Round trip (msec)",
                    "",
                    channel.data.rtt.average
                      ? channel.data.rtt.average.toLocaleString("en", {
                          maximumFractionDigits: 0,
                        })
                      : "No data",
                    channel.data.rtt.variance
                      ? channel.data.rtt.variance.toLocaleString("en", {
                          maximumFractionDigits: 3,
                        })
                      : "No data",
                  ]}
                />
              )}
              {channel.channelType !== "data" && channel.packetLossPCT > 0.09 && (
                <InfoRow
                  classes={classes}
                  id={["", channelID("packetsLoss")]}
                  items={[
                    "Packets loss (%)",
                    channel.packetLossPCT?.toLocaleString("en", {
                      maximumFractionDigits: 1,
                    }),
                  ]}
                />
              )}
              {channel.channelType !== "data" && channel.data.packetLoss > 0 && (
                <InfoRow
                  classes={classes}
                  id={["", channelID("packetsLost")]}
                  items={[
                    "Packets lost (#)",
                    channel.data.packetLoss.toLocaleString("en", {
                      maximumFractionDigits: 1,
                    }),
                  ]}
                />
              )}
            </Grid>
            {isVideo && (
              <>
                <Grid item={true} container={true}>
                  {hasVideoData && (
                    <InfoRow
                      classes={classes}
                      id={["", channelID("frameRate")]}
                      items={[
                        "Frame rate",
                        channel.data.fps.average?.toLocaleString("en", {
                          maximumFractionDigits: 0,
                        }),
                      ]}
                    />
                  )}
                </Grid>
                {hasDetailedResolutionValues(channel.data.frameWidth) && (
                  <Grid item>
                    <InfoRow
                      classes={classes}
                      id={["", channelID("videoResolution_maxReached")]}
                      items={[
                        "Video Resolution (max reached)",
                        channel.data.frameWidth
                          ? getResolutionValue(channel.data.frameWidth, false) +
                            "x" +
                            getResolutionValue(channel.data.frameHeight, false)
                          : "No data",
                      ]}
                    />
                    <InfoRow
                      classes={classes}
                      id={["", channelID("videoResolution_mostFrequent")]}
                      items={[
                        "Video Resolution (most frequent)",
                        channel.data.frameWidth.max && channel.data.frameWidth.mostFrequent
                          ? getResolutionValue(channel.data.frameWidth, true) +
                            "x" +
                            getResolutionValue(channel.data.frameHeight, true)
                          : "No data",
                      ]}
                    />
                  </Grid>
                )}
                {!hasDetailedResolutionValues(channel.data.frameWidth) && (
                  <Grid item>
                    <InfoRow
                      classes={classes}
                      items={[
                        "Video Resolution",
                        channel.data.frameWidth ? channel.data.frameWidth + "x" + channel.data.frameHeight : "No data",
                      ]}
                    />
                  </Grid>
                )}
              </>
            )}
          </Grid>
        </AccordionDetails>
      </Accordion>
    );
  }
}

const IconFontSize = {
  width: "1.2rem",
  height: "1.2rem",
  marginRight: "0.4rem",
};

const styles = (_theme: Theme) =>
  createStyles({
    panelRoot: {
      width: "calc(100% + 1.5rem)",
    },
    panelExpanded: {
      margin: 0,
    },
    openInNew: {
      opacity: 0,
    },
    panelTitle: {
      background: "#dde4eb",
      height: "3rem",
      color: "#444444",
      padding: "0.7em 1.1em 0.7em 1.1em",
      fontSize: "1.1em",
      fontWeight: "bold",
      textTransform: "capitalize",
    },
    panelBody: {
      padding: "1.5rem 1.3rem",
    },
    headerText: {
      color: "",
    },
    resultTableHead: {
      marginBottom: 5,
    },
    expandIcon: {
      fontSize: "2rem",
    },
    expansionPanel: {
      width: "100%",
      display: "block",
      "&:hover $openInNew": {
        opacity: 1,
      },
    },
    arrowBackIcon: {
      ...IconFontSize,
      color: "#3577c1",
    },
    arrowForwardIcon: {
      ...IconFontSize,
      color: "#a22a21",
    },
    cameraIcon: {
      ...IconFontSize,
      color: "#5F5F5F",
    },
    headsetIcon: {
      ...IconFontSize,
      color: "#5F5F5F",
    },
    expandPanelDetails: {
      overflow: "visible",
    },
    expandHeadFist: {
      display: "inline-flex",
      alignItems: "center",
    },
    expandHeadSecond: {
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "flex-end",
    },
    expandDetailHead: {},
    connectionString: {
      fontWeight: 600,
      display: "flex",
      alignItems: "center",
    },
    connectionStringIcon: {
      margin: "0 5px",
    },
    rowItem: {
      width: 160,
      padding: 2,
      textAlign: "right",
      marginRight: 10,
    },
    peerLink: {
      display: "flex",
      alignItems: "center",
    },
    rowItemLabel: {
      width: 120,
      padding: 2,
    },
  });

const decorate = withStyles(styles);

export default decorate(ExpandChannelDetails);

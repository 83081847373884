import * as React from "react";
import { WithStyles } from "@mui/styles";
import withStyles from "@mui/styles/withStyles";
import createStyles from "@mui/styles/createStyles";

type StyledComponent = WithStyles<"root">;

type AppRootProps = {
  children: React.ReactNode;
};

const View = (props: AppRootProps & StyledComponent) => <div className={props.classes.root}>{props.children}</div>;

const styles = () =>
  createStyles({
    root: {
      flexGrow: 1,
      zIndex: 1,
      overflow: "hidden",
      position: "relative",
      display: "flex",
      height: "100vh",
      width: "100%",
    },
  });

export default withStyles(styles)(View);

import { createStyles, makeStyles } from "@mui/styles";

import Widget, { WidgetConfig } from "./components/Widget";

interface RibbonStatsProps {
  data: WidgetConfig[];
}

const useStyles = makeStyles(
  createStyles({
    container: {
      width: "100%",
      border: "1px solid #DEDEDE",
      borderRadius: "4px",
      display: "flex",
      justifyContent: "flex-start",
      flexWrap: "wrap",
      overflow: "hidden",
    },
  })
);

const RibbonStats = ({ data }: RibbonStatsProps) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      {data.map((widgetData) => (
        <Widget {...widgetData} key={widgetData.title} />
      ))}
    </div>
  );
};

export default RibbonStats;

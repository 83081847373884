import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { Theme, Typography } from "@mui/material";
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import createStyles from '@mui/styles/createStyles';
import Button from "@mui/material/Button";
import Email from "@mui/icons-material/EmailOutlined";
import { Link } from "react-router-dom";
import { AppRoutes } from "../../../constants/routes/app-routes";

type StyledComponent = WithStyles<"root" | "mailIcon" | "text" | "link">;

class Support extends React.Component<RouteComponentProps<{}> & StyledComponent> {
  public render(): JSX.Element {
    return (
      <Link to={AppRoutes.Support} className={this.props.classes.link}>
        <Button color="primary" className={this.props.classes.root}>
          <Email className={this.props.classes.mailIcon} />
          <Typography variant="body2" color="primary" className={this.props.classes.text}>
            {"Support"}
          </Typography>
        </Button>
      </Link>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    link: {
      textDecoration: "none",
    },
    root: {
      "@media(max-width: 700px)": {
        minWidth: 40,
        padding: 0,
      },
    },
    mailIcon: {
      [theme.breakpoints.up("xs")]: {
        marginRight: 5,
      },
    },
    text: {
      fontWeight: 500,
      letterSpacing: "0.02857em",

      "@media(max-width: 700px)": {
        display: "none",
      },
    },
  });

const decorate = withStyles(styles);

export default withRouter(decorate(Support));

import * as React from "react";
import { CircularProgress, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    spinnerContainer: {
      width: "100vw",
      height: "100vh",
      top: 0,
      left: 0,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      zIndex: 999999,
      background: "rgba(0,0,0,0.3)",
      overflow: "hidden",
      position: "fixed",
    },
  })
);

const Loader = () => {
  const classes = useStyles();

  React.useEffect(() => {
    document.getElementsByTagName("body")[0].classList.add("loader-active");
    return () => {
      document.getElementsByTagName("body")[0].classList.remove("loader-active");
    };
  }, []);

  return (
    <div className={classes.spinnerContainer}>
      <CircularProgress />
    </div>
  );
};

export default Loader;

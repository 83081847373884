import { CircularProgress } from "@mui/material";
import { Route, Switch, Redirect, BrowserRouter } from "react-router-dom";

import AuthProvider from "@monortc/front/containers/Auth/AuthProvider";
import AppLayout from "./containers/Layout";
import LoginLayout from "./containers/Auth/LoginLayout";
import { AppRoutes } from "./constants/routes/app-routes";
import AppController from "./containers/AppController";

const RouterContainer = () => {
  return (
    <BrowserRouter>
      <AuthProvider loaderIcon={<CircularProgress />} authQueryParams={{ assureAuth: true }}>
        <Switch>
          <Route
            path={AppRoutes.root}
            render={(props) => (
              <AppController>
                <AppLayout {...props} />
              </AppController>
            )}
          />
          <Route path="/" component={LoginLayout} />
          <Redirect from="*" to="/" />
        </Switch>
      </AuthProvider>
    </BrowserRouter>
  );
};

export default RouterContainer;

import * as React from "react";
import Select from "react-select";
import NoSsr from "@mui/material/NoSsr";
import { Theme, TextField, Typography, MenuItem, Paper } from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import createStyles from '@mui/styles/createStyles';
import { BaseTextFieldProps } from "@mui/material/TextField";

interface OptionType {
  label: string;
  value: string;
}

type UsersAutocompleteProps = {
  classes: any;
  suggestions: Array<OptionType>;
  onInputChange?: (e: string) => void;
  onChange?: (value: OptionType) => void;
};

type InputComponentProps = Pick<BaseTextFieldProps, "inputRef"> & React.HTMLAttributes<HTMLDivElement>;

const inputComponent = ({ inputRef, ...props }: InputComponentProps) => {
  return <div ref={inputRef} {...props} />;
};

const Control = (props: any) => {
  const {
    children,
    innerProps,
    innerRef,
    selectProps: { classes, TextFieldProps },
  } = props;

  return (
    <TextField
    className={ classes.root }
      fullWidth={true}
      InputProps={{
        inputComponent,
        inputProps: {
          className: classes.input,
          ref: innerRef,
          children,
          ...innerProps,
        },
      }}
      {...TextFieldProps}
      variant="standard" />
  );
};

const Option = (props: any) => {
  return (
    <MenuItem
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
        display:'block',
        paddingTop: 6,
        paddingBottom: 6,
        paddingLeft: 12,
        paddingRight: 12,
      }}
      {...props.innerProps}
    >
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div>
          <strong>{props.data.email}</strong>
        </div>
        <div>company: {props.data.company}</div>
        <div>role: {props.data.role}</div>
      </div>
    </MenuItem>
  );
};

const Placeholder = (props: any) => {
  const { selectProps, innerProps = {}, children } = props;
  return (
    <Typography color="textSecondary" className={selectProps.classes.placeholder} {...innerProps}>
      {children}
    </Typography>
  );
};

const SingleValue = (props: any) => {
  return (
    <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
      {props.children}
    </Typography>
  );
};

const ValueContainer = (props: any) => {
  return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
};

const Menu = (props: any) => {
  return (
    <Paper square={true} className={props.selectProps.classes.paper} {...props.innerProps}>
      {props.children}
    </Paper>
  );
};

const NoOptionsMessage = (props: any) => {
  return (
    <Typography color="textSecondary" className={props.selectProps.classes.noOptionsMessage} {...props.innerProps}>
      {props.children}
    </Typography>
  );
};

const components = {
  Control,
  Menu,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
};

const UsersAutocomplete = ({ classes, suggestions, onInputChange, onChange }: UsersAutocompleteProps) => {
  const [single, setSingle] = React.useState<any>(null);
  const [input, setInput] = React.useState<string>("");
  const handleChangeSingle = (value: any) => {
    if (onChange) {
      onChange(value);
    }
    setSingle(null);
  };

  const selectStyles = {
    input: (base: React.CSSProperties) => ({
      ...base,
      "& input": {
        font: "inherit",
      },
    }),
  };

  return (
    <div className={classes.root}>
      <NoSsr>
        <Select
          classes={classes}
          styles={selectStyles}
          inputId="user-list"
          TextFieldProps={{
            InputLabelProps: {
              htmlFor: "user-list",
              shrink: true,
            },
          }}
          placeholder="Search for a user"
          options={suggestions}
          components={components}
          value={single}
          onChange={handleChangeSingle}
          onInputChange={(e: any) => {
            // event fires event on touch event
            // so we restrict useless call
            if (!e && !input) {
              return;
            }
            if (onInputChange) {
              onInputChange(e);
              setInput(e);
            }
          }}
        />
      </NoSsr>
    </div>
  );
};

const decorate = withStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      '& .MuiInputBase-input': {
        display: 'flex'
      },
      '& .MuiMenuItem-root.Mui-selected': {
        backgroundColor: 'rgba(0, 0, 0, 0.08)'
      }
    },
    input: {
      display: "flex",
      padding: 0,
      height: "auto",
    },
    valueContainer: {
      display: "flex",
      flexWrap: "wrap",
      flex: 1,
      alignItems: "center",
      overflow: "hidden",
    },
    chip: {
      margin: theme.spacing(0.5),
    },
    noOptionsMessage: {
      padding: theme.spacing(2),
    },
    singleValue: {
      fontSize: 16,
    },
    placeholder: {
      position: "absolute",
      left: 2,
      bottom: 6,
      fontSize: 16,
    },
    paper: {
      position: "absolute",
      zIndex: 1,
      marginTop: theme.spacing(1),
      left: 0,
      right: 0,
    },
    divider: {
      height: theme.spacing(2),
    },
  })
);

export default decorate(UsersAutocomplete);

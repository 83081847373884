/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-empty-interface */
import * as React from "react";
import { AxiosResponse } from "axios";
import { saveAs } from "file-saver";
import { ColDef, ColumnApi, GridApi } from "ag-grid-enterprise";

import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import SaveNewIcon from "@mui/icons-material/AddBox";
import ExportIcon from "@mui/icons-material/GetApp";
import ImportIcon from "@mui/icons-material/Publish";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Button, Divider, Menu, MenuItem, Tooltip, Typography } from "@mui/material";

import ApiPath from "src/constants/ApiPath";
import { axiosInstance } from "src/services/AxiosService";
import { ProjectsContext } from "src/containers/AppController";

import SelectControl from "./SelectControl";
import usePresetsContext from "./usePresetsContext";
import DeleteConfirmation from "./DeleteConfirmation";
import SaveNewDialog from "./SaveNewDialog";
import usePresetConfig from "./usePresetConfig";
import GlobalControl from "./GlobalControl";
import { reportError } from "src/services/ErrorService";
import { ExportFormat } from "../types";
import ExportDialog from "./ExportDialog";
import useExportConfig from "src/hooks/useExportConfig";

export type Preset = {
  colState: any;
  groupState: any;
  sortState: any;
  filterState: any;
};

export interface IPresets {
  viewName: string;
  disablePresets?: boolean;
  exportProduct?: "testingRTC" | "upRTC" | "watchRTC" | "qualityRTC" | "probeRTC" | "analyzeRTC";
  customExportRowLimit?: number;
  customExportColumnNames?: string | string[];
  isJsonExportAvailable?: boolean;
  isFramed?: boolean;
}

export interface IPreset {
  _id?: string;
  name: string;
  global: boolean;
  gridName: string;
  preset: string | Preset;
}

export interface IState {
  userRole?: string;
  setNotification?(message: string): void;
}

interface IPresetsProps extends IPresets, IState {
  gridApi: GridApi | undefined;
  columnApi: ColumnApi | undefined;
  columnDefs: ColDef[];
  rowModelType?: "serverSide" | "clientSide";
  doExport?: (format: ExportFormat) => Promise<boolean>;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      alignItems: "flex-end",
      marginRight: theme.spacing(1),
    },
    buttonsContainer: {
      display: "flex",
      alignItems: "flex-end",
    },
  })
);

export const defaultExportRetentionPeriodDays = 30;

export const SYSTEM_EXPORT_RETENTION_PERIOD_DAYS = {
  testingRTC: 30,
  upRTC: 30,
  watchRTC: 30,
  qualityRTC: 30,
  probeRTC: 30,
  analyzeRTC: 30,
} as const;

export const PROJECT_EXPORT_RETENTION_PERIOD_KEYS = {
  testingRTC: "testHistoryDaysBack",
  upRTC: "testHistoryDaysBack",
  watchRTC: "watchrtc_retentionPeriod",
  qualityRTC: "qualityrtc_retentionPeriod",
  probeRTC: "qualityrtc_retentionPeriod",
  analyzeRTC: "testHistoryDaysBack",
};

export const defaultExportRowLimit = 1000;

export const CUSTOM_EXPORT_COLUMN_NAMES = {
  WatchRTCRoomHistory: "ID,comment,Time,Duration,Room,Peers,Warnings,Errors,Score,Bucket,searchPeer",
  OnPremiseProbesHistory:
    "ID,Status,Date,Email,Reason,Video Quality Bitrate In,Video Quality Bitrate Out,Video Quality Loss In,Video Quality Loss Out,Region,URL,Location,Test Time,IP,Concurrent Sessions,Throughput Min,Throughput Avg,Call Quality MOS,Call Quality Error,Call Quality Packet Loss,Call Quality Jitter,Call Quality RTT,TURN Connectivity UDP,TURN Connectivity TCP,Bandwidth Speed Uplink,Bandwidth Speed Downlink,Bandwidth Speed Jitter,PING",
  TestSessionsGridControl:
    "Probe,Probe URL,Machine,Status,Notifications,IP,OS,Browser,Location,Network Profile,Firewall Profile,Media,Session #,Session Size,Session Index,Test Start Time,Test Duration,Connection Start Time,Connection Duration,Call Setup Time,Score,MOS,CPU,Memory,Bitrate Audio In,Bitrate Audio Out,Bitrate Video In,Bitrate Video Out,Packet Loss Audio In,Packet Loss Audio Out,Packet Loss Video In,Packet Loss Video Out,Jitter Audio In,Jitter Audio Out,Jitter Video In,Jitter Video Out,RTT Audio Out,RTT Video Out",
  TestRunHistory:
    "Test Name,Monitor Name,Machines,Time,URL,Test Status,Failure Reason,Session Size,Number of Probes Success,Number of Probes Warning,Number of Probes Failure,Test Duration,Connection Duration,Call Setup Time,Score,MOS,CPU,Memory,Bitrate Audio In,Bitrate Audio Out,Bitrate Video In,Bitrate Video Out,Packet Loss Audio In,Packet Loss Audio Out,Packet Loss Video In,Packet Loss Video Out,Jitter Audio In,Jitter Audio Out,Jitter Video In,Jitter Video Out,RTT Audio Out,RTT Video Out",
  WatchRTCRoomPeers:
    "Room Name,Peer Name,Probe URL,Room IDX,Browser Name,Browser Version,OS,Location,Remote Location,Notifications,Test Start Time,Connection Start Time,Connection Duration,Call Setup Time,Score,MOS,CPU,Memory,Bitrate Audio In,Bitrate Audio Out,Bitrate Video In,Bitrate Video Out,Packet Loss Audio In,Packet Loss Audio Out,Packet Loss Video In,Packet Loss Video Out,Jitter Audio In,Jitter Audio Out,Jitter Video In,Jitter Video Out,RTT Audio Out,RTT Video Out",
  MonitorRunHistory:
    "Test Name,Monitor Name,Machines,Time,URL,Test Status,Failure Reason,Session Size,Number of Probes Success,Number of Probes Warning,Number of Probes Failure,Test Duration,Connection Duration,Call Setup Time,Score,MOS,CPU,Memory,Bitrate Audio In,Bitrate Audio Out,Bitrate Video In,Bitrate Video Out,Packet Loss Audio In,Packet Loss Audio Out,Packet Loss Video In,Packet Loss Video Out,Jitter Audio In,Jitter Audio Out,Jitter Video In,Jitter Video Out,RTT Audio Out,RTT Video Out",
  QualityRTCInvites: "Invite Name,URL,Owner,Runs,Runs Left,Expiration Date",
  QualityRTCInviteTests:
    "ID,Status,Date,Email,Reason,Video Quality Bitrate In,Video Quality Bitrate Out,Video Quality Loss In,Video Quality Loss Out,Region,URL,Location,Test Time,IP,Throughput Min,Throughput Avg,Call Quality MOS,Call Quality Error,Call Quality Packet Loss,Call Quality Jitter,Call Quality RTT,TURN Connectivity UDP,TURN Connectivity TCP,Bandwidth Speed Uplink,Bandwidth Speed Downlink,Bandwidth Speed Jitter,PING",
};

const Presets = (props: IPresetsProps) => {
  const classes = useStyles();
  const uploadRef = React.useRef<any>(null);

  const {
    viewName,
    disablePresets,
    gridApi,
    columnApi,
    columnDefs,
    userRole,
    exportProduct,
    customExportRowLimit,
    customExportColumnNames,
    doExport,
    isJsonExportAvailable,
    isFramed,
  } = props;
  const setNotification = props.setNotification as any;

  const { preset, presets, defaultPresetConfig, setPreset, setPresets } = usePresetsContext();
  const { selectedProject } = React.useContext(ProjectsContext);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = React.useState(false);
  const [saveNewDialogOpen, setSaveNewDialogOpen] = React.useState(false);
  const { getCurrentPresetConfig } = usePresetConfig(gridApi);

  const { exportConfig } = useExportConfig(!doExport || isFramed);

  const [moreDialogAnchorEl, setMoreDialogAnchorEl] = React.useState<null | HTMLElement>(null);
  const isMoreDialogOpen = Boolean(moreDialogAnchorEl);
  const openMoreDialog = (event: React.MouseEvent<HTMLElement>) => {
    setMoreDialogAnchorEl(event.currentTarget);
  };
  const closeMoreDialog = () => {
    setMoreDialogAnchorEl(null);
  };

  const [exportFormat, setExportFormat] = React.useState<ExportFormat | undefined>();
  const openExportDialog = (newExportFormat: ExportFormat) => setExportFormat(newExportFormat);
  const closeExportDialog = () => setExportFormat(undefined);

  const exportRetentionPeriodDays = exportProduct
    ? Math.min(
        selectedProject?.[PROJECT_EXPORT_RETENTION_PERIOD_KEYS[exportProduct]],
        SYSTEM_EXPORT_RETENTION_PERIOD_DAYS[exportProduct]
      )
    : defaultExportRetentionPeriodDays;
  const exportRowLimit = customExportRowLimit ?? exportConfig?.maxObjectsToExport ?? defaultExportRowLimit;
  const isExportEnabled = doExport && exportRowLimit;

  let columnNames: string[];
  if (!customExportColumnNames) {
    columnNames = columnDefs
      ?.map((colDef) => colDef.headerName)
      .filter((columnName): columnName is string => Boolean(columnName));
  } else if (typeof customExportColumnNames === "string") {
    columnNames = customExportColumnNames.split(",");
  } else {
    columnNames = customExportColumnNames;
  }

  React.useEffect(() => {
    getPresets();
  }, []);

  const getPresets = async () => {
    try {
      const result: AxiosResponse = await axiosInstance.get(`${ApiPath.api.gridPreset.root}/${viewName}`);
      setPresets(result.data);
    } catch (err) {
      reportError("Couldn't fetch presets", err);
    }
  };

  const deletePreset = async (id?: string) => {
    try {
      if (id) {
        setPresets(presets.filter((x) => x._id !== id));

        await axiosInstance.delete(`${ApiPath.api.gridPreset.root}/${id}`);

        if (defaultPresetConfig) {
          applyPreset(defaultPresetConfig);
        }
      }
    } catch (err) {
      console.log(err.message, { err: err.stack });
      setNotification("Could not delete preset");
    }
  };

  const saveNewPreset = async (name: string) => {
    try {
      const currentPresetConfig = getCurrentPresetConfig();
      const gridPreset = {
        name,
        gridName: viewName,
        preset: JSON.stringify(currentPresetConfig),
        global: false,
      } as IPreset;

      const result: AxiosResponse = await axiosInstance.post(`${ApiPath.api.gridPreset.root}`, gridPreset);
      const newPreset = result.data;
      setPresets([...presets, newPreset]);
      setPreset(newPreset);
      setNotification("New preset saved");
    } catch (err) {
      setNotification("Could not save new preset.");
    }
  };

  const savePreset = async () => {
    try {
      const currentPresetConfig = getCurrentPresetConfig();
      const presetToSave = {
        global: preset?.global,
        preset: JSON.stringify(currentPresetConfig),
      };

      const result: AxiosResponse = await axiosInstance.put(
        `${ApiPath.api.gridPreset.root}/${preset?._id}`,
        presetToSave
      );
      const presetIndex = presets.findIndex((x) => x._id === preset?._id);
      const presetsCopy = [...presets];
      presetsCopy[presetIndex] = result.data;
      setPresets(presetsCopy);
      setNotification(`${result.data.name} preset saved.`);
    } catch (err) {
      setNotification("Could not save preset");
    }
  };

  const exportPreset = () => {
    const presetToExport = preset as IPreset;
    presetToExport.global = false;
    const data = JSON.stringify(presetToExport);
    const blob = new Blob([data], { type: "application/json" });
    saveAs(blob, presetToExport.name);
  };

  const importPreset = (event: React.FormEvent<HTMLInputElement>) => {
    const file = event.target["files"][0];
    const reader = new FileReader();
    reader.readAsText(file, "UTF-8");
    reader.onload = async (evt: Event) => {
      const text = evt.target ? evt.target["result"] : {};
      try {
        const gridPreset = JSON.parse(text) as IPreset;
        // check if obj is grid preset
        if (!gridPreset.name || !gridPreset.preset) {
          setNotification("Uploaded file is not grid preset configuration.");
          return;
        }
        if (gridPreset.gridName !== viewName) {
          setNotification(`Could not apply preset as uploaded configuration is for ${viewName} grid.`);
          return;
        }
        if (presets.findIndex((x) => x.name === gridPreset.name) !== -1) {
          gridPreset.name = gridPreset.name + " copy";
        }
        delete gridPreset._id;

        const result: AxiosResponse = await axiosInstance.post(`${ApiPath.api.gridPreset.root}`, gridPreset);
        const importedPreset = result.data;
        setPresets([...presets, importedPreset]);
        setPreset(importedPreset);
        setNotification("Preset imported");
      } catch (err) {
        reportError("Couldn't import preset", err);
        setNotification("Couldn't import preset");
      }
    };
  };

  const selectPreset = (id: string) => {
    const preset = presets.find((x) => x._id === id);
    if (preset) {
      setPreset(preset);
      applyPreset(JSON.parse(preset.preset as string) as Preset);
    } else {
      setPreset(undefined);
      if (defaultPresetConfig) {
        applyPreset(defaultPresetConfig);
      }
    }
  };

  const applyPreset = (preset: Preset) => {
    if (gridApi && columnApi) {
      columnApi.applyColumnState({ state: preset.colState, applyOrder: true });
      columnApi.setColumnGroupState(preset.groupState);

      gridApi.setFilterModel(preset.filterState);
      if (props.rowModelType === "serverSide") {
        gridApi.refreshServerSideStore({ purge: true });
      }
    }
  };

  const toggleDeleteConfirmation = () => {
    setDeleteConfirmationOpen(!deleteConfirmationOpen);
  };

  const toggleSaveNewDialog = () => {
    setSaveNewDialogOpen(!saveNewDialogOpen);
  };

  const onDeleteConfirm = async () => {
    const id = preset?._id;

    await deletePreset(id);
    toggleDeleteConfirmation();
  };

  const onSaveNew = async (name: string) => {
    await saveNewPreset(name);
    toggleSaveNewDialog();
  };

  const onExportPreset = () => {
    exportPreset();
  };

  const onImport = () => {
    uploadRef.current.click();
  };

  const onFile = (event: React.FormEvent<HTMLInputElement>) => {
    importPreset(event);
  };

  const isAdmin = userRole === "admin";
  const isGlobalPreset = preset?.global;
  const userEditAllowed = !selectedProject?.editGridPresets?.length
    ? true
    : selectedProject?.editGridPresets.includes(viewName + "");

  const showEditActions = isAdmin || userEditAllowed;

  const showGlobalButton = preset && isAdmin;
  const showSaveNewButton = isAdmin || userEditAllowed;
  const showSaveButton = preset && (isAdmin || (userEditAllowed && !isGlobalPreset));
  const showDeleteButton = preset && (isAdmin || (userEditAllowed && !isGlobalPreset));
  const showExportButton = preset && (isAdmin || userEditAllowed);
  const showImportButton = isAdmin;

  return (
    <div className={classes.container}>
      {!disablePresets ? (
        <>
          <SelectControl options={presets} selected={preset?._id} onSelect={selectPreset} />

          {showEditActions && (
            <div className={classes.buttonsContainer}>
              {showSaveNewButton && (
                <Tooltip title={"Save New"} placement="top">
                  <Button size="small" onClick={toggleSaveNewDialog}>
                    <SaveNewIcon fontSize="small" />
                    <Typography fontSize={14} marginLeft={1} textTransform="capitalize">
                      Save as New
                    </Typography>
                  </Button>
                </Tooltip>
              )}
            </div>
          )}
        </>
      ) : null}

      {(isExportEnabled || (!disablePresets && showEditActions)) && (
        <>
          <IconButton size="small" onClick={openMoreDialog}>
            <MoreVertIcon />
          </IconButton>
          <Menu
            anchorEl={moreDialogAnchorEl}
            id="account-menu"
            open={isMoreDialogOpen}
            onClose={closeMoreDialog}
            onClick={closeMoreDialog}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            {isExportEnabled && [
              <MenuItem onClick={() => openExportDialog("csv")} key="downloadCsv">
                <ExportIcon fontSize="small" htmlColor="#c5c5c5" />
                <Typography fontSize={14} marginLeft={1}>
                  Download CSV
                </Typography>
              </MenuItem>,
              isJsonExportAvailable ? (
                <MenuItem onClick={() => openExportDialog("json")} key="downloadJson">
                  <ExportIcon fontSize="small" htmlColor="#c5c5c5" />
                  <Typography fontSize={14} marginLeft={1}>
                    Download JSON
                  </Typography>
                </MenuItem>
              ) : null,
            ]}

            {isExportEnabled && !disablePresets && showEditActions ? <Divider key="downloadDivider" /> : null}

            {!disablePresets &&
              showEditActions && [
                showDeleteButton && (
                  <MenuItem onClick={toggleDeleteConfirmation} key="delete">
                    <DeleteIcon fontSize="small" htmlColor="#c5c5c5" />
                    <Typography fontSize={14} marginLeft={1}>
                      Delete
                    </Typography>
                  </MenuItem>
                ),

                showImportButton && (
                  <MenuItem onClick={onImport} key="import">
                    <>
                      <input
                        type="file"
                        ref={uploadRef}
                        style={{ display: "none" }}
                        accept="application/json"
                        onChange={onFile}
                      />
                    </>
                    <ImportIcon fontSize="small" htmlColor="#c5c5c5" />
                    <Typography fontSize={14} marginLeft={1}>
                      Import preset
                    </Typography>
                  </MenuItem>
                ),

                showGlobalButton && (
                  <MenuItem key="setGlobal">
                    <GlobalControl />
                    <Typography fontSize={14} marginLeft={1}>
                      Set global
                    </Typography>
                  </MenuItem>
                ),
                showSaveButton && (
                  <MenuItem onClick={savePreset} key="save">
                    <SaveIcon fontSize="small" htmlColor="#c5c5c5" />
                    <Typography fontSize={14} marginLeft={1}>
                      Save
                    </Typography>
                  </MenuItem>
                ),
                showExportButton && (
                  <MenuItem onClick={onExportPreset} key="exportPreset">
                    <ExportIcon fontSize="small" htmlColor="#c5c5c5" />
                    <Typography fontSize={14} marginLeft={1}>
                      Export preset
                    </Typography>
                  </MenuItem>
                ),
              ]}
          </Menu>
        </>
      )}

      <SaveNewDialog open={saveNewDialogOpen} onSave={onSaveNew} onClose={toggleSaveNewDialog} />
      {isExportEnabled ? (
        <ExportDialog
          format={exportFormat}
          retentionPeriodDays={exportRetentionPeriodDays}
          rowLimit={exportRowLimit}
          columnNames={columnNames}
          doExport={doExport}
          closeDialog={closeExportDialog}
        />
      ) : null}
      <DeleteConfirmation
        open={deleteConfirmationOpen}
        onConfirm={onDeleteConfirm}
        onClose={toggleDeleteConfirmation}
      />
    </div>
  );
};

export default Presets;

import { useEffect, useState } from "react";
import { AxiosResponse } from "axios";
import { useDispatch } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";

import { axiosInstance } from "@monortc/front/services/AxiosService";
import { SetNotification } from "@monortc/front/actions/notificationAction";
import ButtonSimple from "@monortc/front/components/ButtonSimple";
import AuthService from "@monortc/front/services/AuthService";
import { FetchUser } from "@monortc/front/actions/authAction";
import ApiPath from "@/constants/ApiPath";
import { AuthRoutes } from "@/constants/routes/auth-routes";
import { AppRoutes } from "@/constants/routes/app-routes";

type RouteParams = {
  objectId: string;
};

const useStyles = makeStyles(
  createStyles({
    container: {
      maxWidth: 600,
      width: "100%",
    },
    title: {
      margin: 0,

      textAlign: "center",
      fontSize: 38,
      fontWeight: 700,
      lineHeight: 1.5,
      letterSpacing: "-0.5px",
    },
    subtitle: {
      margin: "16px 0 0",

      textAlign: "center",
      letterSpacing: "0.15px",
    },
    continueButton: { margin: "36px auto 0" },
  })
);

const ConfirmAccount = (props: RouteComponentProps<RouteParams>) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const fetchUser = () => dispatch(FetchUser({ assureAuth: true }));
  const setNotification = (message: string) => dispatch(SetNotification(message));

  const [confirming, setConfirming] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        await axiosInstance
          .get(`${ApiPath.api.user.confirmAccount}/${props.match.params.objectId}?assureAuth=true`)
          .then((response: AxiosResponse<LoginResponse>) => {
            if (response.status === 200 && response?.data?.token && response?.data?.expires) {
              AuthService.setToken(response.data.token);
              AuthService.setExpires(response.data.expires);
            }
          });
      } catch (err) {
        props.history.push(AuthRoutes.SignIn);
        setNotification(err?.response?.data?.message || "Failed to confirm account. Token is invalid");
      } finally {
        setConfirming(false);
      }
    })();
  }, []);

  const handleContinue = async () => {
    const result: any = await fetchUser();
    const user: User = result.data;

    if (user) {
      props.history.push(AppRoutes.Main);
    } else {
      props.history.push(AuthRoutes.SignIn);
    }
  };

  let resultJsx: JSX.Element;

  if (confirming) {
    resultJsx = (
      <div className={classes.container}>
        <Typography variant="h1" className={classes.title}>
          Waiting for confirmation
        </Typography>
        <p className={classes.subtitle}>Please wait...</p>
      </div>
    );
  } else {
    resultJsx = (
      <div className={classes.container}>
        <Typography variant="h1" className={classes.title}>
          All Set!
        </Typography>
        <p className={classes.subtitle}>Your Account is good to go.</p>

        <ButtonSimple onClick={handleContinue} className={classes.continueButton}>
          Continue
        </ButtonSimple>
      </div>
    );
  }

  return resultJsx;
};

export default ConfirmAccount;

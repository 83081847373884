import { useEffect } from "react";
import { Redirect, Route, RouteComponentProps, Switch, withRouter } from "react-router-dom";
import { CircularProgress } from "@mui/material";

import AuthService from "@monortc/front/services/AuthService";
import AuthProvider from "@monortc/front/containers/Auth/AuthProvider";
import Navbar from "./components/Navbar";
import WorkingSection from "./components/WorkingSection";
import SignInPage from "@/containers/Auth/SignInPage";
import SignUpPage from "@/containers/Auth/SignUpPage";
import SignUpConfirmPage from "@/containers/Auth/SignUpConfirmPage";
import ForgotPasswordPage from "@/containers/Auth/ForgotPasswordPage";
import ResetPassword from "@/containers/Auth/ResetPassword";
import ConfirmAccount from "@/containers/Auth/ConfirmAccount";
import { AuthRoutes } from "@/constants/routes/auth-routes";
import { AppRoutes } from "@/constants/routes/app-routes";
import SingleSignOnPage from "../SingleSignOnPage";
import CreatePassword from "../CreatePassword";
import PricingPage from "@/containers/Auth/PricingPage";

const LoginLayout = (props: RouteComponentProps<{}>) => {
  useEffect(() => {
    if (AuthService.loggedIn()) {
      if (props.location.pathname !== AuthRoutes.CreatePassword) {
        props.history.push(AppRoutes.Main);
      }
    } else {
      AuthService.logout("agent-assure");
    }
  }, []);

  return (
    <AuthProvider loaderIcon={<CircularProgress />} authQueryParams={{ assureAuth: true }}>
      <Navbar />
      <WorkingSection>
        <Switch>
          <Route exact path={AuthRoutes.Pricing} component={PricingPage} />
          <Route exact path={AuthRoutes.SignIn} component={SignInPage} />
          <Route exact path={AuthRoutes.SingleSignOn} component={SingleSignOnPage} />
          <Route exact path={AuthRoutes.SignUp} component={SignUpPage} />
          <Route exact path={AuthRoutes.ForgotPassword} component={ForgotPasswordPage} />
          <Route exact path={AuthRoutes.CreatePassword} component={CreatePassword} />
          <Route exact path={AuthRoutes.ResetPasswordId} component={ResetPassword} />
          <Route exact path={AuthRoutes.ConfirmAccountId} component={ConfirmAccount} />
          <Route exact path={`${AuthRoutes.SignUpConfirm}/:email`} component={SignUpConfirmPage} />
          <Redirect exact from="/" to={AuthRoutes.SignIn} />
        </Switch>
      </WorkingSection>
    </AuthProvider>
  );
};

export default withRouter(LoginLayout);

import * as React from "react";
import { useLayoutEffect, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { ProjectsContext } from "@/containers/AppController";
import { AppRoutes } from "@/constants/routes/app-routes";

export interface NavigationItem {
  name: string;
  link: string;
  Icon?: any;
  svgIcon?: boolean;
  hidden?: boolean;
  disabled?: boolean;
  child?: string[];
  items?: NavigationItem[];
}

export interface AuthorizationCheckerProps {
  isSuperAdmin: boolean;
  isProjectAdmin: boolean;
  isAccountExpired: boolean;
}

function isUnauthorized(
  navigationItem: NavigationItem,
  parentNavigationItem: NavigationItem | null,
  navigationBlockName: string,
  isSuperAdmin: boolean,
  pathname: string
) {
  const isLinkMatching =
    pathname.includes(navigationItem.link) ||
    Boolean(navigationItem.child?.find((childLink) => pathname.includes(childLink)));

  const isForbidden =
    navigationItem.disabled ||
    navigationItem.hidden ||
    parentNavigationItem?.disabled ||
    parentNavigationItem?.hidden ||
    (navigationBlockName === "adminItems" && !isSuperAdmin);

  return !isSuperAdmin && isLinkMatching && isForbidden;
}

function withAuthorization(
  getNavigationBlocks: (
    project: Project | null | undefined,
    isSuperAdmin: boolean,
    isProjectAdmin: boolean,
    isAccountExpired: boolean
  ) => Record<string, NavigationItem[]>
) {
  return function withAuthorizationInner<T extends AuthorizationCheckerProps>(Child: React.ComponentType<T>) {
    return function AuthorizationChecker(props: T) {
      const location = useLocation();
      const history = useHistory();

      const { selectedProject } = useContext(ProjectsContext);

      const { isSuperAdmin, isProjectAdmin, isAccountExpired } = props;

      useLayoutEffect(
        function redirectTo403IfNotAuthorized() {
          if (selectedProject) {
            const navigationBlocks = getNavigationBlocks(
              selectedProject,
              isSuperAdmin,
              isProjectAdmin,
              isAccountExpired
            );

            for (const [navigationBlockName, navigationBlock] of Object.entries(navigationBlocks)) {
              for (const parentNavigationItem of navigationBlock) {
                if (isUnauthorized(parentNavigationItem, null, navigationBlockName, isSuperAdmin, location.pathname)) {
                  history.replace(AppRoutes.Forbidden);
                  return;
                } else if (parentNavigationItem.items) {
                  for (const childNavigationItem of parentNavigationItem.items) {
                    if (
                      isUnauthorized(
                        childNavigationItem,
                        parentNavigationItem,
                        navigationBlockName,
                        isSuperAdmin,
                        location.pathname
                      )
                    ) {
                      history.replace(AppRoutes.Forbidden);
                    }
                  }
                }
              }
            }
          }
        },
        [selectedProject, isAccountExpired]
      );

      return <Child {...props} />;
    };
  };
}

export default withAuthorization;

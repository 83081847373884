import { createStyles, makeStyles } from "@mui/styles";

import ButtonSimple from "@monortc/front/components/ButtonSimple";
import GOOGLE_IMAGE from "@monortc/front/assets/socials/google.svg";

interface LoginSocialButtonsProps {
  isSignUp?: boolean;
}

const useStyles = makeStyles(
  createStyles({
    socialButton: {
      justifyContent: "flex-start",
    },
    socialButtonContent: { display: "flex", alignItems: "center" },
    socialIconSimple: {
      width: 22,
      margin: "0 53px 0 25px",
    },
  })
);

const LoginSocialButtons = ({ isSignUp }: LoginSocialButtonsProps) => {
  const classes = useStyles();

  const loginOAuth = (provider: string) => {
    window.location.href = `${process.env.REACT_APP_API_PATH}/auth/${provider}?assureAuth=true`;
  };

  return (
    <ButtonSimple
      onClick={() => loginOAuth("google")}
      variant="outlined"
      color="secondary"
      fullWidth
      className={classes.socialButton}
    >
      <div className={classes.socialButtonContent}>
        <img draggable={false} className={classes.socialIconSimple} src={GOOGLE_IMAGE} alt="google" />
        {isSignUp ? "Sign up with Google" : "Login with Google"}
      </div>
    </ButtonSimple>
  );
};

export default LoginSocialButtons;

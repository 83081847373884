import { RouteComponentProps } from "react-router-dom";
import { Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";

interface IRouterProps {
  email: string;
}

const useStyles = makeStyles(
  createStyles({
    container: {
      maxWidth: 600,
      width: "100%",
    },
    title: {
      margin: 0,

      textAlign: "center",
      fontSize: 38,
      fontWeight: 700,
      lineHeight: 1.5,
      letterSpacing: "-0.5px",
    },
    mainText: {
      margin: "16px 0 0",

      textAlign: "center",
    },
  })
);

const SignUpConfirmPage = (props: RouteComponentProps<IRouterProps>) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Typography variant="h1" className={classes.title}>
        Thanks for signing up!
      </Typography>

      <p className={classes.mainText}>
        We have sent a verification link to: <strong>{props.match.params.email}</strong>. Please follow the instructions
        in the email to complete the sign up process.
      </p>
    </div>
  );
};

export default SignUpConfirmPage;

import { AppBar, Button, Theme, Toolbar } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { Link } from "react-router-dom";

import { AuthRoutes } from "@/constants/routes/auth-routes";
import logoImgSrc from "@/assets/images/logo-agent-assure.svg";
import ButtonSimple from "src/components/ButtonSimple";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      backgroundColor: "#fff",
      boxShadow: "none",
    },
    toolbar: {
      padding: 16,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    logoLink: {
      textDecoration: "none",
      height: 40,
      [theme.breakpoints.down("sm")]: {
        height: 28,
      },
      "& button": {
        minWidth: "unset",
      },
    },
    logoImg: {
      height: "100%",
    },
    buttonsContainer: {
      display: "flex",
      gap: "20px",
    },
  })
);

const Navbar = () => {
  const classes = useStyles();
  const pathname = window.location.pathname;

  const signUpLinks = (
    <>
      <Link className={classes.logoLink} to={AuthRoutes.Pricing}>
        <Button style={{ textTransform: "none", color: "#243544" }} variant="text">
          Pricing
        </Button>
      </Link>
      <Link className={classes.logoLink} to={AuthRoutes.SignIn}>
        <ButtonSimple variant="contained">Log In</ButtonSimple>
      </Link>
    </>
  );
  const pricingLinks = (
    <Link className={classes.logoLink} to={AuthRoutes.SignIn}>
      <ButtonSimple variant="contained">Log In</ButtonSimple>
    </Link>
  );

  return (
    <AppBar position="static" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <Link className={classes.logoLink} to={AuthRoutes.SignIn}>
          <img className={classes.logoImg} src={logoImgSrc} alt="Agent Assure" />
        </Link>
        <div className={classes.buttonsContainer}>
          {pathname === AuthRoutes.SignUp ? signUpLinks : pathname === AuthRoutes.Pricing ? pricingLinks : ""}
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;

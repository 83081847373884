import * as React from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AxiosResponse } from "axios";

import { axiosInstance } from "@monortc/front/services/AxiosService";
import AuthService from "@monortc/front/services/AuthService";
import SocketService from "@monortc/front/services/SocketService";
import { FetchUser } from "@monortc/front/actions/authAction";
import { dispatchDataLoading } from "@monortc/front/actions/dataLoadingActions";
import { AuthRoutes } from "@/constants/routes/auth-routes";
import { AppRoutes } from "@/constants/routes/app-routes";
import ApiPath from "@/constants/ApiPath";

interface AppControllerProps {
  children: React.ReactNode;
}

export type AppControllerState = {
  projects?: Array<Project>;
  selectedProject?: Project | null;
  setSelectedProject?: (projectId: string, url?: string) => void;
  updateSelectedProject?: () => Promise<Project | null>;
};

export const ProjectsContext = React.createContext({
  projects: [],
} as AppControllerState);

const AppController = ({ children }: AppControllerProps) => {
  const history = useHistory();

  const dispatch = useDispatch();
  const user = useSelector((state: IStore<"assureUser">) => state.userAuth.user);

  const toggleUsersProject = React.useCallback(
    async (projectId: string, path?: string) => {
      dispatch(dispatchDataLoading(true));

      try {
        const result: AxiosResponse<LoginResponse> = await axiosInstance.post(
          `${ApiPath.api.user.changeProject}/${projectId}`,
          {
            id: projectId,
            assureAuth: true,
          }
        );

        const data = result.data;

        AuthService.setToken(data.token);
        AuthService.setExpires(data.expires);

        // TODO: socket update here
        await dispatch(FetchUser({ assureAuth: "true" }));
        dispatch(dispatchDataLoading(false));

        history.replace(path || "/");
      } catch (err) {
        // TODO: add global error handler
        // tslint:disable-next-line:no-console
        console.log(err);
      }
    },
    [dispatch, dispatchDataLoading, axiosInstance, ApiPath, AuthService, FetchUser, history]
  );

  const [state, setState] = React.useState<AppControllerState>({
    projects: [],
    selectedProject: null,
  });

  const setSelectedProject = React.useCallback(
    async (projectId: string, path?: string) => {
      setState((currentState) => ({
        ...currentState,
        selectedProject: currentState.projects && currentState.projects.find((p: Project) => p._id === projectId),
      }));
      await toggleUsersProject(projectId, path);
      SocketService._instance.refresh();
    },
    [setState, toggleUsersProject, SocketService._instance]
  );

  const getProjectDetails = React.useCallback(
    async (projectId: string | null): Promise<Project | null> => {
      if (!projectId) {
        return null;
      }

      try {
        const result: AxiosResponse<Project> = await axiosInstance.get(
          `${ApiPath.api.projects.root}/projectSetup/${projectId}`,
          { params: { assureAuth: true } }
        );

        return result.data;
      } catch (err) {
        // TODO: add global error handler
        // tslint:disable-next-line:no-console
        console.log(err);
      }
      return null;
    },
    [axiosInstance, ApiPath]
  );

  const updateSelectedProject = React.useCallback(async () => {
    let result: Project | null = null;

    if (state.selectedProject) {
      result = await getProjectDetails(state.selectedProject._id);

      setState((currentState) => ({ ...currentState, selectedProject: result }));
    }

    return result;
  }, [state, getProjectDetails, setState]);

  const setProject = async () => {
    const result: AxiosResponse<Array<Project>> = await axiosInstance.get(ApiPath.api.projects.all, {
      params: {
        userId: user._id,
        assureAuth: true,
      },
    });

    const projects = result.data.sort((a, b) => a.name_sort.localeCompare(b.name_sort));
    let selectedProject = null;

    const isProjects = projects?.length > 0;

    if (!isProjects) {
      AuthService.logout("agent-assure");
      history.push(AuthRoutes.SignIn);
      return;
    }

    const userProject = user?.assureProject;
    const isUserProject = !!userProject?._id;
    const isInProjects = projects.some((p: any) => p._id === userProject._id);
    const isUserProjectValid = isUserProject && (isInProjects || user.role === "admin");

    if (!isUserProject || (isUserProject && !isUserProjectValid)) {
      selectedProject = projects[0];
      await toggleUsersProject(selectedProject._id);
    }

    if (user.role !== "admin") {
      projects.forEach((p: Project) => {
        if (userProject && p._id === userProject._id) {
          selectedProject = p;
        }
      });
    } else {
      selectedProject = { _id: userProject._id };
    }

    const project = await getProjectDetails(selectedProject && selectedProject._id);

    if (!isInProjects && user.role === "admin" && project) {
      projects.push(project);
    }

    setState({
      projects,
      selectedProject: project,
    });
  };

  const contextValue = React.useMemo(
    () => ({
      projects: state.projects,
      selectedProject: state.selectedProject,
      setSelectedProject,
      updateSelectedProject,
    }),
    [state.projects, state.selectedProject, setSelectedProject, updateSelectedProject]
  );

  React.useEffect(() => {
    (async () => {
      if (user) {
        const localUser = user as AssureUser & { hashedPassword?: string; loggedInAs?: unknown; acceptTerms?: boolean };

        if (!localUser.hashedPassword) {
          history.push(AuthRoutes.CreatePassword);
        } else if (!localUser.loggedInAs && !localUser.acceptTerms) {
          history.push(AppRoutes.UserSettings);
        }

        await setProject();
      }
    })();
  }, []);

  return <ProjectsContext.Provider value={contextValue}>{children}</ProjectsContext.Provider>;
};

export default AppController;

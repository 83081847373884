import { useDispatch } from "react-redux";
import { createStyles, makeStyles } from "@mui/styles";
import { IconButton, Theme, Tooltip } from "@mui/material";
import { useBoolean } from "usehooks-ts";
import { FileCopyOutlined } from "@mui/icons-material";
import { CopyToClipboard } from "react-copy-to-clipboard";
import cn from "classnames";

import { SetNotification } from "src/actions/notificationAction";
import CustomKeyValue from "../CustomKeyValue";

export interface CustomKeyConfig {
  label: string;
  labelTooltip?: string;
  keyName: string;
  values: (string | { id: string; label: string; endpoint: string })[];
}

type CustomKeyProps = CustomKeyConfig & { tablePageLink?: string };

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: { minWidth: 0, flex: 1 },
    container: { minWidth: 0, display: "flex", gap: 8, alignItems: "center" },
    valuesContainer: {
      minWidth: 0,
    },
    label: {
      margin: "0 0 4px",

      fontSize: 12,
      lineHeight: "20px",
      fontWeight: 400,
      color: theme.palette.text.secondary,
      letterSpacing: "0.17px",
    },
    values: { minWidth: 0 },
    showMoreButton: {
      padding: 0,
      border: 0,
      background: "none",
      cursor: "pointer",

      fontSize: 14,
      lineHeight: "20px",
      fontWeight: 400,
      letterSpacing: "0.17px",
      color: "#547189",

      "&:hover": { textDecoration: "underline" },
    },
    copyButton: { margin: "-5px 0 -5px 8px" },
    copyIcon: { width: 20, height: 20 },
    copyButtonNoShowMore: { position: "relative", top: 12 },
  })
);

const itemCountVisibleWithoutExpansion = 2;

const CustomKey = ({ label, labelTooltip, keyName, values, tablePageLink }: CustomKeyProps) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const { value: isExpanded, toggle: toggleIsExpanded } = useBoolean(false);
  const isExpandable = values.length > itemCountVisibleWithoutExpansion;

  const valuesJsx = values
    .slice(0, isExpandable && !isExpanded ? itemCountVisibleWithoutExpansion : undefined)
    .map(function getValueJsx(value) {
      return (
        <CustomKeyValue
          keyName={keyName}
          value={value}
          tablePageLink={tablePageLink}
          key={typeof value === "string" ? value : value.id}
        />
      );
    });

  const canShowMore = isExpandable && !isExpanded;

  return (
    <div className={classes.wrapper}>
      <div className={classes.container}>
        <div className={classes.valuesContainer}>
          <Tooltip title={labelTooltip ?? ""} arrow>
            <h4 className={classes.label}>{label}</h4>
          </Tooltip>

          <div className={classes.values}>{valuesJsx}</div>

          {canShowMore ? (
            <button onClick={toggleIsExpanded} className={classes.showMoreButton}>
              Show {values.length - itemCountVisibleWithoutExpansion} more
            </button>
          ) : null}
        </div>

        <CopyToClipboard
          text={values.reduce((accumulator, value) => {
            let result = "";

            if (accumulator) {
              result = `${accumulator}, ${typeof value === "string" ? value : value.label}`;
            } else {
              result = typeof value === "string" ? value : value.label;
            }

            return result;
          }, "")}
          onCopy={(_text: string | undefined, result: Boolean) =>
            dispatch(SetNotification(result ? `${label} value copied to clipboard` : "Copy to clipboard failed"))
          }
        >
          <IconButton size="small" className={cn(classes.copyButton, { [classes.copyButtonNoShowMore]: !canShowMore })}>
            <FileCopyOutlined className={classes.copyIcon} />
          </IconButton>
        </CopyToClipboard>
      </div>
    </div>
  );
};

export default CustomKey;

import { useState } from "react";
import { Theme, IconButton, alpha } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import {
  ChevronLeftOutlined as ChevronLeftOutlinedIcon,
  ChevronRightOutlined as ChevronRightOutlinedIcon,
  FirstPageOutlined as FirstPageOutlinedIcon,
  LastPageOutlined as LastPageOutlinedIcon,
} from "@mui/icons-material";

interface PaginationControlsProps {
  firstPage?: number;
  currentPage: number | undefined;
  lastPage: number | undefined;
  handlePageChange: (index: number) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: "0 6px",
      display: "flex",
      alignItems: "center",
      columnGap: 6,
    },
    navigationButton: {
      width: 26,
      height: 26,
      borderRadius: "4px",

      "& .MuiTouchRipple-child": {
        borderRadius: "4px",
      },
    },
    icon: {
      width: 18,
      height: 18,
      color: theme.palette.text.primary,
      ".Mui-disabled &": { color: alpha(theme.palette.text.primary, 0.38) },
    },
    pageNumbersContainer: { display: "flex", alignItems: "center" },
    pageNumberInput: {
      width: 26,
      height: 26,
      border: `1px solid ${alpha(theme.palette.text.primary, 0.23)}`,
      borderRadius: "4px",
      backgroundColor: "transparent",

      fontSize: 14,
      lineHeight: "26px",
      textAlign: "center",
      fontWeight: 400,
      letterSpacing: "0.17px",
    },
    pageNumber: {
      width: 26,
      height: 26,
      margin: 0,

      fontSize: 14,
      lineHeight: "26px",
      textAlign: "center",
      fontWeight: 400,
      whiteSpace: "pre",
      letterSpacing: "0.17px",
    },
  })
);

const PaginationControls = ({
  firstPage = 1,
  currentPage = 1,
  lastPage = 0,
  handlePageChange,
}: PaginationControlsProps) => {
  const classes = useStyles();

  const [targetPageNumber, setTargetPageNumber] = useState<number | "">(currentPage);

  let resultJsx: JSX.Element | null = null;

  if (lastPage > 1) {
    const nextPage = currentPage + 1;
    const prevPage = currentPage - 1;
    const isFirstPage = currentPage === firstPage;
    const isLastPage = currentPage === lastPage;

    resultJsx = (
      <div className={classes.container}>
        <IconButton
          onClick={() => handlePageChange(firstPage)}
          disabled={isFirstPage}
          className={classes.navigationButton}
        >
          <FirstPageOutlinedIcon className={classes.icon} />
        </IconButton>
        <IconButton
          onClick={() => handlePageChange(prevPage)}
          disabled={isFirstPage}
          className={classes.navigationButton}
        >
          <ChevronLeftOutlinedIcon className={classes.icon} />
        </IconButton>

        <div className={classes.pageNumbersContainer}>
          <input
            inputMode="numeric"
            pattern="[0-9]*"
            value={targetPageNumber}
            onFocus={() => {
              setTargetPageNumber("");
            }}
            onBlur={() => {
              if (!targetPageNumber) {
                setTargetPageNumber(currentPage);
              } else if (targetPageNumber !== currentPage) {
                handlePageChange(targetPageNumber);
              }
            }}
            onChange={(event) => {
              if (event.target.value) {
                let newPageNumber = Number(event.target.value);

                if (Number.isInteger(newPageNumber)) {
                  if (newPageNumber < firstPage) {
                    newPageNumber = firstPage;
                  } else if (newPageNumber > lastPage) {
                    newPageNumber = lastPage;
                  }
                } else {
                  newPageNumber = currentPage;
                }

                setTargetPageNumber(newPageNumber);
              } else {
                setTargetPageNumber("");
              }
            }}
            onKeyDown={(event) => {
              if (event.key === "Enter" && event.target instanceof HTMLInputElement) {
                event.target.blur();
              }
            }}
            className={classes.pageNumberInput}
          />
          <p className={classes.pageNumber}>/</p>
          <p className={classes.pageNumber}>{lastPage}</p>
        </div>

        <IconButton
          onClick={() => handlePageChange(nextPage)}
          disabled={isLastPage}
          className={classes.navigationButton}
        >
          <ChevronRightOutlinedIcon className={classes.icon} />
        </IconButton>
        <IconButton
          onClick={() => handlePageChange(lastPage)}
          disabled={isLastPage}
          className={classes.navigationButton}
        >
          <LastPageOutlinedIcon className={classes.icon} />
        </IconButton>
      </div>
    );
  }

  return resultJsx;
};

export default PaginationControls;

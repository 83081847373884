import { ReactNode } from "react";
import cn from "classnames";
import { IconButton, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { InfoOutlined as InfoOutlinedIcon } from "@mui/icons-material";

import CustomToolTip, { ICustomToolTip } from "src/components/Tooltip/CustomToolTip";

interface WidgetInfo {
  title: string;
  sharedUnit?: string;
  helpLink?: string;
}

export type Color = "default" | "good" | "warning" | "bad";

interface MultiMetricWidgetMetric {
  name: string;
  value: number | string;
  tooltip?: ICustomToolTip;
  color?: Color;
  id?: string;
}

interface TripleMetricWidget {
  variant: "triple";

  data: [MultiMetricWidgetMetric, MultiMetricWidgetMetric, MultiMetricWidgetMetric];
}

interface DoubleMetricWidget {
  variant: "double";

  data: [MultiMetricWidgetMetric, MultiMetricWidgetMetric];
}

interface SingleMetricWidget {
  variant: "single";

  data: {
    value: number | string;
    tooltip?: ICustomToolTip;
    color?: Color;
    id?: string;
  };
}

interface TableWidgetMetric {
  value: number | string;
  tooltip?: ICustomToolTip;
  color?: Color;
  id?: string;
}

interface TableWidget {
  variant: "table";

  data: {
    audio: {
      send: TableWidgetMetric;
      recv: TableWidgetMetric;
    };
    video: {
      send: TableWidgetMetric;
      recv: TableWidgetMetric;
    };
  };
}

export type WidgetConfig = WidgetInfo & (TripleMetricWidget | DoubleMetricWidget | SingleMetricWidget | TableWidget);

type WidgetProps = WidgetConfig;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      flex: "1 0 100px",
      position: "relative",
      borderStyle: "solid",
      borderColor: "#DEDEDE",
      background: "#FFFFFF",

      borderWidth: 0,
      borderRightWidth: 1,
      "&:last-child": { borderRightWidth: 0 },

      "@media (max-width: 1000px)": {
        minWidth: "33%",

        "&:nth-child(3n)": {
          borderRightWidth: 0,
        },

        "&:nth-child(n+4)": {
          borderTopWidth: 1,
        },
      },

      "@media (max-width: 756px)": {
        minWidth: "50%",

        "&:nth-child(3n)": {
          borderRightWidth: 1,
        },

        "&:nth-child(2n)": {
          borderRightWidth: 0,
        },

        "&:nth-child(n+3)": {
          borderTopWidth: 1,
        },
      },

      "@media (max-width: 516px)": {
        minWidth: "100%",

        borderTopWidth: 1,
        borderRightWidth: 0,
        "&:first-child": { borderTopWidth: 0 },

        "&:nth-child(3n)": {
          borderRightWidth: 0,
        },
      },

      "&:not(:hover) $infoIconButton": { display: "none" },
    },

    header: {
      padding: "7px 16px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    title: {
      margin: "0",

      fontSize: 16,
      lineHeight: "30px",
      fontWeight: 700,
    },
    unit: {
      fontSize: 12,
      lineHeight: 1.66,
      fontWeight: 400,
      letterSpacing: "0.4px",
      color: theme.palette.text.disabled,
    },
    infoIconButton: {
      position: "absolute",
      top: 8,
      right: 8,
      zIndex: 1,
    },
    infoIcon: { width: 20, height: "auto" },

    contentWrapper: {},
    multiMetricWidgetContent: {
      margin: "8px auto 0",
      padding: "8px 16px 23px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: 8,
    },
    singleMetricWidgetContent: {
      margin: "18px auto 0",
      padding: "8px 16px 27px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: 8,
    },
    tableWidgetContent: {
      padding: "0px 8px 7px 16px",
    },

    metricContainer: { flex: 1 },
    metricName: {
      margin: 0,

      fontSize: 12,
      lineHeight: 1.2,
      fontWeight: 400,
      textAlign: "center",
      letterSpacing: "0.4px",
    },
    metricValue: {
      margin: "8px 0 0",

      fontSize: 20,
      lineHeight: 1.25,
      fontWeight: 700,
      textAlign: "center",
      whiteSpace: "pre",
    },
    default: { color: theme.palette.text.primary },
    good: { color: theme.palette.success.light },
    warning: { color: theme.palette.data4.main },
    bad: { color: theme.palette.data5.main },

    table: {
      borderCollapse: "collapse",
      width: "100%",
    },
    tableHeaderRow: {},
    tableColumnHeaderCell: {
      width: "min-content",
      padding: "8px 8px 0",

      fontSize: 12,
      lineHeight: 1.2,
      fontWeight: 400,
      letterSpacing: "0.4px",
      textAlign: "center",
    },
    tableRow: {},
    tableRowHeaderCell: {
      width: "min-content",
      padding: "8px 0 0",

      fontSize: 12,
      lineHeight: "20px",
      fontWeight: 400,
      letterSpacing: "0.4px",
    },
    tableCell: {
      width: "50%",
      padding: "8px 4px 0",

      lineHeight: 1.25,
      fontWeight: 700,
      textAlign: "center",
    },
  })
);

const Widget = ({ title, sharedUnit, helpLink, variant, data }: WidgetProps) => {
  const classes = useStyles();

  const prettifyUnits = (metricValue: number | string) => {
    let result: ReactNode = metricValue;

    if (typeof metricValue === "string" && /\d/.test(metricValue)) {
      result = metricValue
        .split(/(\D+)|([\d,.]+)/)
        .filter((value) => Boolean(value))
        .map((valueOrUnit, index) => {
          let result: JSX.Element | string = index === 0 ? valueOrUnit : ` ${valueOrUnit}`;

          if (/[^\d]+/.test(valueOrUnit[0])) {
            const unit = valueOrUnit;

            result = (
              <span className={classes.unit} key={index}>
                {" "}
                {unit.trim()}
              </span>
            );
          }

          return result;
        });
    }

    return result;
  };

  const getMultipleMetricJsx = (metric: MultiMetricWidgetMetric) => {
    const metricJsx = (
      <p className={cn(classes.metricValue, metric.color && classes[metric.color])} id={metric.id}>
        {prettifyUnits(metric.value)}
      </p>
    );

    return (
      <div className={classes.metricContainer} key={metric.name}>
        <h3 className={classes.metricName}>{metric.name}</h3>
        {metric.tooltip ? <CustomToolTip {...metric.tooltip}>{metricJsx}</CustomToolTip> : metricJsx}
      </div>
    );
  };

  let contentJsx: JSX.Element;

  if (variant === "triple" || variant === "double") {
    contentJsx = <div className={classes.multiMetricWidgetContent}>{data.map(getMultipleMetricJsx)}</div>;
  } else if (variant === "single") {
    const metricJsx = (
      <div className={classes.singleMetricWidgetContent}>
        <p className={cn(classes.metricValue, data.color && classes[data.color])} id={data.id}>
          {prettifyUnits(data.value)}
        </p>
      </div>
    );

    contentJsx = (
      <div className={classes.metricContainer}>
        {data.tooltip ? <CustomToolTip {...data.tooltip}>{metricJsx}</CustomToolTip> : metricJsx}
      </div>
    );
  } else {
    const getTdJsx = (metric: TableWidgetMetric) =>
      metric.tooltip ? (
        <CustomToolTip
          wrapperElement="td"
          wrapperClassName={cn(classes.tableCell, metric.color && classes[metric.color])}
          {...metric.tooltip}
          id={metric.id}
        >
          {prettifyUnits(metric.value)}
        </CustomToolTip>
      ) : (
        <td className={cn(classes.tableCell, metric.color && classes[metric.color])} id={metric.id}>
          {prettifyUnits(metric.value)}
        </td>
      );

    contentJsx = (
      <div className={classes.tableWidgetContent}>
        <table className={classes.table}>
          <thead>
            <tr className={classes.tableHeaderRow}>
              <th scope="col" className={classes.tableColumnHeaderCell}></th>
              <th scope="col" className={classes.tableColumnHeaderCell}>
                in
              </th>
              <th scope="col" className={classes.tableColumnHeaderCell}>
                out
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className={classes.tableRow}>
              <th scope="row" className={classes.tableRowHeaderCell}>
                A
              </th>
              {getTdJsx(data.audio.recv)}
              {getTdJsx(data.audio.send)}
            </tr>
            <tr className={classes.tableRow}>
              <th scope="row" className={classes.tableRowHeaderCell}>
                V
              </th>
              {getTdJsx(data.video.recv)}
              {getTdJsx(data.video.send)}
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

  return (
    <div className={classes.container}>
      <header className={classes.header}>
        <h2 className={classes.title}>
          {title} {sharedUnit ? <span className={classes.unit}>({sharedUnit})</span> : null}
        </h2>
        {helpLink ? (
          <IconButton href={helpLink} target="_blank" size="small" className={classes.infoIconButton}>
            <InfoOutlinedIcon className={classes.infoIcon} />
          </IconButton>
        ) : null}
      </header>

      <div className={classes.contentWrapper}>{contentJsx}</div>
    </div>
  );
};

export default Widget;

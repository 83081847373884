import { useContext, useEffect, useState } from "react";
import { RouteProps, Redirect, Route, Switch } from "react-router";
import { useSelector } from "react-redux";
import * as moment from "moment";

import AsyncComponent from "@monortc/front/components/asyncComponent";
import AppRoot from "@monortc/front/containers/Layout/components/AppRoot";
import AppContentSpinner from "@monortc/front/containers/Layout/components/AppContentSpinner";
import { AccountingTypes } from "@monortc/front/containers/Admin/ProjectProperties";
import { getLocationTitle } from "@/constants/PageStateDictionary";
import AppContent from "@/containers/Layout/components/AppContent";
import Loader from "@/components/Loader";
import { ProjectsContext } from "@/containers/AppController";
import { AppRoutes } from "@/constants/routes/app-routes";
import { RedirectRules } from "@/constants/routes/redirect-routes";
import Sidebar from "./components/Sidebar";
import AlertToolbar, { TimeUnit, timeUnits } from "./components/AlertToolbar";

const Main = AsyncComponent(() => import("@/containers/Main"));

const ForbiddenPage = AsyncComponent(() => import("@/containers/ForbiddenPage"));
const ContentMissingPage = AsyncComponent(() => import("@/containers/ContentMissingPage"));

const History = AsyncComponent(() => import("@/containers/History"));
const Invites = AsyncComponent(() => import("@/containers/Invites"));

const Support = AsyncComponent(() => import("@/containers/Support"));
const AccountManagement = AsyncComponent(() => import("@/containers/AccountManagement"));
const UserSettings = AsyncComponent(() => import("@/containers/UserSettings"));
const Settings = AsyncComponent(() => import("@/containers/Settings"));

const trialAlertStatusKey = "assure-trial-alert-status";
const closedStatusValue = "closed";

const defaultMaxUsers = 2;

const AppLayout = (props: RouteProps) => {
  const { location } = props;

  const { selectedProject } = useContext(ProjectsContext);

  const user = useSelector((state: IStore<"assureUser">) => state.userAuth.user);
  const { _id: userId, email: userEmail } = user;

  const isSuperAdmin = user?.projectRoleName === "Super Admin";
  const isProjectAdmin = user?.projectRoleName === "Super Admin" || user?.projectRoleName === "Account Admin";
  const hasWriteAccess = user?.permissions?.accountManagement === "rw";

  const [isTrialAlertOpen, setIsTrialAlertOpen] = useState(false);

  useEffect(() => {
    setIsTrialAlertOpen(sessionStorage.getItem(trialAlertStatusKey) !== closedStatusValue);
  }, []);

  const closeTrialAlert = () => {
    setIsTrialAlertOpen(false);
    sessionStorage.setItem(trialAlertStatusKey, closedStatusValue);
  };

  const isAdminRoute = Boolean(location?.pathname.includes("/app/admin"));

  let isEvaluation = false;
  let isExpired = false;
  let isSuspended = false;
  let timeLeft = 0;
  let timeLeftUnit: TimeUnit = timeUnits[0];

  if (selectedProject && !isAdminRoute) {
    isEvaluation = selectedProject.accountType === AccountingTypes.evaluation;
    isExpired = new Date(selectedProject.paymentExpiry).getTime() < Date.now();
    isSuspended =
      selectedProject.accountType === AccountingTypes.suspended ||
      (selectedProject.accountType === AccountingTypes.customer && isExpired);

    if (isEvaluation && !isExpired) {
      const paymentExpiryMoment = moment(selectedProject.paymentExpiry);
      const nowMoment = moment();

      let i = 0;
      while (timeLeft <= 0 && i < timeUnits.length) {
        timeLeftUnit = timeUnits[i];
        timeLeft = paymentExpiryMoment.diff(nowMoment, timeLeftUnit);
        i += 1;
      }
    }
  }

  const isAlertHideable = timeLeftUnit === "day" && timeLeft >= 3;

  const isAlertVisible = (isEvaluation && (!isAlertHideable || isTrialAlertOpen || isExpired)) || isSuspended;
  const isEvaluationExpired = isEvaluation && isExpired;
  const isAccountExpired = isExpired || isSuspended;

  document.title = location?.pathname
    ? "Agent Assure | " + (getLocationTitle(location.pathname) || "App")
    : "Agent Assure";

  const RedirectRoutes = Object.keys(RedirectRules).map((route) => (
    <Redirect key={route} exact from={RedirectRules[route].from} to={RedirectRules[route].to} />
  ));

  return (
    <>
      <AlertToolbar
        isEvaluation={isEvaluation}
        isExpired={isExpired}
        isSuspended={isSuspended}
        isTrialAlertOpen={isTrialAlertOpen}
        timeLeft={timeLeft}
        timeLeftUnit={timeLeftUnit}
        isAlertHideable={isAlertHideable}
        closeTrialAlert={closeTrialAlert}
      />
      <AppRoot>
        <AppContentSpinner Loader={Loader} />
        <Sidebar
          isSuperAdmin={isSuperAdmin}
          isProjectAdmin={isProjectAdmin}
          isAlertVisible={isAlertVisible}
          isAccountExpired={isAccountExpired}
          userEmail={userEmail}
        />
        <AppContent isAlertVisible={isAlertVisible}>
          <Switch>
            {RedirectRoutes}
            <Route
              exact
              path={AppRoutes.Main}
              render={(props) => (
                <Main
                  {...props}
                  hasWriteAccess={hasWriteAccess}
                  isEvaluationExpired={isEvaluationExpired}
                  isSuspended={isSuspended}
                />
              )}
            />

            <Route exact path={AppRoutes.Forbidden} component={ForbiddenPage} />
            <Route exact path={AppRoutes.NotFound} component={ContentMissingPage} />
            <Route exact path={AppRoutes.WrongLink} component={ContentMissingPage} />
            <Route exact path={AppRoutes.ServerError} component={ContentMissingPage} />

            <Route exact path={AppRoutes.History} component={History} />
            <Route
              exact
              path={AppRoutes.Invites}
              render={(props) => (
                <Invites {...props} hasWriteAccess={hasWriteAccess} isAccountExpired={isAccountExpired} />
              )}
            />

            <Route exact path={AppRoutes.Support} render={(props) => <Support {...props} userEmail={userEmail} />} />
            <Route
              exact
              path={AppRoutes.AccountManagement}
              render={(props) => (
                <AccountManagement
                  {...props}
                  userId={userId}
                  numberOfUsers={selectedProject?.num_of_users}
                  maxUsers={selectedProject?.maxUsers ?? defaultMaxUsers}
                />
              )}
            />
            <Route exact path={AppRoutes.UserSettings} component={UserSettings} />
            <Route exact path={AppRoutes.Settings} component={Settings} />

            <Redirect from="*" to={AppRoutes.NotFound} />
          </Switch>
        </AppContent>
      </AppRoot>
    </>
  );
};

export default AppLayout;

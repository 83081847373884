import * as React from "react";
import { connect } from "react-redux";
import { createTheme, ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { LicenseManager } from "ag-grid-enterprise";

import { SetNotification } from "@monortc/front/actions/notificationAction";
import { APP_VERSION } from "./constants";
import Router from "./Router";
import SnackbarNotifier from "./containers/SnackbarNotifier";

LicenseManager.setLicenseKey(
  "CompanyName=testRTC Ltd,LicensedApplication=testRTC,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=1,AssetReference=AG-023260,ExpiryDate=10_December_2022_[v2]_MTY3MDYzMDQwMDAwMA==4a6261f0be8e2c2a4715fa12be21a119"
);

type AppDispatch = {
  setNotification(message: string, type?: string): void;
};

export type AppState = {
  error: Error | null;
};

export const AppContext = React.createContext({
  error: null,
  watchrtcNotifications: 0,
} as AppState);

const theme = createTheme({
  palette: {
    primary: {
      light: "#4CCBEB",
      main: "#06B6DD",
      dark: "#007E96",
      contrastText: "#ffffff",
    },
    text: { primary: "#243544", secondary: "rgba(36, 53, 68, 0.68)", disabled: "rgba(36, 53, 68, 0.38)" },
    error: {
      main: "#D32F2F",
      dark: "#a22a22",
    },
    info: { main: "#0BA5EC" },
    action: {
      active: "rgba(36, 53, 68, 0.54)",
      disabled: "rgba(36, 53, 68, 0.26)",
    },
    divider: "rgba(36, 53, 68, 0.12)",
  },

  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: ".8125rem",
        },
      },
    },
    MuiTableSortLabel: {
      styleOverrides: {
        icon: {
          height: 15,
          width: 15,
          marginLeft: 2,
          marginTop: 1,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: ({ theme }) => ({
          background: theme.palette.text.primary,
          fontSize: 10,
          lineHeight: "14px",
          fontWeight: 500,
          color: "#fff",
        }),
        arrow: ({ theme }) => ({ color: theme.palette.text.primary }),
      },
    },
  },
});

export class App extends React.Component<AppDispatch, AppState> {
  constructor(props: AppDispatch) {
    super(props);

    this.state = {
      error: null,
    };
  }

  componentDidMount() {
    console.info(APP_VERSION);
  }

  componentDidCatch(error: Error, _info: React.ErrorInfo) {
    this.setState({
      error: error,
    });
    this.props.setNotification("Unexpected error occurred. Please, contact support.");
  }

  public render() {
    return (
      <AppContext.Provider value={this.state}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Router />
            <SnackbarNotifier />
          </ThemeProvider>
        </StyledEngineProvider>
      </AppContext.Provider>
    );
  }
}

const mapDispatchToProps = (dispatch: any): AppDispatch => ({
  setNotification: (message: string) => dispatch(SetNotification(message)),
});

export default connect<any, AppDispatch, {}>(null, mapDispatchToProps)(App);

import * as React from "react";
import { RouteComponentProps } from "react-router";
import TestService from "../../services/TestService";
import View from "./View";
import withBreadcrumb, { WithBreadcrumb } from "../../components/withBreadcrumb";
import { axiosInstance } from "src/services/AxiosService";
import { AxiosResponse } from "axios";
import ApiPath from "src/constants/ApiPath";
import { AppRoutes } from "../../constants/routes/app-routes";
import { pushTestParentRoutes } from "../../components/withBreadcrumb/RoutesMap";
import AuthService from "../../services/AuthService";
import { reportError } from "src/services/ErrorService";
import { handleForbiddenError } from "../../helpers/errorHelper";
import { VM_DEDICATED_MEMORY, VM_PROBES_AMOUNT } from "../../constants/vm-probes";

export const TestIterationContext = React.createContext({
  test: null,
  testRun: null,
  testDefinition: null,
  testEvents: [],
  testIterations: [],
  updateTest: (_test: Test) => null,
  saveTestIteration: (_test: Test) => null,
  navigateToIteration: (_id: any) => null,
  isReport: false,
  freeReport: false,
  error: "",
} as TestIterationState);

export interface TestIterationProps {
  freeReport: boolean;
  setExpired: (flag: boolean) => void;
}

export interface TestIterationState {
  test: any;
  testRun: any;
  testDefinition: any;
  testEvents: Array<any>;
  testIterations: Array<any>;
  isReport: boolean;
  freeReport: boolean;
  updateTest: (test: any) => void;
  saveTestIteration: (test: any) => void;
  navigateToIteration: (index: number) => void;
  error: string;
}

type PerformanceSourceData = {
  browserCpu: number[];
  browserMemory: number[];
  probeCpu: number[];
  probeMemory: number[];
  probeMemoryMb: number[];
  /**
   * Index of first point which is bigger than test voiceStartTime.
   * Used to align chart on the same timeline with other ones
   */
  setupIndex: number;
  firstDataTime: number;
  step: number;
  voiceStartTime: number;
};

export interface PerformanceChartData {
  performanceByProbe: {
    cpu: Array<number | undefined>;
    memory: Array<number | undefined>;
  };
  performanceByBrowser: {
    cpu: Array<number | undefined>;
    memory: Array<number | undefined>;
  };
}

class TestIteration extends React.Component<
  TestIterationProps & RouteComponentProps<any> & WithBreadcrumb,
  TestIterationState
> {
  mounted = false;

  defaultState: TestIterationState = {
    test: null,
    testRun: null,
    testDefinition: null,
    testEvents: [],
    testIterations: [],
    isReport: false,
    freeReport: false,
    updateTest: this.updateTest.bind(this),
    saveTestIteration: this.saveTestIteration,
    navigateToIteration: this.navigateToIteration.bind(this),
    error: "",
  };

  constructor(props: TestIterationProps & RouteComponentProps<any> & WithBreadcrumb) {
    super(props);

    this.updateTest = this.updateTest.bind(this);
    this.fetchAllData = this.fetchAllData.bind(this);
    this.saveTestIteration = this.saveTestIteration.bind(this);

    this.state = {
      ...this.defaultState,
      freeReport: props.freeReport,
    };
  }

  componentDidMount() {
    this.mounted = true;
    this.props.breadcrumbs.reset();
    this.props.breadcrumbs.push("#", "Loading...");

    this.fetchAllData().then();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  transformPerformanceSourceData(data: PerformanceSourceData) {
    const totalProbeMemoryBytes = (VM_DEDICATED_MEMORY * 1024 * 1024 * 1024) / VM_PROBES_AMOUNT;
    const bytesToMBytes = (bytes: number) => {
      return bytes / 1048576;
    };
    const transformedData: PerformanceChartData = {
      performanceByProbe: { cpu: [], memory: [] },
      performanceByBrowser: { cpu: [], memory: [] },
    };

    data.setupIndex = data.setupIndex || 0;

    // slice (data.setupIndex) is done to adjust x axis data to start with test voice start time
    transformedData.performanceByProbe.cpu = (data.probeCpu || []).slice(data.setupIndex);
    transformedData.performanceByBrowser.cpu = (data.browserCpu || []).slice(data.setupIndex);

    if (data.probeMemoryMb && data.probeMemoryMb.length) {
      transformedData.performanceByProbe.memory = ((data.probeMemoryMb || []).slice(data.setupIndex) || []).map(
        (i) => i / VM_PROBES_AMOUNT
      );
    } else {
      transformedData.performanceByProbe.memory = (
        (data.probeMemory || []).slice(data.setupIndex) || []
      ).map((x: number) => bytesToMBytes((x / 100) * totalProbeMemoryBytes ?? 0));
    }

    transformedData.performanceByBrowser.memory = ((data.browserMemory || []).slice(data.setupIndex) || []).map(
      (x: number) => bytesToMBytes(x ? x * 1048576 : 0) ?? 0
    );

    return transformedData;
  }

  async fetchAllData(iterationId?: string, manual?: boolean) {
    const { breadcrumbs, history, match, freeReport } = this.props;
    const pathname = window.location.pathname;
    window.scrollTo(0, 0);

    const resultId = iterationId || match.params.objectId;

    if (manual) {
      this.setState({
        ...this.state,
        test: null,
        error: "",
      });
    }

    try {
      const [testResult, chartDataResult] = await Promise.all([
        freeReport ? this.getFreeReport(resultId) : this.getTestIteration(resultId),
        this.getTestIterationChartData(resultId, freeReport),
      ]);

      if (testResult.status === 403 || chartDataResult.status === 403) {
        return handleForbiddenError(this.props.history, testResult.data?.data);
      }
      if (testResult.status === 404 || chartDataResult.status === 404) {
        return this.props.history.push(AppRoutes.NotFound);
      }
      if (testResult.status === 400 || chartDataResult.status === 400) {
        this.setState({
          error:
            testResult.data.message ||
            chartDataResult.data.message ||
            "Something went wrong. Please check the link you are using.",
        });
        return;
      }

      const test = {
        ...testResult.data,
        chartData: { ...chartDataResult.data.chartData },
      };
      if (chartDataResult.data.performanceChartData) {
        test.performanceChartData = this.transformPerformanceSourceData(chartDataResult.data.performanceChartData);

        const chartSamplesStartTime = chartDataResult.data.performanceChartData.firstDataTime || 0;
        const chartSamplesEndTime = chartDataResult.data.performanceChartData.lastDataTime || 0;
        const performanceDataFrequency = chartDataResult.data.performanceChartData.step / 1000 || 1;

        test.performanceChartSamples = Math.round(
          (chartSamplesEndTime - chartSamplesStartTime) / (performanceDataFrequency * 1000)
        );
      }

      const testRun = manual ? this.state.testRun : await this.getTestRun(test.testRunId);
      const testDefinition = manual ? this.state.testDefinition : await this.getTestDefinition(testRun.testId);
      const testEvents = manual
        ? this.state.testEvents
        : test.testRunId
        ? await this.getTestIterationEvents(test.testRunId)
        : [];

      // set chart startUp time to minimum timestamp from globel event.
      const evts: any[] = testEvents.length > 0 ? testEvents : test.events;
      const dirtyEvents = evts.map((evt: any) => (evt.events ? evt.events : []));

      const events: { timestamp: any }[] = [];

      dirtyEvents.forEach((el: any) => {
        Array.prototype.push.apply(events, el);
      });

      // if no global event found, set current timestamp. which willl always miximum then setupStartTime.
      const initValue = { timestamp: new Date().getTime() };
      const firstEvent = events
        .filter((evt) => evt && evt.timestamp)
        .reduce(function (prev, curr) {
          return prev.timestamp < curr.timestamp ? prev : curr;
        }, initValue);

      test.stat.setupStartTime = Math.min(firstEvent?.timestamp, new Date(test.stat?.setupStartTime).getTime());

      const testIterations = manual ? this.state.testIterations : await this.getTestIterations(test.testRunId);

      const isReport = test.status === "analyze" || test.status === "upload";

      if (test.runName) {
        if (test.runName === "manual-upload") {
          pushTestParentRoutes(breadcrumbs, "manual-upload", test.fileName);
          if (!freeReport) {
            history.replace(AppRoutes.AnalyzeDumpResult + "/" + resultId);
          }
        } else {
          pushTestParentRoutes(breadcrumbs, testRun.runMode, test.runName, testRun._id, test.machine);
          if (testRun.runMode === "test" && pathname.includes("monitoring")) {
            history.replace(AppRoutes.TestIteration + "/" + resultId);
          } else if (testRun.runMode === "monitor" && pathname.includes("testing")) {
            history.replace(AppRoutes.MonitorAgentDetails + "/" + resultId);
          }
        }
      }

      if (this.mounted) {
        await this.setState({
          ...this.state,
          test,
          testRun,
          testDefinition,
          testEvents,
          testIterations,
          isReport,
          freeReport: freeReport,
          error: "",
        });
      }
    } catch (err) {
      if (err.response?.status === 404) {
        this.setState({
          error: "Not found",
        });
      } else if (err.response?.status === 401) {
        AuthService.setRedirectUrl(pathname);
        AuthService.logout("testrtc");
        history.push("/signin");
      } else if (err.response?.status === 403) {
        history.push(AppRoutes.Forbidden);
      } else {
        this.setState({
          error: "Couldn't load test iteration data",
        });
      }
      reportError(`Couldn't load test iteration data`, err);
    }
  }

  updateTest(test: any) {
    this.setState({
      test,
    });
  }

  async getTestIteration(iterationId: string) {
    return await TestService.getTestIteration(iterationId).catch((err: any) => err.response);
  }

  async getTestIterationChartData(iterationId: string, freeReport?: boolean) {
    return await TestService.getTestIterationChartData(iterationId, freeReport).catch((err: any) => err.response);
  }

  async getFreeReport(reportId: string) {
    try {
      const result: AxiosResponse = await axiosInstance
        .get(`${ApiPath.api.testIterations}/report/${reportId}`)
        .catch((err) => err.response);

      return result;
    } catch (err) {
      this.props.setExpired(true);
      throw err;
    }
  }

  async saveTestIteration(testIteration: Test) {
    try {
      const { data } = await TestService.saveTestIteration(testIteration);
      this.updateTest({
        ...this.state.test,
        ...data,
      });
    } catch (e) {
      // TODO: use global error handler
    }
  }

  async getTestRun(testId: string) {
    if (!testId) {
      return {
        testId: null,
      };
    }
    const res = await TestService.getTestRun(testId);
    if (res.status === 200) {
      return res.data;
    } else if (res.status === 403) {
      this.props.history.push(AppRoutes.Forbidden);
    } else {
      return false;
    }
  }

  async getTestDefinition(testId: string) {
    if (!testId) {
      return null;
    }
    try {
      const { data } = await TestService.getTestDefinition(testId);
      return data;
    } catch (e) {
      return false;
    }
  }

  async getTestIterationEvents(id: string) {
    const { data } = await TestService.getTestIterationEvents(id);
    return data;
  }

  async getTestIterations(id: string) {
    if (!id) {
      return null;
    }
    const { data } = await TestService.getMinimalTestIterations(id);
    return data;
  }

  navigateToIteration(index: number) {
    if (!index) {
      return;
    }

    const id = this.state.testIterations.find((iteration) => Number(iteration.idx) === index)?._id;
    this.props.history.push(`${AppRoutes.TestIteration}/${id}`);
    this.fetchAllData(id, true);
  }

  render() {
    return (
      <TestIterationContext.Provider value={this.state}>
        <View retry={() => this.fetchAllData(undefined, true)} {...this.state} />
      </TestIterationContext.Provider>
    );
  }
}

export default withBreadcrumb(TestIteration);

// import { useState } from "react";
import { Card, Typography, Toolbar, Divider, Table, TableBody, TableRow, TableCell } from "@mui/material";
import { ProjectPropertiesState } from "../";
import { ProjectPropertiesViewProps } from "../View";
import { Field } from "react-final-form";
import CheckboxControl from "src/components/form-elements/CheckboxControl";
import TextFieldControl from "src/components/form-elements/TextFieldControl";

const AnalyzeRTC = ({ classes }: ProjectPropertiesViewProps & ProjectPropertiesState) => {
  return (
    <Card className={classes.card} style={{ padding: 0 }}>
      <Toolbar>
        <Typography variant="subtitle1">analyzeRTC</Typography>
      </Toolbar>
      <Divider />
      <Table>
        <TableBody>
          <TableRow>
            <TableCell style={{ width: 220 }}>analyzeRTC Enable</TableCell>
            <TableCell>
              <Field component={CheckboxControl} type="checkbox" name="analyzertc_enable" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={{ width: 220 }}>Rules Engine Enabled</TableCell>
            <TableCell>
              <Field component={CheckboxControl} type="checkbox" name="analyzertc_rulesEngineEnable" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Urls</TableCell>
            <TableCell>
              <Field component={TextFieldControl} type="text" name="analyzertc_urls" />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Card>
  );
};

export default AnalyzeRTC;

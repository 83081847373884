import { Grid, Paper, Typography, Slide, Theme } from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";

import AnalyzerBanner from "../../../components/auth/AnalyzerBanner";
import LoginSocialButtons from "../../../components/auth/LoginSocialButtons";
import FooterColors from "../../../components/auth/FooterColors";
import SignupForm from "./SignupForm";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(5),
      position: "relative",
      paddingTop: 0,
    },
    orText: {
      margin: `${theme.spacing(2)} 0`,
      padding: "0 10px",
      textAlign: "center",
      textTransform: "none",
    },
    banner: {
      height: "auto",
    },
    bannerLink: {
      marginTop: 158,
      [theme.breakpoints.down("sm")]: {
        marginTop: 30,
      },
    },
    socialsButtonsContainer: {
      marginTop: 16,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",

      textTransform: "none",
    },
    title: {
      fontSize: "1.9rem",
      color: "white",
      fontWeight: 300,
      margin: 0,
      userSelect: "none",
    },
    titleContainer: {
      backgroundColor: theme.palette.primary.main,
      padding: "40px",
      paddingBottom: "10px",
      paddingTop: "20px",
      margin: "0 -40px",
      justifyContent: "center",
    },
  })
);

const SignUpPage = () => {
  const classes = useStyles();

  return (
    <Grid container={true} direction="row" justifyContent="center">
      <Grid item={true} xs={12} sm={6} md={5} lg={4}>
        <Slide direction="right" in={true} mountOnEnter={true} unmountOnExit={true}>
          <Paper className={classes.root} elevation={4}>
            <div className={classes.titleContainer}>
              <Typography className={classes.title} align="left" variant="h6">
                Signup on testRTC
              </Typography>
            </div>
            <div className={classes.socialsButtonsContainer}>
              <LoginSocialButtons />
            </div>
            <div className={classes.orText}>
              <Typography variant="body2">Or sign up using email</Typography>
            </div>
            <SignupForm />
            <FooterColors />
          </Paper>
        </Slide>
      </Grid>
      <Grid item={true} xs={12} sm={5} lg={4}>
        <AnalyzerBanner />
      </Grid>
    </Grid>
  );
};

export default SignUpPage;

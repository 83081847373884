// import { useState } from "react";
import {
  Card,
  Typography,
  Toolbar,
  Divider,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Grid,
  FormLabel,
} from "@mui/material";
import { ProjectPropertiesState } from "../";
import { ProjectPropertiesViewProps } from "../View";
import { Field } from "react-final-form";
import TextFieldControl from "src/components/form-elements/TextFieldControl";
import CheckboxControl from "src/components/form-elements/CheckboxControl";

const TestingRTC = ({ classes }: ProjectPropertiesViewProps & ProjectPropertiesState) => {
  return (
    <Card className={classes.card} style={{ padding: 0 }}>
      <Toolbar>
        <Typography variant="subtitle1">testingRTC</Typography>
      </Toolbar>
      <Divider />
      <Table
        sx={{
          width: "100%",
        }}
      >
        <TableBody>
          <TableRow>
            <TableCell>Block Test Runs On Negative Balance</TableCell>
            <TableCell>
              <Field component={CheckboxControl} type="checkbox" name="blockTestRunsOnNegativeBalance" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={{ width: 220 }}>Max Concurrent Probes</TableCell>
            <TableCell>
              <Grid item={true} xs={12} container={true} style={{ paddingBottom: 4 }}>
                <Grid item={true}>
                  <FormLabel className={classes.privilegesFieldLabel}>Audio</FormLabel>
                </Grid>
                <Grid item={true} className={classes.formFieldGridItem}>
                  <Field component={TextFieldControl} type="number" name="maxAudioSessions" />
                </Grid>
              </Grid>
              <Grid item={true} xs={12} container={true}>
                <Grid item={true}>
                  <FormLabel className={classes.privilegesFieldLabel}>Video</FormLabel>
                </Grid>
                <Grid item={true} className={classes.formFieldGridItem}>
                  <Field component={TextFieldControl} type="number" name="maxVideoSessions" />
                </Grid>
              </Grid>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Max Monthly Minutes</TableCell>
            <TableCell>
              <div>
                <Field
                  component={TextFieldControl}
                  type="number"
                  name="testingrtc_monthlyMinuteQuota"
                  style={{ width: 70 }}
                />
              </div>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Max Timeout</TableCell>
            <TableCell>
              <div>
                <Field component={TextFieldControl} type="number" name="testMaxTimeout" style={{ width: 70 }} />
              </div>
              <div>{"Minutes"}</div>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Test History</TableCell>
            <TableCell>
              <div>
                <Field component={TextFieldControl} type="number" name="testHistoryDaysBack" style={{ width: 70 }} />
              </div>
              <div>{"days back"}</div>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Max eval tests</TableCell>
            <TableCell>
              <Field component={TextFieldControl} type="number" name="max_EvaluationTestRunCount" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Performance Dashboard Results Limit</TableCell>
            <TableCell>
              <Field component={TextFieldControl} type="number" name="testingrtc_performanceChartMaxTests" />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Card>
  );
};

export default TestingRTC;

import { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, NavLink, useLocation } from "react-router-dom";
import { ReactSVG } from "react-svg";
import cn from "classnames";
import {
  Autocomplete,
  CircularProgress,
  ClickAwayListener,
  Drawer,
  IconButton,
  InputAdornment,
  Popover,
  Slide,
  TextField,
  Theme,
  Tooltip,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import {
  AccountCircleOutlined as AccountCircleOutlinedIcon,
  ArrowForwardIosOutlined as ArrowForwardIosOutlinedIcon,
  CloseOutlined as CloseOutlinedIcon,
  Search as SearchIcon,
} from "@mui/icons-material";

import AuthService from "@monortc/front/services/AuthService";
import { axiosInstance, isAxiosError } from "@monortc/front/services/AxiosService";
import { SetNotification } from "@monortc/front/actions/notificationAction";
import { ProjectsContext } from "@/containers/AppController";
import withAuthorization from "@/components/withAuthorization";
import agentAssureLogoSrc from "@/assets/images/logo-agent-assure.svg";
import agentAssureLogoCondensedSrc from "@/assets/images/logo-agent-assure-condensed.svg";
import houseIconSrc from "@/assets/images/icons/house.svg";
import historyIconSrc from "@/assets/images/icons/history.svg";
import messageIconSrc from "@/assets/images/icons/message.svg";
import documentIconSrc from "@/assets/images/icons/document.svg";
import planeIconSrc from "@/assets/images/icons/plane.svg";
import spearlineLogoSrc from "@/assets/images/logo-spearline.svg";
import spearlineLogoCondensedSrc from "@/assets/images/logo-spearline-condensed.svg";
import arrowCircledIconSrc from "@/assets/images/icons/arrow-circled.svg";
import accountIconSrc from "@/assets/images/icons/account.svg";
import settingsIconSrc from "@/assets/images/icons/settings.svg";
import exitIconSrc from "@/assets/images/icons/exit.svg";
import { AuthRoutes } from "@/constants/routes/auth-routes";
import { AppRoutes, ExternalLinks } from "@/constants/routes/app-routes";
import ApiPath from "@/constants/ApiPath";
import Popup from "@monortc/front/components/OneUI/Popup";

interface SidebarProps {
  isSuperAdmin: boolean;
  isProjectAdmin: boolean;
  isAlertVisible: boolean;
  isAccountExpired: boolean;
  userEmail: string | undefined;
}

export interface NavigationItem {
  name: string;
  link: string;
  isLinkExternal?: boolean;
  onClick?: () => void;
  Icon?: any;
  svgIcon?: boolean;
  hidden?: boolean;
  disabled?: boolean;
  child?: string[];
  items?: NavigationItem[];
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainMenuDrawer: {
      height: "100vh",
      position: "sticky",
      top: 0,
      backgroundColor: "#FFFFFF",
      zIndex: 11,
    },
    mainMenuDrawerAlertVisible: {
      height: "calc(100vh - 48px)",
    },
    mainMenuPaper: {
      width: 254,
      height: "100%",
      position: "relative",
      border: 0,
    },
    mainMenuPaperCollapsed: {
      width: 80,
    },
    mainMenuContainer: {
      width: 254,
      height: "100vh",
      position: "fixed",
      top: 0,
      display: "flex",
      flexDirection: "column",
    },
    mainMenuContainerAlertVisible: {
      height: "calc(100vh - 48px)",
      top: 48,
    },
    mainMenuContainerCollapsed: {
      width: 80,
    },
    logoLink: {
      display: "block",
      padding: "16px 24px",
    },
    logoLinkCollapsed: {
      margin: "0 auto",
      padding: "16px 0",
    },
    logoImg: {
      height: 28,

      "& svg": {
        height: "100%",
      },
    },

    mainNavigation: { flex: 1, overflow: "overlay" },
    generalNavigationList: {
      margin: 0,
      padding: "16px 0",
    },
    supportNavigationList: { margin: 0, padding: "16px 0" },
    separator: {
      width: "calc(100% - 14px)",
      height: 1,
      flex: "0 0 1px",
      margin: "0 7px",
      backgroundColor: theme.palette.divider,
    },
    separatorSimple: {
      width: "100%",
      height: 1,
      backgroundColor: theme.palette.divider,
    },
    navigationItem: { listStyleType: "none" },
    navigationItemLink: {
      width: "100%",
      height: 48,
      border: 0,
      padding: "0 32px",
      display: "flex",
      alignItems: "center",
      background: "none",
      cursor: "pointer",

      textDecoration: "none",

      transition: "background-color 0.2s ease",

      "&:hover": {
        backgroundColor: `${theme.palette.primary.main}0A`,
      },
    },
    navigationItemLinkCollapsed: {
      padding: 0,
      justifyContent: "center",
    },
    navigationItemSimpleLink: {
      width: "100%",
      height: 40,
      border: 0,
      padding: "0 16px",
      display: "flex",
      alignItems: "center",
      background: "none",
      cursor: "pointer",

      textDecoration: "none",

      transition: "background-color 0.2s ease",

      "&:hover": {
        backgroundColor: `${theme.palette.primary.main}0A`,
      },
    },
    navigationItemLinkActive: {
      backgroundColor: `${theme.palette.primary.main}14`,
    },
    tooltip: {
      "&&&": {
        marginTop: 4,
      },
    },
    navigationItemName: {
      margin: "0 0 0 16px",

      textAlign: "left",
      fontFamily: "Roboto, sans-serif",
      color: theme.palette.text.primary,
      fontSize: "16px",
      fontWeight: "400",
      lineHeight: 1,
      letterSpacing: "0.15px",
    },
    navigationItemSimpleName: {
      margin: "0 0 0 26px",

      textAlign: "left",
      fontFamily: "Roboto, sans-serif",
      color: theme.palette.text.primary,
      fontSize: "16px",
      fontWeight: "400",
      lineHeight: 1,
      letterSpacing: "0.15px",
    },
    navigationItemIcon: { width: 24, display: "flex", justifyContent: "center" },

    mainMenuFooter: {
      marginTop: 32,
      backgroundColor: "#fff",
    },
    userButton: {
      width: "100%",
      padding: "12px 18px 12px 32px",
      border: 0,
      display: "flex",
      alignItems: "center",
      background: "none",
      cursor: "pointer",

      transition: "background-color 0.2s ease",

      "&:hover": {
        backgroundColor: `${theme.palette.primary.main}0A`,
      },
    },
    userButtonActive: {
      backgroundColor: `${theme.palette.primary.main}14`,
    },
    userButtonCollapsed: {
      padding: "22px 0",
      justifyContent: "center",
    },
    userButtonIcon: {
      width: 24,

      color: theme.palette.action.active,
    },
    userButtonContent: {
      flex: 1,
      margin: "0 10px 0 16px",
      overflow: "hidden",
    },
    userButtonProjectName: {
      margin: 0,
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",

      textAlign: "left",
      fontFamily: "Roboto, sans-serif",
      color: theme.palette.text.primary,
      fontSize: 16,
      fontWeight: 400,
      lineHeight: 1.5,
      letterSpacing: "0.15px",
    },
    userButtonEmail: {
      margin: 0,
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",

      textAlign: "left",
      fontFamily: "Roboto, sans-serif",
      color: theme.palette.text.secondary,
      fontSize: 14,
      fontWeight: 400,
      lineHeight: 1.43,
      letterSpacing: "0.15px",
    },
    userButtonArrow: {
      width: 20,

      color: theme.palette.action.active,
    },
    userButtonArrowOpen: { transform: "rotate(180deg)" },
    userButtonArrowCollapsed: {},

    poweredByContainer: {
      position: "relative",
      padding: "24px 32px",
      display: "flex",
      alignItems: "center",
    },
    poweredByContainerCollapsed: {
      padding: "24px 0",
      justifyContent: "center",
    },
    poweredByText: { margin: "0 8px 0 0", color: theme.palette.text.secondary, fontSize: 12 },
    poweredByIcon: { width: 92.42, height: 20 },
    poweredByCollapsedIcon: {
      height: 20,

      "& svg": { height: 20 },
    },
    collapseButton: {
      position: "absolute",
      right: 0,
      top: "50%",
      transform: "translate(50%, -50%) rotate(180deg)",

      "& circle": { transition: "fill 0.2s ease" },
      "& path:first-of-type": { transition: "fill 0.2s ease" },
      "& path:last-of-type": { transition: "fill 0.2s ease" },

      "&:hover": {
        "& circle": { fill: theme.palette.primary.main },
        "& path:first-of-type": { fill: "#fff" },
        "& path:last-of-type": { fill: theme.palette.primary.main },
      },
    },
    collapseButtonCollapsed: { transform: "translate(50%, -50%)" },
    collapseIcon: {
      width: 24,
      height: 24,
    },

    userMenuDrawer: {
      width: 320,
      height: "100vh",
      position: "fixed",
      top: 0,
      left: 254,
      display: "flex",
      flexDirection: "column",
      backgroundColor: "#F2F4F6",
      zIndex: 10,
    },
    userMenuDrawerAlertVisible: {
      top: 48,
      height: "calc(100vh - 48px)",
    },
    userMenuDrawerCollapsed: {
      left: 80,
    },
    userMenuSimplePaper: {
      width: 260,
    },
    userMenuHeader: {
      margin: "16px 8px 0 24px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-start",
    },
    userMenuSimpleHeader: {
      margin: "20px 16px",
    },
    userMenuHeaderContent: { marginRight: 8 },
    userMenuProjectName: {
      margin: 0,

      fontSize: 16,
      fontWeight: 700,
      lineHeight: 1.25,
      letterSpacing: "0.15px",
      wordBreak: "break-all",
    },
    userMenuSimpleProjectName: {
      margin: 0,

      fontSize: 16,
      fontWeight: 400,
      lineHeight: 1.5,
      letterSpacing: "0.15px",
      wordBreak: "break-all",
    },
    userMenuEmail: {
      margin: "4px 0 0",

      fontSize: 14,
      fontWeight: 400,
      lineHeight: 1.43,
      color: theme.palette.text.secondary,
      letterSpacing: "0.15px",
      wordBreak: "break-all",
    },
    userMenuSimpleEmail: {
      margin: "-2px 0 0",

      fontSize: 14,
      fontWeight: 400,
      lineHeight: 1.43,
      color: theme.palette.text.secondary,
      letterSpacing: "0.15px",
      wordBreak: "break-all",
    },
    userMenuCloseButton: { marginTop: 6 },
    userMenuCloseIcon: {},
    userMenuProjectAutocompleteContainer: { margin: "24px 24px 16px", flex: 1 },
    userMenuProjectAutocompleteListItem: { wordBreak: "break-word" },
    userMenuNavigation: { margin: "32px 0", overflow: "overlay" },
    userMenuSimpleNavigation: { margin: "10px 0", overflow: "overlay" },
    userMenuAccountNavigationList: { margin: 0, padding: 0 },
    userMenuSimpleAccountNavigationList: { margin: 0, padding: 0 },
  })
);

function getNavigationBlocks(
  _project: Project | null | undefined,
  isSuperAdmin: boolean,
  isProjectAdmin: boolean,
  isAccountExpired: boolean
): Record<string, NavigationItem[]> {
  return {
    generalItems: [
      {
        name: "Home",
        link: AppRoutes.Main,
        Icon: houseIconSrc,
        svgIcon: true,
      },
      {
        name: "History",
        link: AppRoutes.History,
        Icon: historyIconSrc,
        svgIcon: true,
      },
      {
        name: "Invites",
        link: AppRoutes.Invites,
        Icon: messageIconSrc,
        svgIcon: true,
      },
    ],
    supportItems: [
      {
        name: "Documentation",
        link: ExternalLinks.Documentation,
        isLinkExternal: true,
        Icon: documentIconSrc,
        svgIcon: true,
      },
      {
        name: "Support",
        link: AppRoutes.Support,
        Icon: planeIconSrc,
        svgIcon: true,
      },
    ],
    accountItems: [
      {
        name: "Account Management",
        link: AppRoutes.AccountManagement,
        Icon: accountIconSrc,
        svgIcon: true,
        hidden: !isProjectAdmin || (isAccountExpired && !isSuperAdmin),
      },
      {
        name: "Settings",
        link: AppRoutes.Settings,
        Icon: settingsIconSrc,
        svgIcon: true,
        hidden: !isProjectAdmin || (isAccountExpired && !isSuperAdmin),
      },
    ],
  };
}

const sidebarCollapsePreferenceKeys = {
  desktop: "agent-assure-sidebar-collapse-preference-desktop",
  mobile: "agent-assure-sidebar-collapse-preference-mobile",
};

const sidebarCollapsePreferenceValues = {
  expanded: "expanded",
  collapsed: "collapsed",
};

const matchMediaMobile = window.matchMedia("(max-width: 600px)");

const getIsNavigationItemShown = (navigationItem: NavigationItem) => !navigationItem.hidden;

const Sidebar = ({ isSuperAdmin, isProjectAdmin, isAlertVisible, isAccountExpired, userEmail }: SidebarProps) => {
  const classes = useStyles();

  const location = useLocation();

  const dispatch = useDispatch();

  const { projects, selectedProject, setSelectedProject } = useContext(ProjectsContext);

  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(() => {
    let result: boolean;

    if (!matchMediaMobile.matches) {
      const preference = localStorage.getItem(sidebarCollapsePreferenceKeys.desktop);

      if (!preference || preference === sidebarCollapsePreferenceValues.expanded) {
        result = false;
      } else {
        result = true;
      }
    } else {
      const preference = localStorage.getItem(sidebarCollapsePreferenceKeys.mobile);

      if (!preference || preference === sidebarCollapsePreferenceValues.collapsed) {
        result = true;
      } else {
        result = false;
      }
    }

    return result;
  });

  const toggleIsSidebarCollapsed = () =>
    setIsSidebarCollapsed((currentIsSidebarCollapsed) => {
      const result = !currentIsSidebarCollapsed;

      localStorage.setItem(
        matchMediaMobile.matches ? sidebarCollapsePreferenceKeys.mobile : sidebarCollapsePreferenceKeys.desktop,
        result ? sidebarCollapsePreferenceValues.collapsed : sidebarCollapsePreferenceValues.expanded
      );

      return result;
    });

  const isUserMenuSimple = !isSuperAdmin && Number(projects?.length) <= 1;

  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);

  const toggleIsUserMenuOpen = () => setIsUserMenuOpen((currentIsUserMenuOpen) => !currentIsUserMenuOpen);

  const [userMenuSimpleAnchorEl, setUserMenuSimpleAnchorEl] = useState<HTMLButtonElement | null>(null);

  const openSimpleUserMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setUserMenuSimpleAnchorEl(event.currentTarget);
  };

  const closeSimpleUserMenu = () => {
    setUserMenuSimpleAnchorEl(null);
  };

  const handleClickAway = () => {
    if (userMenuSimpleAnchorEl) {
      closeSimpleUserMenu();
    }

    if (isUserMenuOpen) {
      setIsUserMenuOpen(false);
    }
  };

  const [projectSearchQuery, setProjectSearchQuery] = useState("");
  const [areSuperAdminProjectsLoading, setAreSuperAdminProjectsLoading] = useState(false);
  const [superAdminProjects, setSuperAdminProjects] = useState<Project[] | null>(null);

  useEffect(
    function fetchSuperAdminProjects() {
      let timeoutId: NodeJS.Timeout;

      if (isSuperAdmin) {
        if (projectSearchQuery) {
          timeoutId = setTimeout(async () => {
            setAreSuperAdminProjectsLoading(true);

            try {
              const response = await axiosInstance.get<Project[]>(ApiPath.api.projects.search, {
                params: {
                  s: projectSearchQuery,
                  assureAuth: true,
                },
              });

              if (response.data) {
                setSuperAdminProjects(response.data);
              }
            } catch (error: unknown) {
              if (isAxiosError<string | { message?: string }>(error)) {
                const errorMessage =
                  typeof error.response?.data === "string" ? error.response?.data : error.response?.data?.message;

                if (errorMessage) {
                  dispatch(SetNotification(errorMessage));
                }
              }
            }

            setAreSuperAdminProjectsLoading(false);
          }, 500);
        } else {
          setSuperAdminProjects(null);
        }
      }

      return () => {
        if (timeoutId) {
          clearTimeout(timeoutId);
        }
      };
    },
    [projectSearchQuery]
  );

  const getNavigationItemJsx = (isCollapsible = true, isSimple = false) => (navigationItem: NavigationItem) => {
    let resultJsx: JSX.Element;

    const shouldCollapse = isCollapsible && isSidebarCollapsed;

    const contentJsx = (
      <>
        <ReactSVG src={navigationItem.Icon} className={classes.navigationItemIcon} />
        {!isCollapsible || !isSidebarCollapsed ? (
          <p className={!isSimple ? classes.navigationItemName : classes.navigationItemSimpleName}>
            {navigationItem.name}
          </p>
        ) : null}
      </>
    );

    let linkJsx: JSX.Element;

    if (!navigationItem.link) {
      linkJsx = (
        <button
          onClick={() => {
            handleClickAway();

            if (navigationItem.onClick) {
              navigationItem.onClick();
            }
          }}
          className={
            !isSimple
              ? cn(classes.navigationItemLink, {
                  [classes.navigationItemLinkCollapsed]: shouldCollapse,
                })
              : classes.navigationItemSimpleLink
          }
        >
          {contentJsx}
        </button>
      );
    } else if (navigationItem.isLinkExternal) {
      linkJsx = (
        <a
          href={navigationItem.link}
          onClick={() => {
            if (navigationItem.onClick) {
              navigationItem.onClick();
            }
          }}
          target="_blank"
          rel="noreferrer"
          className={
            !isSimple
              ? cn(classes.navigationItemLink, {
                  [classes.navigationItemLinkCollapsed]: shouldCollapse,
                })
              : classes.navigationItemSimpleLink
          }
        >
          {contentJsx}
        </a>
      );
    } else {
      linkJsx = (
        <NavLink
          to={navigationItem.link}
          onClick={() => {
            handleClickAway();

            if (navigationItem.onClick) {
              navigationItem.onClick();
            }
          }}
          className={
            !isSimple
              ? cn(classes.navigationItemLink, {
                  [classes.navigationItemLinkCollapsed]: shouldCollapse,
                })
              : classes.navigationItemSimpleLink
          }
          activeClassName={classes.navigationItemLinkActive}
        >
          {contentJsx}
        </NavLink>
      );
    }

    if (shouldCollapse) {
      resultJsx = (
        <Tooltip title={navigationItem.name} classes={{ tooltip: classes.tooltip }} key={navigationItem.name}>
          <li className={classes.navigationItem}>{linkJsx}</li>
        </Tooltip>
      );
    } else {
      resultJsx = (
        <li className={classes.navigationItem} key={navigationItem.name}>
          {linkJsx}
        </li>
      );
    }

    return resultJsx;
  };

  const navigationBlocks = getNavigationBlocks(null, isSuperAdmin, isProjectAdmin, isAccountExpired);

  const generalItemsJsx = navigationBlocks.generalItems.filter(getIsNavigationItemShown).map(getNavigationItemJsx());
  const supportItemsJsx = navigationBlocks.supportItems.filter(getIsNavigationItemShown).map(getNavigationItemJsx());
  const accountItems = [
    ...navigationBlocks.accountItems,
    {
      name: "Logout",
      link: "",
      onClick: () => {
        AuthService.logout("agent-assure");
        document.location.pathname = AuthRoutes.SignIn;
      },
      Icon: exitIconSrc,
      svgIcon: true,
    },
  ].filter(getIsNavigationItemShown);

  const isAccountItemActive = navigationBlocks.accountItems.reduce(
    (accumulator, accountItem) => accumulator || accountItem.link === location.pathname,
    false
  );

  const handleUserButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (isUserMenuSimple) {
      openSimpleUserMenu(event);
    } else {
      toggleIsUserMenuOpen();
    }
  };

  const selectedProjectName = selectedProject?.name ?? "Loading...";

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <aside>
        <Drawer
          variant="permanent"
          open
          className={cn(classes.mainMenuDrawer, { [classes.mainMenuDrawerAlertVisible]: isAlertVisible })}
          classes={{
            paper: cn(classes.mainMenuPaper, { [classes.mainMenuPaperCollapsed]: isSidebarCollapsed }),
          }}
        >
          <div
            className={cn(classes.mainMenuContainer, {
              [classes.mainMenuContainerAlertVisible]: isAlertVisible,
              [classes.mainMenuContainerCollapsed]: isSidebarCollapsed,
            })}
          >
            <Link
              to={AppRoutes.Main}
              className={cn(classes.logoLink, { [classes.logoLinkCollapsed]: isSidebarCollapsed })}
            >
              {!isSidebarCollapsed ? (
                <ReactSVG className={classes.logoImg} src={agentAssureLogoSrc} />
              ) : (
                <ReactSVG className={classes.logoImg} src={agentAssureLogoCondensedSrc} />
              )}
            </Link>

            <nav className={classes.mainNavigation}>
              <ul className={classes.generalNavigationList}>{generalItemsJsx}</ul>

              <div className={classes.separator} />

              <ul className={classes.supportNavigationList}>{supportItemsJsx}</ul>
            </nav>

            <footer className={classes.mainMenuFooter}>
              <div className={classes.separator} />
              <button
                className={cn(classes.userButton, {
                  [classes.userButtonActive]: isAccountItemActive,
                  [classes.userButtonCollapsed]: isSidebarCollapsed,
                })}
                onClick={handleUserButtonClick}
              >
                <AccountCircleOutlinedIcon className={classes.userButtonIcon} />

                {!isSidebarCollapsed ? (
                  <>
                    <div className={classes.userButtonContent}>
                      <p className={classes.userButtonProjectName}>{selectedProjectName}</p>
                      {userEmail ? <p className={classes.userButtonEmail}>{userEmail}</p> : null}
                    </div>
                    <ArrowForwardIosOutlinedIcon
                      className={cn(classes.userButtonArrow, { [classes.userButtonArrowOpen]: isUserMenuOpen })}
                    />
                  </>
                ) : null}
              </button>
              <div className={classes.separator} />

              {!isSidebarCollapsed ? (
                <>
                  <Popup agentAssure />
                </>
              ) : null}
              <div className={classes.separator} />
              <div
                className={cn(classes.poweredByContainer, {
                  [classes.poweredByContainerCollapsed]: isSidebarCollapsed,
                })}
              >
                {!isSidebarCollapsed ? (
                  <>
                    <p className={classes.poweredByText}>Powered by</p>
                    <ReactSVG src={spearlineLogoSrc} className={classes.poweredByIcon} />
                  </>
                ) : (
                  <ReactSVG src={spearlineLogoCondensedSrc} className={classes.poweredByCollapsedIcon} />
                )}

                <IconButton
                  onClick={toggleIsSidebarCollapsed}
                  className={cn(classes.collapseButton, { [classes.collapseButtonCollapsed]: isSidebarCollapsed })}
                >
                  <ReactSVG src={arrowCircledIconSrc} className={classes.collapseIcon} />
                </IconButton>
              </div>
            </footer>
          </div>
        </Drawer>

        {!isUserMenuSimple ? (
          <Slide
            in={isUserMenuOpen}
            direction="right"
            mountOnEnter
            unmountOnExit
            className={cn(classes.userMenuDrawer, {
              [classes.userMenuDrawerAlertVisible]: isAlertVisible,
              [classes.userMenuDrawerCollapsed]: isSidebarCollapsed,
            })}
          >
            <div>
              <header className={classes.userMenuHeader}>
                <div className={classes.userMenuHeaderContent}>
                  <h1 className={classes.userMenuProjectName}>{selectedProjectName}</h1>
                  <h2 className={classes.userMenuEmail}>{userEmail}</h2>
                </div>

                <IconButton onClick={toggleIsUserMenuOpen} className={classes.userMenuCloseButton}>
                  <CloseOutlinedIcon className={classes.userMenuCloseIcon} />
                </IconButton>
              </header>

              <div className={classes.userMenuProjectAutocompleteContainer}>
                <Autocomplete
                  id="select-project"
                  options={superAdminProjects || (projects ?? [])}
                  filterOptions={isSuperAdmin ? (option) => option : undefined}
                  getOptionLabel={(option) => option.name}
                  renderOption={(props, option) => (
                    <li
                      {...props}
                      className={cn(props.className, classes.userMenuProjectAutocompleteListItem)}
                      key={option._id}
                    >
                      {option.name}
                    </li>
                  )}
                  onChange={(_event, value) => {
                    if (value && setSelectedProject) {
                      setSelectedProject(value._id);
                    }
                  }}
                  onInputChange={(_event, value) => {
                    setProjectSearchQuery(value);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Search by project name"
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <>
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                            {params.InputProps.startAdornment}
                          </>
                        ),
                        endAdornment: (
                          <>
                            {areSuperAdminProjectsLoading ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                  noOptionsText="No projects"
                  loading={!setSelectedProject || areSuperAdminProjectsLoading}
                />
              </div>

              <nav className={classes.userMenuNavigation}>
                <ul className={classes.userMenuAccountNavigationList}>
                  {accountItems.map(getNavigationItemJsx(false))}
                </ul>
              </nav>
            </div>
          </Slide>
        ) : (
          <Popover
            open={Boolean(userMenuSimpleAnchorEl)}
            onClose={closeSimpleUserMenu}
            anchorEl={userMenuSimpleAnchorEl}
            anchorOrigin={{
              vertical: "center",
              horizontal: !isSidebarCollapsed ? 240 : 66,
            }}
            marginThreshold={32}
            classes={{ paper: classes.userMenuSimplePaper }}
          >
            <header className={classes.userMenuSimpleHeader}>
              <h1 className={classes.userMenuSimpleProjectName}>{selectedProjectName}</h1>
              <h2 className={classes.userMenuSimpleEmail}>{userEmail}</h2>
            </header>

            <div className={classes.separatorSimple} />

            <nav className={classes.userMenuSimpleNavigation}>
              <ul className={classes.userMenuSimpleAccountNavigationList}>
                {accountItems.map(getNavigationItemJsx(false, true))}
              </ul>
            </nav>
          </Popover>
        )}
      </aside>
    </ClickAwayListener>
  );
};

export default withAuthorization(getNavigationBlocks)(Sidebar);

import { AppRoutes } from "./routes/app-routes";

const Names = {
  [AppRoutes.Main]: "Home",

  [AppRoutes.History]: "History",
  [AppRoutes.Invites]: "Invites",

  [AppRoutes.Forbidden]: "403 - Forbidden",
  [AppRoutes.NotFound]: "404 - Not found",
  [AppRoutes.WrongLink]: "400 - Wrong link",
  [AppRoutes.ServerError]: "500 - Server error",
} as const;

export default Names;

export function getLocationTitle(path: string): string {
  let title = "";
  for (const name in Names) {
    if (path.includes(name)) {
      title = Names[name];
    }
  }
  return title;
}

// import { useState } from "react";
import { Card, Typography, Toolbar, Divider, Table, TableBody, TableRow, TableCell } from "@mui/material";
import { Field } from "react-final-form";
import TextFieldControl from "src/components/form-elements/TextFieldControl";

import { Theme } from "@mui/material/styles";

import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      width: "100%",
      marginTop: theme.spacing(3),
      paddingBottom: 20,
      padding: 0,
    },
  })
);

const MetricsCustomizations = (_props: any) => {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <Toolbar>
        <Typography variant="subtitle1">Metrics customizations</Typography>
      </Toolbar>
      <Divider />
      <Table>
        <TableBody>
          <TableRow>
            <TableCell style={{ width: 220 }}>Chart Thresholds</TableCell>
            <TableCell>
              <Field component={TextFieldControl} type="text" multiline rows={10} name="charts_thresholds" resizeY={true}/>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={{ width: 220 }}>Ribbon Thresholds</TableCell>
            <TableCell>
              <Field component={TextFieldControl} type="text" multiline rows={10} name="ribbon_thresholds" resizeY={true}/>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={{ width: 220 }}>Metric Customisations (JSON)</TableCell>
            <TableCell>
              <Field component={TextFieldControl} type="text" multiline rows={10} name="metric_customisations" />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Card>
  );
};

export default MetricsCustomizations;

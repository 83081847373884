import { Redirect, Route, Switch } from "react-router";

import AsyncComponent from "../../components/asyncComponent";
import Navbar from "./components/Navbar";
import Sidebar from "./components/Sidebar";
import AppContent from "./components/AppContent";
import { AppLayoutState } from "./index";
import AppRoot from "./components/AppRoot";
import AppContentSpinner from "./components/AppContentSpinner";
import Loader from "../../components/Loader/Loader";
import AppController from "../AppController";
import { WatchRTCProvider } from "../WatchRTC/Context";
import Breadcrumb from "./components/Breadcrumb";
import { AppRoutes } from "../../constants/routes/app-routes";
import { RedirectRules } from "../../constants/routes/redirect-routes";
import AlertToolbar from "./components/AlertToolbar";
import { PRODUCT } from "../Trace/enums";

type ViewProps = {
  handleDrawerToggle: () => void;
};

const TestRunDetails = AsyncComponent(() => import("../TestRunDetails"));
const Logs = AsyncComponent(() => import("../LogsContainer"));

const AsyncQualityRTC = AsyncComponent(() => import("../QualityRTC"));
const AsyncQualityRTCInvites = AsyncComponent(() => import("../QualityRTCInvites"));
const AsyncQualityRTCInvite = AsyncComponent(() => import("../QualityRTCInvite"));
const AsyncNetworkTestCharts = AsyncComponent(() => import("../NetworkTestCharts"));
const AsyncOnPremiseProbes = AsyncComponent(() => import("../OnPremiseProbes"));
const AsyncOnPremiseProbesHistory = AsyncComponent(() => import("../OnPremiseProbesHistory"));
const AsyncOnPremiseProbeProperty = AsyncComponent(() => import("../OnPremiseProbeProperty"));
const AsyncOnPremiseProbeCharts = AsyncComponent(() => import("../OnPremiseProbeCharts"));
const AsyncOnPremiseProbesCompare = AsyncComponent(() => import("../OnPremiseProbesCompare"));
const AsyncOnPremiseProbeResults = AsyncComponent(() => import("../OnPremiseProbeResults"));
const UserAccounts = AsyncComponent(() => import("../Admin/UserAccounts"));
const UserDetails = AsyncComponent(() => import("../Admin/UserDetails"));
const Projects = AsyncComponent(() => import("../Admin/Projects"));
const ProjectProperties = AsyncComponent(() => import("../Admin/ProjectProperties"));
const Probes = AsyncComponent(() => import("../Admin/Probes"));
const UsageGraph = AsyncComponent(() => import("../Admin/UsageGraph"));
const NetworkTestBenchmarks = AsyncComponent(() => import("../Admin/NetworkTestBenchmarks"));
const NetworkTestBenchmarkDetails = AsyncComponent(() => import("../Admin/NetworkTestBenchmarks/Details.tsx"));
const AdminUtils = AsyncComponent(() => import("../Admin/AdminUtils"));
const BillingData = AsyncComponent(() => import("../Admin/BillingData"));
const BillingDataDetails = AsyncComponent(() => import("../Admin/BillingData/Details"));
const WatchRTCRoomHistory = AsyncComponent(() => import("../WatchRTCRoomHistory"));
const WatchRTCRoom = AsyncComponent(() => import("../WatchRTCRoom"));
const WatchRTCPeer = AsyncComponent(() => import("../WatchRTCPeer"));
const WatchRTCHighlights = AsyncComponent(() => import("../WatchRTCHighlights"));
const WatchRTCTrends = AsyncComponent(() => import("../WatchRTCTrends"));
const WatchRTCLive = AsyncComponent(() => import("../WatchRTCLive"));
const WatchRTCNotifications = AsyncComponent(() => import("../WatchRTCNotifications"));
const AnalyticsEvents = AsyncComponent(() => import("../Admin/AnalyticsEvents"));
const Settings = AsyncComponent(() => import("../Options/Settings"));
const DrillDownToTest = AsyncComponent(() => import("../DrillDownToTest"));
const WatchRTCRedirect = AsyncComponent(() => import("../WatchRTCRedirect"));
// const MyProfile = AsyncComponent(() => import("../Options/MyProfile"));
// const AccountBilling = AsyncComponent(() => import("../Options/AccountBilling"));
const Main = AsyncComponent(() => import("../Main/indexNew"));
const Tests = AsyncComponent(() => import("../Tests"));
const TestProperty = AsyncComponent(() => import("../TestProperty"));
const MonitoringProperty = AsyncComponent(() => import("../MonitoringProperty"));
const AssignToTest = AsyncComponent(() => import("../AssignToTest"));
const TestRunHistory = AsyncComponent(() => import("../TestRunHistory"));
const TestRunGroup = AsyncComponent(() => import("../TestRunGroup"));
const AnalyzeDump = AsyncComponent(() => import("../AnalyzeDump"));
const Assets = AsyncComponent(() => import("../Assets"));
const AgGridTest = AsyncComponent(() => import("../AgGridTest"));
const AssetProperty = AsyncComponent(() => import("../AssetProperty"));
const Monitoring = AsyncComponent(() => import("../Monitoring"));
const MonitorRunHistory = AsyncComponent(() => import("../MonitorRunHistory"));
const TestIteration = AsyncComponent(() => import("../TestIteration"));
const TestLogs = AsyncComponent(() => import("../TestLogs"));
const Support = AsyncComponent(() => import("../Support"));
const UserSettings = AsyncComponent(() => import("../UserSettings"));
const ForbiddenPage = AsyncComponent(() => import("../ForbiddenPage"));
const ContentMissingPage = AsyncComponent(() => import("../ContentMissingPage"));
const Trace = AsyncComponent(() => import("../Trace"));
const PerformanceDashboard = AsyncComponent(() => import("../PerformanceDashboard"));

const RedirectRoutes = Object.keys(RedirectRules).map((route) => (
  <Redirect key={route} exact from={RedirectRules[route].from} to={RedirectRules[route].to} />
));

export default (props: AppLayoutState & ViewProps) => (
  <AppController>
    <WatchRTCProvider>
      <AppRoot>
        <AppContentSpinner Loader={Loader} />
        <Navbar handleDrawerToggle={props.handleDrawerToggle} />
        <Sidebar {...props} />
        <AppContent>
          <AlertToolbar />
          <div style={{ position: "relative" }}>
            <Breadcrumb />
            <Switch>
              {RedirectRoutes}
              <Route exact={false} path={"/app/watchrtc/results"} component={WatchRTCRedirect} />
              <Route exact path={AppRoutes.AssignToTest} component={AssignToTest} />
              <Route exact path={AppRoutes.Main} component={Main} />
              <Route exact path={AppRoutes.TestingScripts} component={Tests} />
              <Route exact path={AppRoutes.TestRun} component={TestRunHistory} />
              <Route exact path={AppRoutes.MonitorList} component={Monitoring} />
              <Route exact path={AppRoutes.MonitorRun} component={MonitorRunHistory} />
              <Route exact path={AppRoutes.NewTestProperty} component={TestProperty} />
              <Route exact path={AppRoutes.Forbidden} component={ForbiddenPage} />
              <Route exact path={AppRoutes.NotFound} component={ContentMissingPage} />
              <Route exact path={AppRoutes.WrongLink} component={ContentMissingPage} />
              <Route exact path={AppRoutes.ServerError} component={ContentMissingPage} />
              <Route exact path={AppRoutes.NewMonitorProperty} component={MonitoringProperty} />
              <Route exact path={AppRoutes.TestPropertyId} component={TestProperty} />
              <Route exact path={AppRoutes.MonitorPropertyId} component={MonitoringProperty} />
              <Route exact path={AppRoutes.TestRunDetailsId} component={TestRunDetails} />
              <Route exact path={AppRoutes.MonitorRunDetailsId} component={TestRunDetails} />
              <Route exact path={AppRoutes.TestRunGroupId} component={TestRunGroup} />
              <Route exact path={AppRoutes.TestIterationTrace} render={() => <Trace product={PRODUCT.testingRTC} />} />
              <Route exact path={AppRoutes.TestIterationId} component={TestIteration} />
              <Route exact path={AppRoutes.MonitorAgentDetailsTrace} render={() => <Trace product={PRODUCT.upRTC} />} />
              <Route exact path={AppRoutes.MonitorAgentDetailsId} component={TestIteration} />
              <Route exact path={AppRoutes.AnalyzeDumpResultId} component={TestIteration} />
              <Route exact path={AppRoutes.TestLogsId} component={TestLogs} />
              <Route exact path={AppRoutes.QualityRTC} component={AsyncQualityRTC} />
              <Route exact path={AppRoutes.NetworkTestCharts} component={AsyncNetworkTestCharts} />
              <Route exact path={AppRoutes.QualityRTCInvites} component={AsyncQualityRTCInvites} />
              <Route exact path={AppRoutes.QualityRTCInviteNew} component={AsyncQualityRTCInvite} />
              <Route exact path={AppRoutes.QualityRTCInviteId} component={AsyncQualityRTCInvite} />
              <Route exact path={AppRoutes.OnPremiseProbe} component={AsyncOnPremiseProbes} />
              <Route exact path={AppRoutes.OnPremiseProbeHistoryId} component={AsyncOnPremiseProbesHistory} />
              <Route exact path={AppRoutes.NewOnPremiseProbeProperty} component={AsyncOnPremiseProbeProperty} />
              <Route exact path={AppRoutes.OnPremiseProbeChartsId} component={AsyncOnPremiseProbeCharts} />
              <Route exact path={AppRoutes.OnPremiseProbesCompareId} component={AsyncOnPremiseProbesCompare} />
              <Route exact path={AppRoutes.TestPropertyId} component={TestProperty} />
              <Route exact path={AppRoutes.OnPremiseProbePropertyId} component={AsyncOnPremiseProbeProperty} />
              <Route exact path={AppRoutes.OnPremiseProbeResultsId} component={AsyncOnPremiseProbeResults} />
              <Route exact path={AppRoutes.Assets} component={Assets} />
              <Route exact path={"/app/test-grid"} component={AgGridTest} />
              <Route exact path={AppRoutes.NewAssetProperty} component={AssetProperty} />
              <Route exact path={AppRoutes.AssetPropertyId} component={AssetProperty} />
              <Route exact path={AppRoutes.PerformanceDashboard} component={PerformanceDashboard} />

              <Route exact path={AppRoutes.WatchRTCHistory} component={WatchRTCRoomHistory} />
              <Route exact path={AppRoutes.WatchRTCHighlights} component={WatchRTCHighlights} />
              <Route exact path={AppRoutes.WatchRTCTrends} component={WatchRTCTrends} />
              <Route exact path={AppRoutes.WatchRTCLive} component={WatchRTCLive} />
              <Route exact path={AppRoutes.WatchRTCNotifications} component={WatchRTCNotifications} />

              <Route exact path={AppRoutes.AnalyzeDump} component={AnalyzeDump} />
              <Route path={AppRoutes.Logs} component={Logs} />
              <Route exact path={AppRoutes.Support} component={Support} />

              <Route exact path={AppRoutes.UserAccounts} component={UserAccounts} />
              <Route exact path={AppRoutes.UserDetailsId} component={UserDetails} />
              <Route exact path={AppRoutes.Projects} component={Projects} />
              <Route exact path={AppRoutes.ProjectPropertiesId} component={ProjectProperties} />
              <Route exact path={AppRoutes.Probes} component={Probes} />
              <Route exact path={AppRoutes.AdminUtils} component={AdminUtils} />
              <Route exact path={AppRoutes.UsageGraphs} component={UsageGraph} />

              <Route exact path={AppRoutes.NetworkTestBenchmark} component={NetworkTestBenchmarks} />
              <Route exact path={AppRoutes.NetworkTestBenchmarkId} component={NetworkTestBenchmarkDetails} />

              <Route exact path={AppRoutes.BillingData} component={BillingData} />
              <Route exact path={AppRoutes.BillingDataId} component={BillingDataDetails} />
              <Route exact path={AppRoutes.UserSettings} component={UserSettings} />

              <Route exact path={AppRoutes.WatchRTCRoomId} component={WatchRTCRoom} />
              <Route exact path={AppRoutes.WatchRTCPeerTrace} render={() => <Trace product={PRODUCT.watchRTC} />} />
              <Route
                exact
                path={AppRoutes.WatchRTCLivePeerTrace}
                render={() => <Trace product={PRODUCT.watchRTCLive} />}
              />
              <Route exact path={AppRoutes.WatchRTCPeerId} component={WatchRTCPeer} />
              <Route
                exact
                path={AppRoutes.WatchRTCLiveId}
                component={(props: any) => <WatchRTCPeer {...props} live />}
              />
              <Route exact path={AppRoutes.AnalyticsEvents} component={AnalyticsEvents} />
              <Route exact path={AppRoutes.Settings} component={Settings} />
              <Route exact path={AppRoutes.MyProfile} component={Settings} />
              <Route exact path={AppRoutes.AccountBilling} component={Settings} />

              <Route exact path={AppRoutes.DrillDownToTestId} component={DrillDownToTest} />
              <Redirect from="*" to={AppRoutes.NotFound} />
            </Switch>
          </div>
        </AppContent>
      </AppRoot>
    </WatchRTCProvider>
  </AppController>
);

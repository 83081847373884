import { ReactElement, useEffect, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { useDispatch } from "react-redux";
import { AxiosError, AxiosResponse } from "axios";
import { ReactSVG } from "react-svg";

import { FetchUser } from "../../actions/authAction";
import AuthService from "../../services/AuthService";
import { AuthRoutes } from "../../constants/routes/auth-routes";
import { reportError } from "src/services/ErrorService";
import { FrameRoutes } from "src/constants/routes/frame-routes";

interface IAuthProviderProps {
  loaderIcon: string | ReactElement;
  children?: any;
  authQueryParams?: Record<string, string | number | boolean>;
}

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    loaderContainer: {
      position: "fixed",
      left: "calc(50% - 64px)",
      top: "calc(50% - 64px)",
    },
  })
);

const validNotLogin = /(pricing|reset|confirm-account|analyze-log|shared|report\/free\/)/;

const AuthProvider = ({
  loaderIcon,
  children,
  authQueryParams,
  history,
  location: { pathname },
}: IAuthProviderProps & RouteComponentProps<{}>) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const authorize = () => dispatch(FetchUser(authQueryParams));

  const [loading, _setLoading] = useState(true);
  let mounted = false;

  const setLoading = (state: boolean) => {
    if (mounted) {
      _setLoading(state);
    }
  };

  useEffect(() => {
    mounted = true;
    fetchUser();
    return () => {
      mounted = false;
    };
  }, []);

  const fetchUser = async () => {
    const platform = authQueryParams?.assureAuth ? "agent-assure" : "testrtc";
    if (window.location.pathname.split("/")[1] === FrameRoutes.root.split("/")[1]) {
      if (
        window.location.hostname.split(".").slice(-2).join(".") !== "testrtc.com" &&
        !window.location.hostname.includes("staging") &&
        window.location.hostname !== "localhost"
      ) {
        AuthService.logout(platform);
      }

      return setLoading(false);
    } else {
      try {
        if (pathname.includes("confirm-account") && AuthService.loggedIn()) {
          AuthService.logout(platform);
          return setLoading(false);
        }

        AuthService.collectDocumentCookies();
        const result = ((await authorize()) as unknown) as AxiosResponse | AxiosError;

        if ((result as AxiosResponse).status !== 200) {
          if ((result as AxiosError).isAxiosError === true || (result as AxiosError).response?.status === 401) {
            reportError("Couldn't fetch user", result as any);
            AuthService.logout(platform);

            if (validNotLogin.test(pathname)) {
              return setLoading(false);
            }

            if (pathname !== AuthRoutes.SignIn) {
              AuthService.setRedirectUrl(window.location.pathname);
            }

            history.push(AuthRoutes.SignIn);
          }
        }

        const isSsoSuccess = AuthService.getIsSsoSuccess();
        const redirectUrl = AuthService.getRedirectUrl();

        if (isSsoSuccess) {
          if (redirectUrl) {
            history.push(redirectUrl);
          }

          AuthService.removeIsSsoSuccess(platform);
          AuthService.setRedirectUrl("");
        }

        return setLoading(false);
      } catch (err) {
        reportError("Couldn't fetch user", err);

        AuthService.logout(platform);

        if (validNotLogin.test(pathname)) {
          return setLoading(false);
        }

        if (pathname !== AuthRoutes.SignIn) {
          AuthService.setRedirectUrl(window.location.pathname);
        }

        history.push(AuthRoutes.SignIn);
        return setLoading(false);
      }
    }
  };

  let loaderJsx: JSX.Element;

  if (typeof loaderIcon === "string") {
    loaderJsx = (
      <div className={classes.loaderContainer}>
        <ReactSVG src={loaderIcon} />
      </div>
    );
  } else {
    loaderJsx = <div className={classes.loaderContainer}>{loaderIcon}</div>;
  }

  return loading ? loaderJsx : children;
};

export default withRouter(AuthProvider);

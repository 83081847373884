//http://jsfiddle.net/gh/get/jquery/3.1.1/highcharts/highcharts/tree/master/samples/maps/tooltip/usehtml/

import { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import HighchartsReact from "highcharts-react-official";
import * as Highcharts from "highcharts";
import highchartsMapModule from "highcharts/modules/map";
import highchartsExportingModule from "highcharts/modules/exporting";
import { IconButton, Skeleton, Theme, alpha, useTheme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { AddOutlined, RemoveOutlined, ZoomInMapOutlined, ZoomOutMapOutlined } from "@mui/icons-material";
import { useBoolean } from "usehooks-ts";
import { debounce } from "lodash";
import cn from "classnames";

import { worldJson } from "./world";

export interface MapLocation {
  name: string;
  countryCode: string | undefined;
  lat: number;
  lon: number;
  count?: number;
}

export interface MapProps {
  locations?: (MapLocation | null)[];
  remoteLocations?: (MapLocation | null)[];
}

highchartsMapModule(Highcharts);
highchartsExportingModule(Highcharts);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      minHeight: 160,
      height: "100%",
      position: "relative",
    },
    preloader: { height: "100%" },
    actionsContainer: { position: "absolute", bottom: 16, right: 16 },
    actionButton: {
      display: "block",

      "&:after": {
        content: '""',
        display: "block",
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        borderRadius: "6px",
        backgroundColor: "#FFF",
        zIndex: 0,
      },

      "& .MuiTouchRipple-root": {
        zIndex: 1,
      },
    },
    fullscreenButton: {
      width: 28,
      height: 28,
      marginBottom: 20,
      padding: 1,
      border: `1px solid ${theme.palette.action.disabled}`,
      borderRadius: "6px",

      "& .MuiTouchRipple-root .MuiTouchRipple-child": {
        borderRadius: "6px",
      },
    },
    zoomInButton: {
      width: 28,
      height: 29,
      display: "block",
      padding: "1px 1px 2px 1px",
      border: `1px solid ${theme.palette.action.disabled}`,
      borderBottomColor: theme.palette.divider,
      borderRadius: "6px 6px 0 0",
      backgroundColor: "#FFF",

      "& .MuiTouchRipple-root .MuiTouchRipple-child": {
        borderRadius: "6px 6px 0 0",
      },
    },
    zoomOutButton: {
      width: 28,
      height: 28,
      display: "block",
      padding: "2px 1px 1px 1px",
      border: `1px solid ${theme.palette.action.disabled}`,
      borderTopWidth: 0,
      borderRadius: "0 0 6px 6px",

      "& .MuiTouchRipple-root .MuiTouchRipple-child": {
        borderRadius: "0 0 6px 6px",
      },
    },
    buttonIcon: {
      position: "relative",
      zIndex: 1,
    },
  })
);

const Map = ({ locations = [], remoteLocations = [] }: MapProps) => {
  const classes = useStyles();
  const theme = useTheme();

  const containerRef = useRef<HTMLDivElement>(null);
  const mapRef = useRef<{ chart: Highcharts.MapChart; container: React.RefObject<HTMLDivElement> }>(null);
  const actionsContainerRef = useRef<HTMLDivElement>(null);

  const { value: isLoaded, setValue: setIsLoaded } = useBoolean(false);
  const [containerHeight, setContainerHeight] = useState<string | number>("100%");
  const [connectionsData, setConnectionsData] = useState<Highcharts.SeriesMaplineDataOptions[]>([]);
  const { value: isFullscreen, setValue: setIsFullscreen } = useBoolean();

  const filteredLocations: MapLocation[] = [];
  const filteredRemoteLocations: MapLocation[] = [];

  for (const location of locations) {
    if (
      location &&
      !filteredLocations.find(function isNameUnique(existingLocation) {
        return location.name === existingLocation.name;
      })
    ) {
      filteredLocations.push(location);
    }
  }

  for (const location of remoteLocations) {
    if (
      location &&
      !filteredRemoteLocations.find(function isNameUnique(existingLocation) {
        return location.name === existingLocation.name;
      })
    ) {
      filteredRemoteLocations.push(location);
    }
  }

  const allLocations = [...filteredLocations, ...filteredRemoteLocations];

  const getLocationNameFromLatLong = (location: MapLocation) => {
    return allLocations.find((i) => i.lat === location.lat && i.lon === location.lon)?.name || location;
  };

  function pointsToPath(fromPoint: MapLocation, toPoint: MapLocation) {
    const chart = mapRef?.current?.chart;

    if (chart && chart.mapView) {
      const from = chart.mapView.lonLatToProjectedUnits(fromPoint),
        to = chart.mapView.lonLatToProjectedUnits(toPoint),
        curve = 0.13,
        arcPointX = (from.x + to.x) / (2 - curve),
        arcPointY = (from.y + to.y) / (2 - curve);
      return [
        ["M", from.x, from.y],
        ["Q", arcPointX, arcPointY, to.x, to.y],
      ];
    } else {
      return null;
    }
  }

  useLayoutEffect(function initConnectionsData() {
    if (locations.length && remoteLocations.length) {
      const newConnectionsData: Highcharts.SeriesMaplineDataOptions[] = [];

      for (let i = 0; i < locations.length; i += 1) {
        const location = locations[i];
        const remoteLocation = remoteLocations[i];

        if (location && remoteLocation) {
          newConnectionsData.push({
            name: `${getLocationNameFromLatLong(remoteLocation)} - ${getLocationNameFromLatLong(location)}`,
            path: pointsToPath(remoteLocation, location)?.toString(),
            color: theme.palette.text.primary,
          });
        }
      }

      setConnectionsData(newConnectionsData);
    }
  }, []);

  const mapOptions = useMemo<Highcharts.Options>(() => {
    const nonFilteredAllLocations = [...locations, ...remoteLocations].filter((x) => x !== null);
    const filteredAllLocations: MapLocation[] = [];

    const locationKey = "name";
    const groupLocations = nonFilteredAllLocations.reduce(function (r: any, a: any) {
      r[a[locationKey]] = r[a[locationKey]] || [];
      r[a[locationKey]].push(a);
      return r;
    }, Object.create(null));

    const localLocationsCount = (
      locationKey: string,
      locations: (MapLocation | null)[],
      filterAllLocations: (MapLocation | null)[],
      groupLocations: MapLocation,
      data: MapLocation
    ) => {
      for (let i = 0; i < locations.length; i++) {
        const location = locations[i];

        const isAlreadyAdded = filterAllLocations.some((loc) => loc?.lat === data?.lat && loc?.lon === data?.lon);
        if (isAlreadyAdded) {
          return false;
        }

        if (data?.lat === location?.lat && data?.lon === location?.lon) {
          filterAllLocations.push({
            ...data,
            count: groupLocations[data[locationKey]].length,
          });
          return true;
        }
      }

      return false;
    };

    for (const value of Object.values(groupLocations || {}) as any) {
      if (value.length > 1) {
        value.forEach((data: any) => {
          if (data.lat === remoteLocations[0]?.lat && data?.long === remoteLocations[0]?.lon) {
            filteredAllLocations?.push({
              ...data,
              count: groupLocations[data[locationKey]].length,
            });
          } else if (!localLocationsCount(locationKey, locations, filteredAllLocations, groupLocations, data)) {
            const findIndex = filteredAllLocations.findIndex(
              (locations) => locations[locationKey] === data[locationKey]
            );
            if (findIndex === -1) {
              filteredAllLocations.push({
                ...data,
                count: groupLocations[data[locationKey]].length,
              });
            }
          }
          return null;
        });
      } else if (value.length === 1) {
        filteredAllLocations.push({
          ...value[0],
          count: groupLocations[value[0][locationKey]].length,
        });
      }
    }

    const countryCodes = new Set<string>();
    for (const location of allLocations) {
      if (location.countryCode) {
        countryCodes.add(location.countryCode.toLowerCase());
      }
    }
    const getIsRemote = (location: MapLocation) =>
      Boolean(filteredRemoteLocations.find((remoteLocation) => remoteLocation.name === location.name));

    return {
      chart: {
        map: worldJson,
        spacing: [0, 0, 0, 0],
        style: { fontFamily: "Roboto, sans-serif" },
        animation: false,
      },
      navigation: {
        buttonOptions: {
          enabled: false,
        },
      },
      mapNavigation: {
        enabled: true,
        enableButtons: false,
      },
      title: {
        text: undefined,
      },
      accessibility: {
        description: "Map where city locations have been defined using latitude/longitude.",
      },
      tooltip: {
        formatter: function (this: any) {
          return this.point.count
            ? `<b>${this.point.name} (${this.point.count || 1})</b><br>Lat: ${this.point.lat}, Lon: ${this.point.lon}`
            : `<b>${this.point.name}</b><br>`;
        },
      },
      legend: {
        floating: true,
        align: "left",
        x: 12,
        y: -12,
        padding: 4,
        itemDistance: 8,
        itemMarginTop: 3,
        itemMarginBottom: 3,
        symbolPadding: 2,
        backgroundColor: "rgba(255, 255, 255, 0.75)",
      },
      plotOptions: {
        mappoint: {
          dataLabels: {
            format: "",
          },
          accessibility: {
            point: {
              valueDescriptionFormat: "{xDescription}. Lat: {point.lat:.2f}, lon: {point.lon:.2f}.",
            },
          },
          marker: {
            symbol: "circle",
            radius: 5,
            lineWidth: 1,
          },
          zIndex: 1,
        },
        mapline: {
          zIndex: 0,
        },
      },
      series: [
        {
          type: "map",
          name: "World",
          data: Array.from(countryCodes).map((countryCode) => [countryCode, 1]),
          showInLegend: false,
          color: theme.palette.action.disabled,
          nullColor: theme.palette.action.focus,
          borderColor: "#FFF",
        },
        {
          type: "mappoint",
          name: "Peers",
          data: filteredAllLocations.filter((location) => !getIsRemote(location)),
          marker: {
            fillColor: alpha("#458DCE", 0.54),
            lineColor: "#458DCE",
          },
        },
        {
          type: "mappoint",
          name: "Servers/Remote",
          data: filteredAllLocations.filter(getIsRemote),
          marker: {
            fillColor: theme.palette.action.active,
            lineColor: theme.palette.text.primary,
          },
        },
        {
          type: "mapline",
          name: "Connections",
          data: connectionsData,
          tooltip: {
            pointFormat: "{point.name}",
          },
          showInLegend: false,
          lineWidth: 1,
          dashStyle: "Dash",
        },
      ],
    };
  }, [locations, remoteLocations, connectionsData]);

  useLayoutEffect(
    function appendActionsToHighchartsContainer() {
      if (actionsContainerRef.current) {
        mapRef.current?.container?.current?.append(actionsContainerRef.current);
      }
    },
    [isFullscreen]
  );

  useEffect(function syncFullscreenState() {
    const handleFullscreen = () => {
      if (document.fullscreenElement === mapRef.current?.container.current) {
        setIsFullscreen(true);
      } else {
        setIsFullscreen(false);
      }
    };

    document.addEventListener("fullscreenchange", handleFullscreen);

    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreen);
    };
  }, []);

  useEffect(function handleLoadAndResize() {
    const updateChartSize = () => {
      setContainerHeight(containerRef.current?.clientHeight ?? "100%");

      setTimeout(() => {
        mapRef.current?.chart.setSize(null, null, false);
        if (mapRef.current?.chart.options.chart) {
          mapRef.current.chart.options.chart.animation = true;
        }
      }, 500);
    };
    const debouncedUpdateChartSize = debounce(updateChartSize, 200);

    setTimeout(() => {
      setIsLoaded(true);

      updateChartSize();
      window.addEventListener("resize", debouncedUpdateChartSize);
    }, 100);

    return () => {
      window.removeEventListener("resize", debouncedUpdateChartSize);
    };
  }, []);

  return (
    <div ref={containerRef} id="mapChart" className={classes.container}>
      {!isLoaded ? (
        <Skeleton variant="rectangular" className={classes.preloader} />
      ) : (
        <>
          <HighchartsReact
            ref={mapRef}
            highcharts={Highcharts}
            constructorType="mapChart"
            options={mapOptions}
            containerProps={{ style: { height: containerHeight } }}
          />

          <div ref={actionsContainerRef} className={classes.actionsContainer}>
            <IconButton
              onClick={() => {
                mapRef.current?.chart.fullscreen.toggle();
              }}
              className={cn(classes.actionButton, classes.fullscreenButton)}
            >
              {!isFullscreen ? (
                <ZoomOutMapOutlined className={classes.buttonIcon} />
              ) : (
                <ZoomInMapOutlined className={classes.buttonIcon} />
              )}
            </IconButton>

            <IconButton
              onClick={() => mapRef.current?.chart.mapView?.zoomBy(0.75)}
              className={cn(classes.actionButton, classes.zoomInButton)}
            >
              <AddOutlined className={classes.buttonIcon} />
            </IconButton>
            <IconButton
              onClick={() => mapRef.current?.chart.mapView?.zoomBy(-0.75)}
              className={cn(classes.actionButton, classes.zoomOutButton)}
            >
              <RemoveOutlined className={classes.buttonIcon} />
            </IconButton>
          </div>
        </>
      )}
    </div>
  );
};

export default Map;

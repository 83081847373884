// import { useState } from "react";
import {
  Button,
  Card,
  Divider,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Toolbar,
  Typography,
} from "@mui/material";
import { ProjectPropertiesState } from "../";
import { ProjectPropertiesViewProps } from "../View";
import { Field } from "react-final-form";
import TextFieldControl from "src/components/form-elements/TextFieldControl";
import CheckboxControl from "src/components/form-elements/CheckboxControl";

import DataStreamConfigurationCard, { Products } from "./DataStream";
import { InviteLockingPolicies } from "../constants/qualityRTC";

interface IQualityRTCProps {
  setOptions: (options: string) => void;
  setActiveJobs: (options: string) => void;
  values: any;
}

export const PrivacyActions = {
  remove: "remove",
  keep: "keep",
  obfuscate: "obfuscate",
};

const QualityRTC = ({
  classes,
  setOptions,
  setActiveJobs,
  values,
}: IQualityRTCProps & ProjectPropertiesViewProps & ProjectPropertiesState) => {
  const onBeautifyClick = () => {
    try {
      const json = JSON.parse(values.qualityrtc_options);
      setOptions(JSON.stringify(json, null, 2));
    } catch {
      //
    }
  };
  return (
    <>
      <Card className={classes.card} style={{ padding: 0 }}>
        <Toolbar>
          <Typography variant="subtitle1">qualityRTC</Typography>
        </Toolbar>
        <Divider />
        <Table>
          <TableBody>
            <TableRow>
              <TableCell style={{ width: 220 }}>qualityRTC Enabled</TableCell>
              <TableCell>
                <Field component={CheckboxControl} type="checkbox" name="qualityrtc_enable" />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ width: 220 }}>Rules Engine Enabled</TableCell>
              <TableCell>
                <Field component={CheckboxControl} type="checkbox" name="qualityrtc_rulesEngineEnable" />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ width: 220 }}>Scoring Enabled</TableCell>
              <TableCell>
                <Field component={CheckboxControl} type="checkbox" name="qualityrtc_scoringEnable" />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ width: 220 }}>Retention Period</TableCell>
              <TableCell>
                <Field component={TextFieldControl} type="number" name="qualityrtc_retentionPeriod" min={1} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ width: 220 }}>Max Monthly Network Tests</TableCell>
              <TableCell>
                <Field component={TextFieldControl} type="number" name="qualityrtc_maxNetworkTests" />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Advanced Analytic</TableCell>
              <TableCell>
                <Field component={CheckboxControl} type="checkbox" name="qualityrtc_advanceAnalytic" />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Webhook Enable</TableCell>
              <TableCell>
                <Field component={CheckboxControl} type="checkbox" name="qualityrtc_webhook_enable" />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ width: 220 }}>Support account reporting</TableCell>
              <TableCell>
                <Field component={CheckboxControl} type="checkbox" name="qualityrtc_support_account_reporting" />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ width: 220 }}>Invites Enabled</TableCell>
              <TableCell>
                <Field component={CheckboxControl} type="checkbox" name="qualityrtc_inviteEnable" />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ width: 220 }}>Invite Locking Policy</TableCell>
              <TableCell>
                <Field
                  component={TextFieldControl}
                  select={true}
                  SelectProps={{ autoWidth: true }}
                  name="qualityrtc_inviteLockingPolicy"
                  variant="standard"
                >
                  {Object.keys(InviteLockingPolicies).map((key: any, i: number) => (
                    <MenuItem key={i} value={InviteLockingPolicies[key]}>
                      {InviteLockingPolicies[key]}
                    </MenuItem>
                  ))}
                </Field>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ width: 220 }}>Invites Purge Time</TableCell>
              <TableCell>
                <Field component={TextFieldControl} type="number" name="qualityrtc_invitePurgeTime" />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ width: 220 }}>Max Invite Duration (days)</TableCell>
              <TableCell>
                <Field component={TextFieldControl} type="number" name="qualityrtc_maxInviteDuration" />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ width: 220 }}>Base URL (customer's website url)</TableCell>
              <TableCell>
                <Field component={TextFieldControl} type="text" name="qualityrtc_baseURL" />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ width: 220 }}>Extra Fields</TableCell>
              <TableCell>
                <Field component={TextFieldControl} type="text" name="qualityrtc_extraFields" />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ width: 220 }}>Show History Columns</TableCell>
              <TableCell>
                <Field
                  component={TextFieldControl}
                  type="text"
                  name="qualityrtc_showHistoryColumns"
                  multiline={true}
                  rows="3"
                  resizeY={true}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ width: 220 }}>Options (JSON)</TableCell>
              <TableCell>
                <Field
                  component={TextFieldControl}
                  type="text"
                  name="qualityrtc_options"
                  multiline={true}
                  rows="5"
                  resizeY={true}
                />
                <Button variant="contained" style={{ marginTop: 5 }} onClick={onBeautifyClick}>
                  {"beautify"}
                </Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Card>
      <Card className={classes.card} style={{ padding: 0 }}>
        <Toolbar>
          <Typography variant="subtitle1">qualityRTC Privacy</Typography>
        </Toolbar>
        <Divider />
        <Table>
          <TableBody>
            <TableRow>
              <TableCell style={{ width: 220 }}>PII Removal interval ( days )</TableCell>
              <TableCell>
                <Field component={TextFieldControl} min={1} type="number" name="qualityrtc_privacy.interval" />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ width: 220 }}>On PII Removal Event Action</TableCell>
              <TableCell>
                <Field
                  component={TextFieldControl}
                  select={true}
                  SelectProps={{ autoWidth: true }}
                  name="qualityrtc_privacy.onRemove"
                  variant="standard"
                >
                  {Object.keys(PrivacyActions).map((key: any, i: number) => (
                    <MenuItem key={i} value={PrivacyActions[key]}>
                      {PrivacyActions[key]}
                    </MenuItem>
                  ))}
                </Field>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ width: 220 }}>On Test Save Action</TableCell>
              <TableCell>
                <Field
                  component={TextFieldControl}
                  select={true}
                  SelectProps={{ autoWidth: true }}
                  name="qualityrtc_privacy.onStore"
                  variant="standard"
                >
                  {Object.keys(PrivacyActions).map((key: any, i: number) => (
                    <MenuItem key={i} value={PrivacyActions[key]}>
                      {PrivacyActions[key]}
                    </MenuItem>
                  ))}
                </Field>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Card>
      <DataStreamConfigurationCard
        classes={classes}
        values={values}
        product={Products.quality}
        setActiveJobs={setActiveJobs}
      />
    </>
  );
};

export default QualityRTC;

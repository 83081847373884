import { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Divider,
  IconButton,
  Theme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { ExportFormat } from "../types";
import { createStyles, makeStyles } from "@mui/styles";

interface ExportDialogProps {
  format: ExportFormat | undefined;
  retentionPeriodDays: number;
  rowLimit: number;
  columnNames: string[];
  doExport: (format: ExportFormat) => Promise<boolean>;
  closeDialog: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogPaper: { maxWidth: 680, width: "100%" },
    dialogTitle: { position: "relative" },
    closeIconButton: {
      position: "absolute",
      right: 8,
      top: "50%",
      transform: "translateY(-50%)",
      color: theme.palette.grey[500],
    },
    rowLimitHeading: {},
    columnListHeading: { margin: "10px 0 0" },
    columnListWrapper: {
      margin: "8px 0 0",
      display: "flex",
      columnGap: 42,
      flexWrap: "wrap",
    },
    columnList: {
      margin: "16px 0 0",
      padding: "0 0 0 18px",
      display: "flex",
      flexDirection: "column",
      rowGap: 16,
    },
    columnListItem: {
      flex: 0,
      fontSize: 14,
      lineHeight: "16px",
    },
  })
);

const ExportDialog = ({
  format,
  retentionPeriodDays,
  rowLimit,
  columnNames,
  doExport,
  closeDialog,
}: ExportDialogProps) => {
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(false);
  const [savedFormat, setSavedFormat] = useState(format);

  useEffect(() => {
    if (format) {
      setSavedFormat(format);
    }
  }, [format]);

  const handleExport = async () => {
    if (format) {
      setIsLoading(true);

      const result = await doExport(format);

      if (result) {
        closeDialog();
      }

      setIsLoading(false);
    }
  };

  const displayFormat = (format ?? savedFormat)?.toUpperCase();

  return (
    <Dialog
      open={Boolean(format)}
      onClose={closeDialog}
      aria-labelledby="export-dialog"
      classes={{ paper: classes.dialogPaper }}
    >
      <DialogTitle className={classes.dialogTitle}>
        Download {displayFormat}
        <IconButton onClick={closeDialog} aria-label="close" className={classes.closeIconButton}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Typography variant="body1" className={classes.rowLimitHeading}>
          Up to {rowLimit} records from the last {retentionPeriodDays} days will be downloaded
        </Typography>
        <Typography variant="body1" className={classes.columnListHeading}>
          The {displayFormat} will include the following columns:
        </Typography>

        <div className={classes.columnListWrapper}>
          <ul className={classes.columnList}>
            {columnNames.slice(0, Math.ceil(columnNames.length / 3)).map((columnName) => (
              <li className={classes.columnListItem} key={columnName}>
                {columnName}
              </li>
            ))}
          </ul>
          <ul className={classes.columnList}>
            {columnNames
              .slice(Math.ceil(columnNames.length / 3), Math.ceil(columnNames.length / 3) * 2)
              .map((columnName) => (
                <li className={classes.columnListItem} key={columnName}>
                  {columnName}
                </li>
              ))}
          </ul>
          <ul className={classes.columnList}>
            {columnNames.slice(Math.ceil(columnNames.length / 3) * 2).map((columnName) => (
              <li className={classes.columnListItem} key={columnName}>
                {columnName}
              </li>
            ))}
          </ul>
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={closeDialog} color="textSecondary">
          Cancel
        </Button>
        <Button onClick={handleExport} disabled={isLoading} variant="contained">
          Download
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ExportDialog;

import { AxiosError as Error, AxiosResponse as Response } from "axios";
import { axiosInstance } from "./AxiosService";
import ApiPath from "../constants/ApiPath";

export default class TestService {
  static getTestRun(testId: string) {
    return axiosInstance
      .get(`${ApiPath.api.testRuns}/show/${testId}`)
      .then((res: Response) => res)
      .catch(({ response }: Error) => response as Response);
  }

  static getTestStats(testId: string) {
    return axiosInstance
      .get(`${ApiPath.api.testRuns}/getStats/${testId}`)
      .then((res: Response) => res)
      .catch(({ response }: Error) => response as Response);
  }

  static getLatestTestRun(selectField: string, limit?: number) {
    return axiosInstance.get(`${ApiPath.api.testRuns}/latest/${selectField}` + (limit ? `?limit=${limit}` : ""));
  }

  static getAllTestIterations(testId: string) {
    return axiosInstance.get(`${ApiPath.api.testIterations}/${testId}/all`);
  }

  static getDistinctUnassignedTestIteration(selectField: string) {
    return axiosInstance.get(`${ApiPath.api.testIterations}/unassigned/distinct/${selectField}`);
  }

  static generateNewRunName() {
    return axiosInstance.get(`${ApiPath.api.testIterations}/generate-name`);
  }

  static getAllTestDefinitions() {
    return axiosInstance.get(`${ApiPath.api.testDefinitions}/all`);
  }

  static getTestDefinition(testId: string) {
    return axiosInstance.get(`${ApiPath.api.testDefinitions}/${testId}`);
  }

  static getTestIteration(iterationId: string) {
    return axiosInstance.get(`${ApiPath.api.testIterations}/${iterationId}`);
  }

  static getTestIterationChartData(iterationId: string, freeReport = false) {
    return axiosInstance.get<IterationChartData>(
      `${ApiPath.api.testIterations}/chartData/${iterationId}/${freeReport ? "free" : ""}`
    );
  }

  static getTestIterationEvents(testIterationId: string) {
    return axiosInstance.get(`${ApiPath.api.testIterations}/${testIterationId}/events`);
  }

  static getMinimalTestIterations(testRunId: string) {
    return axiosInstance.get(`${ApiPath.api.testIterations}/${testRunId}/minimal`);
  }

  static getUnassignedTestIterationCount() {
    return axiosInstance.get(`${ApiPath.api.testIterations}/unassigned/count`);
  }

  static saveTestIteration(testIteration: any) {
    return axiosInstance.put(`${ApiPath.api.testIterations}/${testIteration._id}`, testIteration);
  }

  static updateTestIterations(data: any) {
    return axiosInstance.put(`${ApiPath.api.testIterations}/assign`, data);
  }

  static saveTestRun(testRun: any) {
    return axiosInstance.put(`${ApiPath.api.testRuns}/${testRun._id}`, testRun);
  }
}

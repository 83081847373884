import * as React from "react";
import { Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { ReactSVG } from "react-svg";

import spearlineLogoSrc from "@/assets/images/logo-spearline.svg";

interface WorkingSectionProps {
  children: React.ReactNode;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      minHeight: "calc(100vh - 88px - 76px)",
      padding: "0px 16px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    poweredByContainer: {
      padding: "24px 48px 32px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    poweredByText: { margin: "0 8px 0 0", color: theme.palette.text.secondary, fontSize: 12 },
    poweredByIcon: { width: 92.42, height: 20 },
  })
);

const WorkingSection = ({ children }: WorkingSectionProps) => {
  const classes = useStyles();

  return (
    <>
      <main className={classes.container}>{children}</main>
      <aside className={classes.poweredByContainer}>
        <p className={classes.poweredByText}>Powered by</p>
        <ReactSVG src={spearlineLogoSrc} className={classes.poweredByIcon} />
      </aside>
    </>
  );
};

export default WorkingSection;

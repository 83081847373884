import * as React from "react";
import * as moment from "moment";
import scrollIntoView from "scroll-into-view";

import { Theme } from "@mui/material/styles";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import ForwardIcon from "@mui/icons-material/Forward";

interface IICEConnectionState {
  iceConnectionStates: any;
  title: string;
}

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    toolbar: {
      minHeight: 24,
      padding: 0,
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
    },
    forwardIcon: {
      margin: "0 10px",
    },
    flexAlign: {
      display: "flex",
      alignItems: "center",
      flexWrap: "wrap",
    },
    circle: {
      margin: "10px",
      padding: "8px 12px",
      borderRadius: "10px",
      background: "#f3f3f3",
      wordBreak: "break-all",
      textAlign: "center",
      fontSize: "11px",
      fontWeight: "bold",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      textTransform: "capitalize",
      boxShadow: `0px 1px 3px 0px rgba(0, 0, 0, 0.2),
            0px 1px 1px 0px rgba(0, 0, 0, 0.14),
            0px 2px 1px -1px rgba(0, 0, 0, 0.12)`,
      flexDirection: "column",
      "&:hover": {
        cursor: "pointer",
      },
      "&:active": {
        opacity: 0.6,
      },
    },
    showMoreButton: {
      marginLeft: "10px",
    },
  })
);

const maxStatesToShow = 4;

const ICEConnectionState = ({ iceConnectionStates, title }: IICEConnectionState) => {
  const classes = useStyles();

  const isShowMoreButtonVisible = iceConnectionStates.length > maxStatesToShow;
  const [isOpen, setIsOpen] = React.useState(false);

  const toggleIsOpen = () => {
    setIsOpen((currentIsOpen) => !currentIsOpen);
  };

  const scrollToTraceEvent = (nodeId: string) => {
    const node: any = document.getElementById(nodeId);
    scrollIntoView(node, {
      time: 500,
      align: {
        top: 0,
      },
    });
  };

  const generateTraceEventId = (event: ProcessEvent) => {
    if (typeof event.value === "object") {
      return `${title}${event.type}${event.time}${JSON.stringify(event.value).substring(0, 30)}`;
    }
    return `${title}${event.type}${event.time}${event.value.substring(0, 30)}`;
  };

  const trimLogState = (logState: string, trimFrom: string) => {
    let result = logState;
    const index = logState.indexOf(trimFrom);
    if (index !== -1) {
      result = logState.substring(trimFrom.length);
    }
    return (
      <Typography style={{ fontSize: "12px" }} variant="subtitle1">
        {result}
      </Typography>
    );
  };

  let statesToShow = iceConnectionStates;
  let showMoreButtonText = "";

  if (isShowMoreButtonVisible && isOpen) {
    showMoreButtonText = "Show less";
  } else if (isShowMoreButtonVisible) {
    statesToShow = iceConnectionStates.slice(0, maxStatesToShow);
    showMoreButtonText = `Show ${iceConnectionStates.length - maxStatesToShow} more`;
  }

  return (
    <Toolbar className={classes.toolbar}>
      <Grid item={true} xs={12} md={2}>
        <Typography variant="subtitle1">ICE connection state:</Typography>
      </Grid>
      <Grid item={true} xs={12} md={10} className={classes.flexAlign}>
        {statesToShow.map((event: any) => {
          return (
            <React.Fragment key={`${event.value}-${Math.random() * 20}`}>
              <ForwardIcon className={classes.forwardIcon} />
              <div
                className={classes.circle}
                onClick={() => {
                  scrollToTraceEvent(generateTraceEventId(event));
                }}
              >
                {trimLogState(event.value, "ICEConnectionState")}
                <Typography style={{ fontSize: "12px", color: "#FC373A" }} variant="subtitle1">
                  {moment(event.time).format("HH:mm:ss")}
                </Typography>
              </div>
            </React.Fragment>
          );
        })}
        {isShowMoreButtonVisible ? (
          <>
            <ForwardIcon className={classes.forwardIcon} />
            <Button onClick={toggleIsOpen} className={classes.showMoreButton}>
              {showMoreButtonText}
            </Button>
          </>
        ) : null}
      </Grid>
    </Toolbar>
  );
};

export default ICEConnectionState;

import Grid from "@mui/material/Grid";
import { Theme } from "@mui/material";
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { Link } from "react-router-dom";
import { AuthRoutes } from "../../constants/routes/auth-routes";

const AnalyzerBanner = ({ classes }: WithStyles<"banner" | "bannerLink">) => (
  <Grid className={classes.banner} container={true} alignItems="center" justifyContent="center">
    <Link className={classes.bannerLink} to={AuthRoutes.Analyze}>
      <img src="assets/images/analyze-banner.png" alt="banner" />
    </Link>
  </Grid>
);

const styles = (theme: Theme) =>
  createStyles({
    banner: {
      height: "100%",
    },
    bannerLink: {
      [theme.breakpoints.down('sm')]: {
        marginTop: 30,
      },
    },
  });

export default withStyles(styles)(AnalyzerBanner);

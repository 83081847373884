import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import { createStyles, makeStyles } from "@mui/styles";
import { Card, CircularProgress, Theme, Typography } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import ButtonSimple from "@monortc/front/components/ButtonSimple";

import { AuthRoutes } from "src/constants/routes/auth-routes";
import { useAxios } from "@monortc/front/services/AxiosService";
import { SetNotification } from "src/actions/notificationAction";
import ApiPath from "@/constants/ApiPath";

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    title: { fontSize: "24px", fontWeight: "700", textAlign: "center", marginBottom: "8px" },
    subTitle: { fontSize: "16px", textAlign: "center", marginBottom: "20px" },
    cardTitle: { fontSize: "18px", textAlign: "left", marginBottom: "16px" },
    cardText: { fontSize: "16px", textAlign: "left", marginBottom: "16px" },
    col: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
    },
    wrapper: {
      display: "flex",
      justifyContent: "space-between",
      maxWidth: "1000px",
      gap: "20px",
    },
    cardWrap: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      padding: "40px 10px 0",
      borderRadius: "10px",
      height: "max-content",
    },
    cardWrapFeatured: {
      background: "#a3c9ff2e",
    },
    featuredText: {
      fontSize: "14px",
      margin: "-30px 0 10px 20px",
      height: "20px",
    },
    card: {
      background: "#e7e7e780",
      borderRadius: "10px",
      padding: "20px",
      minHeight: "370px",
      minWidth: "220px",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      "& > div:first-child": {
        flex: 1,
      },
      "& button": {
        minWidth: "unset",
        textTransform: "none",
      },
      "& a": {
        textDecoration: "none",
      },
    },
    cardOptions: {
      "& p:first-child": {
        fontSize: "18px",
      },
      "& p": {
        display: "flex",
        alignItems: "center",
        fontSize: "14px",
        marginBottom: "16px",
      },
      "& svg": {
        color: "green",
        marginRight: "6px",
      },
    },
    price: {
      fontSize: "30px",
      marginBottom: "20px",
      "& span": {
        fontSize: "18px",
        marginLeft: "6px",
      },
    },
  })
);

type Product = {
  id: string;
  object: string;
  active: boolean;
  created: number;
  default_price: string;
  description: string;
  metadata: { [key: string]: string };
  name: string;
  price: Price;
};
type Price = {
  id: string;
  created: number;
  currency: "eur";
  product: Product["id"];
  recurring: {
    interval: "month";
  };
  unit_amount: number;
  unit_amount_decimal: string;
};

const PricingPage = () => {
  const classes = useStyles();
  const { axios } = useAxios();
  const dispatch = useDispatch();
  const setNotification = (message: string) => dispatch(SetNotification(message));

  const [products, setProducts] = useState<Product[]>([]);
  const [isLoading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    setLoading(true);
    try {
      const products = await axios.get(ApiPath.api.agentAssure.products).then((res) => res.data);

      const sortedByDate =
        products.sort((a: Product, b: Product) => {
          return a.created - b.created;
        }) || [];

      setProducts(sortedByDate);
    } catch (e) {
      setProducts([]);
      setNotification("Failed to fetch products. Please try again later");
    } finally {
      setLoading(false);
    }
  };

  if (isLoading) {
    return <CircularProgress color="inherit" size={20} />;
  }

  return (
    <div className={classes.col}>
      <Typography variant={"h1"} className={classes.title}>
        Pricing
      </Typography>
      <Typography variant={"body1"} className={classes.subTitle}>
        All plans include a 14 day free trial
      </Typography>
      <div className={classes.wrapper}>
        {products.length === 0 && (
          <>
            <Typography variant={"body1"} className={classes.subTitle}>
              No products available at the moment. Please try again later.
            </Typography>
            <ButtonSimple onClick={() => fetchProducts()}>Retry</ButtonSimple>
          </>
        )}
        {products.map((item) => {
          return <Product product={item} key={item.name} />;
        })}
      </div>
    </div>
  );
};

type ProductComponentProps = {
  product: Product;
};

const Product = ({ product }: ProductComponentProps) => {
  const classes = useStyles();

  const getFeatures = () => {
    return product.metadata.features.split(",").map((item) => {
      return (
        <p key={item}>
          <CheckIcon />
          {item}
        </p>
      );
    });
  };

  const featuredText = product.metadata.featured || "";

  const isEnterprise = product.metadata.product_key === "enterprise";
  const buttonText = isEnterprise ? "Contact us" : "Sign up for free";

  const buttonLink = !isEnterprise ? product.metadata.product_key : "";

  const productPrice = () => {
    if (!isEnterprise) {
      if (product.price?.unit_amount) {
        const price = isNaN(Number(product.price.unit_amount)) ? false : product.price.unit_amount / 100;
        if (!price) {
          return "Free";
        }
        const recurringText = product.price?.recurring?.interval || "month";
        return (
          <>
            €{price}
            <span>per {recurringText}</span>
          </>
        );
      }
      return "Free";
    } else {
      return "";
    }
  };

  return (
    <div className={`${!featuredText ? classes.cardWrap : `${classes.cardWrapFeatured} ${classes.cardWrap}`}`}>
      {featuredText && <div className={classes.featuredText}>{featuredText}</div>}
      <Card className={classes.card}>
        <div>
          <Typography variant={"h4"} className={classes.cardTitle}>
            {product.name}
          </Typography>
          <Typography variant={"body1"} className={classes.cardText}>
            {product.description}
          </Typography>
        </div>
        <Typography variant={"h5"} className={classes.price}>
          {productPrice}
        </Typography>
        {isEnterprise ? (
          <a href={"https://spearline.com/contact-us"}>
            <ButtonSimple fullWidth>{buttonText}</ButtonSimple>
          </a>
        ) : (
          <Link to={AuthRoutes.SignUp + `?selected_plan=${buttonLink}`}>
            <ButtonSimple fullWidth>{buttonText}</ButtonSimple>
          </Link>
        )}
      </Card>
      <div className={classes.cardOptions}>
        <p>Includes</p>
        {getFeatures()}
      </div>
    </div>
  );
};

export default PricingPage;

export default {
  auth: {
    loginLocal: "/auth/local",
    loginSso: "/auth/sso-url/",
  },
  api: {
    user: {
      me: "/api/users/me",
      changeProject: "/api/users/me/change-project",
      setPassword: "/api/users/set-password",
      resetPassword: "/api/users/reset-password",
      confirmAccount: "/api/users/confirm-account",
    },

    agentAssure: {
      products: "/api/agent-assure/products",
      summary: "/api/agent-assure/summary",
      breakdown: "/api/agent-assure/breakdown",

      history: "/api/agent-assure/history",
      accessToken: "/api/agent-assure/access-token",
      testResultDelete: "/api/agent-assure/result/delete",

      invitesNew: "/api/agent-assure/invites/new",
      invitesAll: "/api/agent-assure/invites/all",
      invitesDuplicate: "/api/agent-assure/invites/duplicate",
      invitesDestroy: "/api/agent-assure/invites/destroy",

      configuration: "/api/agent-assure/configuration",

      newAppeal: "/api/agent-assure/appeal/new",
    },

    userConfig: "/api/config-datas/user-config",
    emailUnique: "/api/users/unique",
    requestPassword: "/api/users/request-password",
    signup: "api/users",
    users: "api/users",
    settings: {
      root: "api/users/settings",
      emails: "api/users/settings/test-uprtc-emails",
    },
    accountUsers: "api/users/account-users",
    accountRoles: "api/users/account-roles",
    projects: {
      root: "api/projects",
      all: "api/projects/all",
      search: "api/projects/search",
      removeUser: "api/projects/remove-user",
      dataStreams: "api/projects/data-streams",
    },

    // admin endpoints
    userAccounts: "api/be_users",
    userDetails: "api/users/group",
    companies: "api/be_users/companies",
    resetPassword: (userId: string, password: string) => `api/be_users/reset-password/${userId}/${password}`,
    influexQuery: "/api/adminops/influxdb/query",
    statusPage: "/api/status-page",
    adminUtils: {
      globalConfig: "/api/config-datas/main/config",
      globalProbes: "/api/config-datas/config-probe",
      updateGlobalConfig: "/api/config-datas/main",
      testMonitorJobQueueInfo: "/api/adminops/bull-info",
      getAgentAllocation: "/api/adminops/get-agent-allocation",
      getTestEndpoint: "/api/adminops/get-test-endpoint",
      fillCalculatedFields: "/api/adminops/migration",
      exportCSV: "/api/adminops/project-all/csv",
      freeReportFeedback: "/api/adminops/free-report-feedback",
      testUpRtcEmails: "/api/adminops/test-uprtc-emails",
      gscDownload: "/api/adminops/gsc-download",
      liveStatsDownload: "/api/adminops/live-stats-download",
      adminCmd: "/api/adminops/admin-cmd",
      customIPCsvUpload: "api/adminops/custom-ip-csv-upload",
    },

    billingData: "/api/test_runs/adminIndex",
    billingDataExport: "/api/test_runs/export/billingData",
    gridPreset: {
      root: "/api/gridPreset",
    },
  },
};

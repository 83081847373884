import { ReactNode, useContext } from "react";
import cn from "classnames";
import { createStyles, makeStyles } from "@mui/styles";

import { AppContext } from "@monortc/front/App";
import AppError from "../AppError";

interface AppContentProps {
  children: ReactNode;
  isAlertVisible: boolean;
}

const useStyles = makeStyles(
  createStyles({
    content: {
      width: "100%",
      minHeight: "100vh",
      position: "relative",
      padding: 0,
      flexGrow: 1,
      backgroundColor: "#F8F9FA",
    },
    contentAlertVisible: {
      minHeight: "calc(100vh - 48px)",
    },
  })
);

const AppContent = ({ children, isAlertVisible }: AppContentProps) => {
  const classes = useStyles();

  const { error } = useContext(AppContext);

  return (
    <main
      className={cn(classes.content, {
        [classes.contentAlertVisible]: isAlertVisible,
      })}
    >
      {!error ? children : <AppError />}
    </main>
  );
};

export default AppContent;
